import { USERMASTER } from "../../utils/api";
import axios from "../../utils/axios"

export const registerApi=async(payload,callback)=>{ 
    await axios
    .post (USERMASTER,payload)
    .then((res) => {
      console.log("res search ", res);
      if(res?.data?.succeeded){
        callback(res?.data?.data) 
      }
      else{
        alert(res?.data?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
}