import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import React, { useEffect } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { routePath } from "./constants/routePath";

import { Drawer, Fab, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./common/loader";
import SideBar from "./common/sideBax";

import Header from "./common/header";
import {
  GetUserApi,
  GetUserMenuApi,
  GetUserMenuHeaderApi,
} from "./utils/utils";

import {
  setActvieBussinessDetails,
  setBussinessSideBar,
  setCustomerManagmentDisplay,
  setfavProduct,
  setHeaderMenu,
  setSideBarMenu,
  setTableName,
  setUserDetail,
} from "./app/store/reducer";

import SimpleSnackbar from "./common/Snackbar";

import MobileHeader from "./common/mobileHeader";
import { useState } from "react";
import Footer from "./common/Footer";

import {
  ensureAuthenticatedUserOrRedirectToLandingPage,
  footerRenderingAccordingToPages,
  halfSideBarRenderingAccordingToPages,
  handleHeaderLandingRenderingAccordingTopages,
  handleHeaderRenderingAccordingTopages,
  handleMobileHeaderRederingAccordingToPages,
  pageGridSizeAccordingToLocation,
} from "./utils/routeUtils";
import HalfSideBar from "./common/halfSideBar";
import { counterSellSetOnLastIndex, getSession } from "./utils/commonUtils";
import { RoutesSet } from "./utils/routeSet";
import { NavBar } from "./common/landingPage/navbar";
import { NavBoarContainer } from "./common/navBarContainer";
import { GetBussiness } from "./features/myBusiness/myBusinessApi";
import BussinessMangment from "./common/businessChategory";
import CustomerMangementbar from "./common/customerMangementBar";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const menuLoginHeader = [
    {
      title: "Home",
    },
    {
      title: "About",
    },
  ];

  const getToken = async () => {
    const token = await sessionStorage.getItem("smbToken");
    if (token) {
      navigate("/");
    }
  };
  const [drawerShow, setDrawerShow] = useState(false);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const favProduct = useSelector((state) => state.reducer.favProduct);
// const =useSelector((state)=>state.reducer.BussinessMangment)
const BussinessSideBar = useSelector((state) => state.reducer.BussinessSideBar);
const customerManagmentDisplay = useSelector((state) => state.reducer.customerManagmentDisplay);

  const [flag,setFlat]=useState({
    getData:false
  })
  useEffect(() => {
    console.log("userDetail ", userDetail);
    if(Object.keys(userDetail).length>0){
      if(!flag.getData){
        getData()

        setFlat({...flag,getData:true})
      }
    }
  }, [userDetail]);
  const tableName = useSelector((state) => state.reducer.tableName);
  const dispatch = useDispatch();
  const getData = async () => {
    await GetBussiness().then((res) => {
      console.log("es.data.data[0] ", res.data.data[0])
  dispatch(setUserDetail({...userDetail,uploadedLogo:res.data.data[0]?.logoPath}))
  dispatch(setActvieBussinessDetails( res.data.data[0]))

      // setMyBussinessData({
      //   ...res.data.data[0],
      //   mobileNumber: userDetail?.mobileNumber,
      // });
    });
  };
  const getUser = async () => {
   

    await GetUserApi()
      .then(async (res) => {
        let tmpChangeBus = { ...res.data.data };
        // businessLineCode
        // dispatch(setUserDetail({ ...tmpChangeBus, businessLineCode: "CB" }));
        dispatch(setUserDetail({ ...tmpChangeBus , businessLineCode: "RE" }));

        await GetUserMenuApi(
          res.data.data.businessLineId,
          res.data.data.languageCode
        )
          .then((res) => {
            console.log("businessLineId ", res.data.data.mainMenu);
            dispatch(setSideBarMenu(res.data.data.hamburger));
          })
          .catch((err) => {
            console.error("res ", err);
          });

        await GetUserMenuHeaderApi(
          res.data.data.businessLineId,
          res.data.data.languageCode
        )
          .then((res) => {
            console.log("businessLineId ", res.data.data.mainMenu);

            // console.log("res.data.data.mainMenu ", res.data.data.mainMenu);
            let data = res.data.data.mainMenu;
            // console.log("index header ss", counterSellSetOnLastIndex(data));
            dispatch(setHeaderMenu(counterSellSetOnLastIndex(data)));
          })
          .catch((err) => {
            console.error("res ", err);
          });
      })
      .catch((err) => {
        console.error("res ", err);
      });
  };
  function isObjectEmpty(obj) {
    return Object.entries(obj).length === 0;
  }
  useEffect(() => {
    const token = sessionStorage.getItem("smbToken");
    // if ( token === null &&
    //   location.pathname !== "/login" &&
    //   location.pathname !== routePath.LANDINGPAGERESTORENT &&
    //   location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    //   location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    //   location.pathname !== routePath.LANDINGPAGEFRANCHISE
    //   // ensureAuthenticatedUserOrRedirectToLandingPage(token, location, routePath)
    // ) {
    //   // navigate(routePath.LANDINGPAGE);
    //   return;
    // }
  }, []);

  
  const notApplyAuthRouting = {
    homepage: 1,
    home: 1,
    ServicesList: 1,
    LANDINGPAGE: 1,
    detail: 1,
    REGISTRATION: 1,
    otp: 1,
    setMpin: 1, 
    // userprofile:1,
    login:1,
    appoinment:1,
    otpappoinment:1,
    fav:1,
    privacyPolicy:1,
    termsOfService:1,
    getUserOffer:1
  };


  const both={
    ServicesList:1
  }
  // const 
 
  const redirectContinuousPage=async()=>{
    await getSession("nextPage",async(data)=>{
      console.log("nextPage data", routePath[data])
     
       if(location.pathname=="/")
      {
        if(userDetail.appMenu=="Buyer"){
          navigate(routePath.SERVICESLIST);
        } 
        if(userDetail?.appMenu=="Biller"){
          navigate(routePath.ADVERTICE);
        }
      }
      
    })
   
  }
  
  useEffect(() => {
    const token = sessionStorage.getItem("smbToken");
    const key= location.pathname.split("/")[location.pathname.split("/").length - 1]
    // if(userDetail &&)
    console.log("called return ",key)

    getSession("favProduct", (data) => {
      console.log("favprod ", data);
      // if(data){
      //   setSession("favProduct", [...data,item], () => {});
      // console.log("favprod set ",data)

      // }
      // else{
      // if(data){
      //   data[item.id]=1
      // }
      let obj = {};
      if (data) {
        obj = JSON.parse(data); 
        dispatch(setfavProduct(obj))
      }
      // obj[String(item.id)] = 1;

      //  obj[item.id]=1
      console.log("favprod first arr ", obj);

      // setSession("favProduct", obj, () => {});

      // }
    });

    if (
      (notApplyAuthRouting[
        key
      ] && Object.keys(userDetail).length==0) || (Object.keys(userDetail).length!==0 && (key=="ServicesList" || key=="appoinment"  || key=="otpappoinment" || key=="fav"))  
    ) {
      return;
      
    } 
    if (
      ensureAuthenticatedUserOrRedirectToLandingPage(token, location, routePath)
    ) {
      navigate(routePath.LANDINGPAGE);
      return;
    }
    if (token && location.pathname === routePath.LANDINGPAGE) {
      if(userDetail.appMenu=="Buyer"){
        navigate(routePath.SERVICESLIST);
      }else{
        navigate(routePath.ADVERTICE);
      }
      return;
    }


    if (
      tableName === null &&
      location.pathname === routePath.COUNTERSELLBILLING
    ) {
      navigate(routePath.COUNTERSELL);
      // return;
    }
    if (
      location.pathname === routePath.COUNTERSELL &&
      // userDetail?.businessLineCode !== "RE" &&
      userDetail?.businessLineCode
    ) {
      if (userDetail?.businessLineCode === "RE") {
        navigate(routePath.COUNTERSELL);
      } else {
        dispatch(setTableName(userDetail?.name));
        navigate(routePath.COUNTERSELLBILLING);
      }
    }
    console.log("token ",token," ",userDetail)
    if (token !== null && isObjectEmpty(userDetail)) {
      getUser();
    }

    if (location.pathname ==="/") {
      getToken();
      
    }
    
    
   
    redirectContinuousPage()

   
  }, [location.pathname, userDetail]);



  useEffect(()=>{
console.log("favProduct",favProduct)
  },[favProduct])
  return (
    <div>
      <Loader />
      <SimpleSnackbar />
      <Grid container spacing={0}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            width: "100%",
            zIndex: "999",
            position: "sticky",
            top: 0,
            display: {
              xs: "block",
          
            },
          }}
        >
          {(userDetail?.appMenu!=="Biller") && 
            <NavBoarContainer />
          }
         {
          (userDetail?.appMenu=="Biller") && 
          <Header
          setDrawerShowSideBar={(data) => setDrawerShow(data)}
          menuLoginHeader={
            // location.pathname === "/login" ? menuLoginHeader : null
            location.pathname === routePath.LOGIN ? menuLoginHeader : null
          }
        />
         }
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: {
              xs:  
              location.pathname=== routePath.LANDINGPAGE ||
              location.pathname === routePath.SERVICESLIST || 
              location.pathname === routePath.DETAIL ?"none":"block"
              // sm: handleHeaderRenderingAccordingTopages(routePath, location)
              //   ? "none"
              //   : "block",
            },
          }}
        >
          {/* <Header
            setDrawerShowSideBar={(data) => setDrawerShow(data)}
            menuLoginHeader={
              // location.pathname === "/login" ? menuLoginHeader : null
              location.pathname === routePath.LOGIN ? menuLoginHeader : null
            }
          /> */}
        </Grid>
        {handleMobileHeaderRederingAccordingToPages(location, routePath) && (
          <React.Fragment>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                },
              }}
            >
              {/* <MobileHeader setDrawerShow={setDrawerShow} /> */}
            </Grid>
          </React.Fragment>
        )}
    

        <Grid
          item
          xs={halfSideBarRenderingAccordingToPages(location, routePath) ? 12 :12}
         
        >
          <Routes>
            {RoutesSet.map((item, index) => {
              return <Route path={item.routePath} element={item.component} />;
            })}
          </Routes>
        </Grid>
        {/* <Grid
          item
          sm={0.5}
          sx={{
            display: {
              xs: "none",
              sm: halfSideBarRenderingAccordingToPages(location, routePath)
                ? "block"
                : "none",
            },
          }}
          display={
            halfSideBarRenderingAccordingToPages(location, routePath)
              ? "block"
              : "none"
          }
        >
          <HalfSideBar setDrawerShowSideBar={(data) => setDrawerShow(data)} />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: footerRenderingAccordingToPages(location, routePath)
                ? "none"
                : "none",
              sm: "none",
            },
          }}
        >
          <Footer />
        </Grid>
      </Grid>
      <Drawer sx={{"& .MuiPaper-root":{top:'90px'}}}
        anchor={"left"}
        open={drawerShow}
        onClose={() => {
          setDrawerShow(false);
        }}
        onClick={() => setDrawerShow(false)}
      >
        <SideBar menuLoginHeader={menuLoginHeader} />
      </Drawer>
      
      <Drawer sx={{"& .MuiPaper-root":{top:'90px'}}}
        anchor={"left"}
        open={BussinessSideBar}
        onClose={() => {
          dispatch(setBussinessSideBar(false))
        }}
        onClick={() => setDrawerShow(false)}
      >
        <BussinessMangment menuLoginHeader={menuLoginHeader} />
      </Drawer>

      <Drawer sx={{"& .MuiPaper-root":{top:'90px'}}}
        anchor={"left"}
        open={customerManagmentDisplay}
        onClose={() => {
          dispatch(setCustomerManagmentDisplay(false))
        }}
        onClick={() => setDrawerShow(false)}
      >
      <CustomerMangementbar menuLoginHeader={menuLoginHeader} />
      </Drawer>
      
      {footerRenderingAccordingToPages(location, routePath) === false && (
        <Fab
          // color="red"
          sx={{
            position: "fixed",
            bottom: "30px",
            right: "40px",
            backgroundColor: "#25D366 !important",
            width: "70px",
            height: "70px",
          }}
          onClick={() => {
            const whatsappLink = `https://web.whatsapp.com/send?phone=${9209054709}&text=Hello`;
            window.open(whatsappLink, "_blank");
          }}
        >
          <WhatsAppIcon
            sx={{
              color: "white",
              fontSize: "40px",
            }}
          />
        </Fab>
      )}
    </div>
  );
}

export default App;
