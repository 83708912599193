import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import EventIcon from "@mui/icons-material/Event";
import { TextField } from "@mui/material";

const DatePickerComponent = ({ callback,title }) => {
  const [startDate, setStartDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    if (date) {
      const dateStr = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [month, day, year] = dateStr.split("/");

      const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;

      setStartDate(date);
      callback(formattedDate); // Send selected date to parent component
    }
  };

  return (
    <Box style={{width:'100%',p:'0px',display:'flex'}}>
      <DatePicker
      sx={{ 
         p:'0px'
      }}
        selected={startDate}
        maxDate={new Date()}
        onChange={handleDateChange}
        className="custom-datepicker"
        ref={datePickerRef}
        onClickOutside={handleCloseDatePicker}
        open={isDatePickerOpen}
      />
      <Box
        sx={{
          display:'flex'
        }}
        onClick={openDatePicker}
      >
      


        <TextField
            size="small"
            // onClick={handleOpenDatePicker}
            id="date"
            sx={{
              width: '100%',
              p:'0px'
            }}
            value={startDate?.toLocaleDateString("en-GB")}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: 'grey',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                   p:'0px'
                }}
              >
               {title?title: "Date :"}
              </Box>
            }
            InputProps={{
              readOnly: true,
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                //   onClick={handleOpenDatePicker}
                />
              ),
            }}
          />
      </Box>
    </Box>
  );
};

export default DatePickerComponent;
