// import axios from "axios";

import origin from "axios";
import { setUserDetail, startLoading, stopLoading } from "../app/store/reducer";
import store from "../app/store/store";
import { useNavigate } from "react-router";
import { routePath } from "../constants/routePath";
//dev
// export const baseURL = "http://103.13.114.194:4080/webapp/";
// live
// export const baseURL = "https://showmybills.com/webapp/";

export const baseURL ="https://elegantprime.in/webapp/"
export const axios = origin.create({
  baseURL,
});

axios.interceptors.request.use(async (config) => {
  await store.dispatch(startLoading());

  const token = await sessionStorage.getItem("smbToken");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
});

axios.interceptors.response.use(async (res) => {
  await store.dispatch(stopLoading());
  return res;
});

axios.interceptors.response.use(
  async (res) => {
    await store.dispatch(stopLoading());
    return res;
  },
  async (error) => {
    await store.dispatch(stopLoading());

    if (error.response && error.response.status === 401) {
 
      sessionStorage.removeItem("smbToken");
 
      // const navigate = useNavigate(); // for react-router
      //  navigate(routePath.LANDINGPAGE);
       store.dispatch(setUserDetail({}))

      // Or if you are not using `useHistory`:
      // window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axios;
