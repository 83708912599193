import { Box, Grid, TextField } from "@mui/material";
import { getEndOfMonth, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getLastDateOfCurrentMonthPayloadFormat, getStartOfMonth } from "../utils/commonUtils";
   
import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
// import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getUnpaidUser } from "../features/paymentReceipt/paymentReceiptApi";
import PaymentReceiptSearchModal from "../features/paymentReceipt/paymentReceiptSearchModal";
import { getCounterSales } from "../features/employeePayRoll/outStandingReportCustomApiHandler";
import { getCounterSalesStatement } from "../features/employeePayRoll/outstandingApi";


export    const ToFromDate=({startDateFormat, setstartDateFormat,endDate,setEndDate})=>{
    //-----
  const [numberOfRecords, setNumberOfRecords] = useState(1);

// variable for modal employee 
    const data = {
        customerMobile: "",
        amount: 0,
        name: ""
      };
      const [billData, setBillData] = useState(data);

  const [search, setSearch] = useState("");
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)
  const [upPaidBillData, setUnPaidBillData] = useState();
  const [unPaidBill, setUnPaidBill] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 24,
  });
  const [payloadGetCounterSales, setPayloadGetCounterSales] = useState({
    page: 1,
    pageSize: pagination.pageSize,
    sortBy: "",
    order: 0,
    buyerGroupId: 0,
    filter: "",
    search: "",
  });

//   const [billData, setBillData] = useState(data);
  const [billFilter, setBillFilter] = useState(null);




  const datePickerRef2 = useRef();

    const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());
  const datePickerRef = useRef();
  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());

  const [SelectedEndDate, setSelectedEndDate] = useState(null);
  const [outStandingBills, setOutStandingBills] = useState({
    records: [],
    totalOustandingAmount: "",
    totalRecords: "",
  });
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };

  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };
  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };

    return (
        <>
       
          <TextField
            fullWidth
            readonly
            onClick={openDatePicker}
            id="date"
            sx={{
              mt: 2,
              "& .MuiInputBase-root": { height: "45px" }
            }}
            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
        
          <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        />
         
        </>
    )
}