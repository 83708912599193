import { payloadDateFormat } from "../../utils/commonUtils";

export const callbackHandlerCounterSellBilling_ = (
  addBill,
  payloadAddBill,
  addBillCounterSell,
  Swal,
  dispatch,
  setBillNo,
  navigate,
  routePath,callBack
) => {
  return {
    addBill: {
      callback: addBill,
      parameter: {
        payloadAddBill: payloadAddBill,
        addBillCounterSell: addBillCounterSell,
        Swal: Swal,
        dispatch: dispatch,
        setBillNo: setBillNo,
        navigate: navigate,
        routePath: routePath,
        callBack:callBack
      },
    },
  };
};
