import React, { useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setUserDetail } from '../app/store/reducer';
import { useDispatch, useSelector } from 'react-redux';

const ImageUploader = ({ path, callback }) => {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(null);


  const userDetail = useSelector((state) => state.reducer.userDetail);

  const [file, setFile] = useState(null)
  // Handle file selectio
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      callback(file)
      setFile(file)
      setSelectedImage(URL.createObjectURL(file)); // Convert image to URL for preview
    }
  };
  useEffect(() => {
    if (path) {

      dispatch(setUserDetail({ ...userDetail, uploadedLogo: path }))
      // setSelectedImage(true)
    }
    else {
      // setSelectedImage(false)

    }
  }, [path])
  // Open file input when clicking on the icon
  const handleIconClick = () => {
    document.getElementById('imageUpload').click();
  };

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      {(path && !selectedImage) ? (
        // Display selected image if available
        <img
          src={path}
          alt="Selected"
          onClick={handleIconClick}
          style={{ width: '200px', height: '200px', cursor: 'pointer', borderRadius: '50%' }}
        />
      ) : (
        // Display the icon if no image is selected
        <></>
      )}
      {selectedImage  && (
          // Display selected image if available
          <img
            src={selectedImage}
            alt="Selected"
            onClick={handleIconClick}
            style={{ width: '200px', height: '200px', cursor: 'pointer', borderRadius: '50%' }}
          />
        )
      }
      {(!selectedImage && !path) &&

        <AccountCircleIcon
          onClick={handleIconClick}
          sx={{ width: '100px', height: '100px', cursor: 'pointer' }}
        />
      }

      {/* Hidden file input field */}
      <input
        type="file"
        id="imageUpload"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageUploader;
