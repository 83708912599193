

import { GETALLOFFERS } from "../../utils/api";
import axios from "../../utils/axios"; 

export const GetAllOffersApi = async (params) => {
    const res = await axios.get(GETALLOFFERS, params);
    return res;
  };

