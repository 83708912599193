import { Box, Grid, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getAllCustomerDetail } from "../../common/searchModal/api"
import OutStandingReportCart from "../employeePayRoll/outStandingReportCart"
import CardCounterInformation from "./card"
import SearchIcon from "@mui/icons-material/Search";
import SearchModalCustomer from "../../common/searchModal/customer"
import { getData } from "./counterInformationApi"
import { CustomeDetail } from "../customerDetail/customerDetail"
import LastCustomer from "../../common/searchModal/lastCustomer"
const CustomerInfo=()=>{
    const [collection, setCollection] = useState([])
  const [search, setSearch] = useState("");
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [openT, setOpenT] = useState(false)
  const [selectedData, setSeletecData] = useState(null)
  const [value, setValue] = useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  useEffect(() => {

  }, [])
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>

              <Box
                // disabled
                // size="small"
                readonly
                fullWidth
                onClick={() => {
                  // setSearchOutStandingsModal(true)

                }}
                sx={{
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  // width: "70%",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
                onChange={(e) => {
                }}

              >
                <Typography variant="p">
                  {selectedData?.name !== "" ? selectedData?.name : "Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    // setSearchModal(true)
                    //    setSearchOutStandingsModal(true)
                    setOpenT(true)

                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={12}>

            </Grid>
            <Grid xs={12}>

              {
                collection.map((item, index) => {
                  return (
                    <CardCounterInformation
                      item={item}
                    />
                  )
                })
              }
            </Grid>
            <LastCustomer searchModal={openT} setSearchModal={setOpenT} callback={(data) => {
              console.log("Data", data)
              getData({ mobile: data?.customerMobile }, (data) => {
                console.log("data detail ", data?.data)
                setCollection(data?.data)
              })

              setSeletecData(data)
              setOpenT(false)
            }} />
          </React.Fragment>
    )
}

export default CustomerInfo