import {
    Box,
    Card,
    CardContent,
    Grid,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import React from "react";
  import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
  export default function EmployeePayrollModalCard({
    index,
    date,
    item,
    ExpenseItemssArr,
    setExpenseItemssArr,
    paymentMode
  }) {
    console.log("item ", item);
    return (
      <Card
        sx={{
          mt: 1,
          // mr: 2,
          cursor: "pointer",
          ":hover": {
            bgcolor: "#2892ba17",
          },
        }}
        onClick={() => {}}
      >
        <CardContent>
          <Grid container> 
          <Grid xs={1} sx={{ display: "flex", alignItems: "center" }}>
               
              <Typography
                sx={{
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  // fontWeight: 600,
                }}
              > 
                {item?.itemId}
              </Typography>
            </Grid>
          <Grid xs={3} sx={{ display: "flex", alignItems: "center" }}>
               
              <Typography
                sx={{
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  // fontWeight: 600,
                }}
              > 
                {date}
              </Typography>
            </Grid>
            <Grid xs={3} sx={{ display: "flex" }}>
              <Typography
                color="primary"
                sx={{
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  // fontWeight: 600,
                  // color: "blac",
                }}
              >
                {paymentMode}
              </Typography>
            </Grid>
            <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <CurrencyRupeeIcon fontSize="10px" />
  
              <Typography
                sx={{
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  // fontWeight: 600,
                }}
              >
                {item?.amount?.toFixed(2)}
              </Typography>
            </Grid>
            
          </Grid>
        </CardContent>
      </Card>
    );
  }
  