import { LASTSERVICEDETAILS } from "../../utils/api"
import axios from "../../utils/axios"

 
export const getData = async (payload, callback) => {
    try {
        const response = await axios.post(LASTSERVICEDETAILS, payload);
        console.log("res", response);

        // Call the callback with the result if provided
        if (callback) {
            callback( response.data); // First argument is null for no error
        }
    } catch (error) {
        console.error("Error in getData:", error);

        // Call the callback with the error if provided
        if (callback) {
            // callback(error);
        }
    }
};
