import { GETPRIVACYPOLICY, GETTERMSERVICE } from "../../utils/api";
import axios from "../../utils/axios";

export const getPrivacyPolicy=async(callback)=>{ 
    await axios
    .get (GETPRIVACYPOLICY)
    .then((res) => {
      console.log("res search ", res);
    //   callback(res?.data?.data) 
      if(res){
        callback(res?.data?.documentContent) 
      }
      else{
        alert(res)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
  }

export const getTermService=async(callback)=>{ 
    await axios
    .get (GETTERMSERVICE)
    .then((res) => {
      console.log("res search ", res);
    //   callback(res?.data?.data) 
      if(res){
        callback(res?.data?.documentContent) 
      }
      else{
        alert(res)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
  }