import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

export default function StockCard({
  item,
  setEditedItem,
  setOpen2,
  setSelectedUser,
}) {
  return (
    <Card
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
        p: "0px",
      }}
      onClick={setEditedItem}
    >
      <CardContent
        sx={{
          p: "10px !important",
          // m: 0,
        }}
      >
        <Grid container>
          <Grid xs={6} sx={{ display: "flex" }}>
            <Typography
              color="primary"
              sx={{
                overflow: "Hidden",
                m: "0px",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.description}
            </Typography>
          </Grid>
          <Grid xs={6} sx={{ display: "flex" }}>
            <Typography
              color="primary"
              sx={{
                overflow: "Hidden",
                m: "0px",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.totalItemQuantity}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
