import { Grid, Typography } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const CardLocation=(props)=>{
    return (     <Grid
        sx={{
          borderBottom: "1px solid rgb(227, 227, 227)",
          p: "10px",
        }}
        container
        onClick={()=>{
          props?.onClick({city:props?.cityName})
          console.log("clicked")
        }}
      >
        <Grid xs={1} sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon />
        </Grid>
        <Grid xs={10}>
          <Grid container>
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {props?.cityName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                {props?.state}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>)
}