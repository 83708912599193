import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";

import { GetDetailsExpense, getPurchaseDetail } from "./expanceApi";

import {
  displayDateFormat,
  downloadXLSXFile,
  getEndOfMonth,
  getFirstDateOfCurrentMonthPayloadFormate,
  getFirstDateOfMonth,
  getLastDateOfCurrentMonthPayloadFormat,
  getStartOfMonth,
  payloadDateFormat,
} from "../../utils/commonUtils";

import ExpenseModal from "./expenseModal";
import ExpenseItemCard from "./ExpenseItemsCard";
import { GETDETALS } from "../../utils/api";
import { getexpence } from "./expenseCustomApiHandlers";
import {
  datePickerOnChange,
  datePickerOnChangeEnd,
} from "./expenseDateFunctions";
import DynamicBreadcrumbs from "../../common/Breadcrumb";




const Expense = ({gridSize}) => {
  const [open, setOpen] = useState(false);

  const [counterSales, setCounterSales] = useState();
  const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());
  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());
  const datePickerRef = useRef();
  const datePickerRef2 = useRef();
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 24,
  });
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [expence, setexpence] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(1);

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (open === false) {
      setUpdateModalData();
    }
  }, [open]);

  const [billFilter, setBillFilter] = useState("cash");

  const [filters, setFilters] = useState([
    {
      name: "Raw Material",
      value: "Raw material",
    },
    { name: "Salary", value: "Salary" },
    {
      name: "Shop Maintenance",
      value: "Shop Maintenance",
    },
    { name: "Other", value: "Other" },
  ]);
  const [updateModalData, setUpdateModalData] = useState();
  useEffect(() => {
    getexpence(
      {
        fromDate: startDateFormat,
        toDate: endDate,
        search: filterBy,
        page: 1,
        pageSize: pagination.pageSize,
      },
      GetDetailsExpense,
      setNumberOfRecords,
      setexpence,
      setTotalAmount
    );
  }, [startDateFormat, endDate]);

  const handleChange = (event, value) => {
    getexpence(
      {
        fromDate: startDateFormat,
        toDate: endDate,
        search: filterBy,
        page: value,
        pageSize: pagination.pageSize,
      },
      GetDetailsExpense,
      setNumberOfRecords,
      setexpence,
      setTotalAmount
    );

    setPagination({
      ...pagination,
      page: value,
    });
  };
  const [filterBy, setFilterBy] = useState("");
  useEffect(() => {
    console.log("datE ", payloadDateFormat());

    getexpence(
      {
        fromDate: startDateFormat,
        toDate: endDate,
        search: filterBy,
        page: 1,
        pageSize: pagination.pageSize,
      },
      GetDetailsExpense,
      setNumberOfRecords,
      setexpence,
      setTotalAmount
    );

    // setDate()
    // setSelectedStartDate(getFirstDateOfMonth())
  }, []);
  useEffect(() => {
    console.log("SelectedStartDate ", SelectedStartDate, " ",
      getStartOfMonth())

  }, [SelectedStartDate])
  const [SelectedEndDate, setSelectedEndDate] = useState(null);
  return (
    <>
      <DynamicBreadcrumbs />
    <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
    <Grid
        container
        spacing={2}
        sx={{
          // sx={{displa}
          display: "flex",
          overflowY: "auto",
          maxHeight: "calc(100vh - 60px)",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >  <TextField
            readonly
            size="small"

            onClick={openDatePicker}
            id="date"
            sx={{
              width: "70%"
            }}

            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          <DatePicker
            hidden={true}
            selected={SelectedStartDate}
            maxDate={new Date()}
            onChange={(data) =>
              datePickerOnChange(
                data,
                datePickerRef2,
                setDate,
                
                setstartDateFormat,
                setSelectedStartDate
              )
            }
            className="custom-datepicker"
            ref={datePickerRef}
            // withPortal
            onClickOutside={() => {
              datePickerRef.current.setOpen(false);
            }}
          />

          <DatePicker
            hidden={true}
            selected={SelectedEndDate}
            minDate={new Date()}
            onChange={(data) =>
              datePickerOnChangeEnd(
                data,
                datePickerRef2,
                setSelectedEndDate,
                setDisplayEndDate,
                setEndDate
              )
            }
            className="custom-datepicker"
            ref={datePickerRef2}
            onClickOutside={() => {
              datePickerRef2.current.setOpen(false);
            }}
          />
 {
            gridSize && (
              <FileDownloadIcon
              sx={{
                mt:"17px",
                ml: "10px",
                mr: "0px",
                height: "1.4375em",
                cursor: "pointer"
                // m: "16.5px 14px",
              }}/>
            )
          }
        </Grid>
        
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex", alignItems: "center",

            justifyContent: "left",
          }}
        >
          <FormControl sx={{ width: "70%", position: "relative" }} >
            <InputLabel id="demo-simple-select-label" sx={{ position: "absolute", top: "-7px" }}>Category </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterBy}
              // sx={{ height: "45px" }}
              size="small"
              label="Category "
              onChange={(e) => {
                setFilterBy(e.target.value);
                getexpence(
                  {
                    fromDate: startDateFormat,
                    toDate: endDate,
                    search: e.target.value,
                    page: 1,
                    pageSize: pagination.pageSize,
                  },
                  GetDetailsExpense,
                  setNumberOfRecords,
                  setexpence,
                  setTotalAmount
                );
              }}
            >
              {filters.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={"option" + index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
 
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
        </Grid>


        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            fontWeight: "500",
            display: "flex",
            justifyContent: 'left',
            alignItems: 'center'
          }}
        >
          <Box >
            <Box
              component="span"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Total Bills Amount (<CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
              ):
            </Box>
            <Box
              component="span"
              sx={{
                marginLeft: "8px",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {" "}
              {totalAmount?.toFixed(2)}
            </Box>
          </Box>
        </Grid>
      {!gridSize &&   <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            fontWeight: "500",
            display: "flex",
            justifyContent: 'right',
            alignItems: 'center'
          }}
        >
          <Fab
            onClick={() => {
              // setOpen(true);
              setOpen(true);
            }}
            sx={{
              textTransform: "capitalize",
              width: {
                xs: "100%",
                sm: "auto",
              },
              // p: "10px",
              whiteSpace: "nowrap",
            }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Add Expense
            <AddIcon />
          </Fab>
        </Grid>}
        <Divider
          sx={{
            width: "100%",
            mt: 2,

            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid
          container
          sx={{
            marginX: "20px",
          }}
        >
          {expence?.map((item, i) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={"expence" + i}
                sx={{ p: "5px", borderRight: "3px solid #dfdfdf" }}

              >
                <ExpenseItemCard
                  item={item}
                  onClick={(data) => {
                    console.log("Data ", data);
                    if(gridSize){
                      return
                    }
                    setOpen(true);
                    setUpdateModalData({
                      date:data?.date,
                      id: data?.id,
                      expenseItemArrProps: data?.expenseDetails,
                      filterByProp: data?.category,
                    });
                  }}
                />
                {/* <PurchaseAccordian item={item} /> */}
                {/* <CounterSalesItemCard item={item} /> */}
              </Grid>
            );
          })}
          {/* ------------------- */}

        </Grid>
        {/* ------------------------ */}
        <Grid container sx={{ pt: "10px" }}>
          {/* <Grid item xs={4}>
            <Typography color="primary">
              <strong>Total Items: &nbsp;  </strong>
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            sx={{
              // bgcolor: "red",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
            <Pagination
              count={
                Number.isInteger(numberOfRecords / pagination.pageSize)
                  ? numberOfRecords / pagination.pageSize
                  : parseInt(numberOfRecords / pagination.pageSize) + 1
              }
              page={pagination.page}
              onChange={handleChange}
            />
            {/* </Stack> */}
          </Grid>
          {/* <Grid item xs={4}></Grid> */}
        </Grid>

        <ExpenseModal
          open={open}
          setOpen={setOpen}
          updateModalData={updateModalData}
          idProp={updateModalData?.id}
          filterByProp={updateModalData?.filterByProp}
          expenseItemArrProps={updateModalData?.expenseItemArrProps}
          getPurchase={() => {
            getexpence(
              {
                fromDate: startDateFormat,
                toDate: endDate,
                search: filterBy,
                page: 1,
                pageSize: pagination.pageSize,
              },
              GetDetailsExpense,
              setNumberOfRecords,
              setexpence,
              setTotalAmount
            );
          }}
        />
        {/* <AddCircleIcon
          color="primary"
          onClick={() => {
            setOpen(true);

            // setModelTable();
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        /> */}
      </Grid>
    </Box>
    </>
  );
};

export default Expense;
