import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Container,
  Grid,
  CardMedia,
  IconButton,
  Box,
  Checkbox,
  Breadcrumbs,
  Link,
} from "@mui/material";
import male from "../../assets/salon_male.png";
import female from "../../assets/salon_female.png";
import {
  FavoriteBorder,
  AppRegistration as AppRegistrationIcon,
  Share as ShareIcon,
  WhatsApp as WhatsAppIcon,
  LocalOffer as LocalOfferIcon,
  Info as InfoIcon,
  PlayArrow as PlayArrowIcon,
  Call as CallIcon,
  Directions as DirectionsIcon,
  Favorite,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { information } from "../../constants/locationData";

import { api } from "../../utils/api";
import axios from "../../utils/axios";
import CardLanding from "../../common/landingPage/card";
import { useSelector } from "react-redux";
import { getAlldetailSerice, getAllServices } from "./servicesListApi";
import MediaModel from "../../common/mediaModel";
import { routePath } from "../../constants/routePath";
import { useNavigate } from "react-router";
import ApModal from "../../common/AppoinmetModalppBtn";
import { getSession, setSession } from "../../utils/commonUtils";

const FavRate = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();
  // Location
  const [hide, setHide] = useState(false);
  const userDetail = useSelector((state) => state.reducer.userDetail);

  const [serViceList, setServiceList] = useState([]);
  // Contact via phone
  const favCard = (item) => {
    console.log("favprod1", item);

    getSession("favProduct", (data) => {
      console.log("favprod ", data);
      // if(data){
      //   setSession("favProduct", [...data,item], () => {});
      // console.log("favprod set ",data)

      // }
      // else{
      // if(data){
      //   data[item.id]=1
      // }
      let obj = {};
      if (data) {
        obj = JSON.parse(data);
      }
      obj[String(item.id)] = 1;
      //  obj[item.id]=1
      console.log("favprod first arr ", obj);
      setSession("favProduct", obj, () => {});

      // }
    });
  };
  const delfavCard = (item) => {
    console.log("favprod1", item);

    getSession("favProduct", (data) => {
      console.log("favprod ", data);
      // if(data){
      //   setSession("favProduct", [...data,item], () => {});
      // console.log("favprod set ",data)

      // }
      // else{
      // if(data){
      //   data[item.id]=1
      // }
      let obj = {};
      if (data) {
        obj = JSON.parse(data);
      }
      delete obj[String(item.id)];
      //  obj[item.id]=1
      console.log("favprod first arr ", obj);
      setSession("favProduct", obj, () => {});

      let arr = Object.keys(obj).map((key) => {
        return obj[key];
      });

      setServiceList(arr);
      // }
    });
  };
  const navBarInputFields = useSelector(
    (state) => state.reducer.navBarInputFields
  );
  //   const getSearchBusiness = async () => {
  //     getAllServices(
  //       {
  //         area: navBarInputFields.location,
  //         itemDescription: "",
  //       },
  //       (data) => {
  //         setServiceList(data);
  //       }
  //     );
  //   };
  useEffect(() => {
    // getSearchBusiness();

    getSession("favProduct", (data) => {
      let obj = {};
      if (data) {
        obj = JSON.parse(data);
        let arr = Object.keys(obj).map((key) => {
          return obj[key];
        });

        setServiceList(arr);
      }
      console.log("favprod ", data);
    });
  }, []);
  //   useEffect(() => {
  //     getSearchBusiness();
  //   }, [navBarInputFields]);

  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState({});

  return (
    <Box sx={{ py: 5, backgroundColor: "#bb4a9b26" }}>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          <Box
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffffb3",
              borderRadius: "20px",
              px: "15px",
            }}
            onClick={() => navigate(routePath.LANDINGPAGE)}
          >
            Home
          </Box>
          <Typography
            color="secondary"
            sx={{
              backgroundColor: "#ffffffb3",
              borderRadius: "20px",
              px: "15px",
            }}
          >
            Services
          </Typography>
        </Breadcrumbs>
        <MediaModel open={open} setOpen={setOpen} modelData={modelData} />

        <Grid container spacing={3}>
          {serViceList.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <CardLanding
                item={item}
                openModel={setHide}
                addFav={() => {
                  console.log("favprod card cb");

                  favCard(item);
                }}
                remove={() => {
                  delfavCard(item);
                }}
                callback={() => {
                  setOpen(true);
                  setModelData({ ...item });
                  console.log("data click");
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <ApModal open={hide} setOpen={setHide} />
    </Box>
  );
};

export default FavRate;
