import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LogoImage from "../../assets/img/logo.svg"
import {
  Container,
  FormControl,
  TextField,
  Button,
  Typography,
  Checkbox,
   
  fontWeight,
  Card
} from "@mui/material"; 
import { setSession } from "../../utils/commonUtils";
import { registerApi } from "./registrationApi";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";

import { Link } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserRegistration = () => {
  const navigate=useNavigate()
  const [formData, setFormData] = useState({
    name: "",
    language: "en",
    mobileNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    registerApi(formData,(data)=>{
      console.log("Data",data)
      setSession("eleGentUser",data)
      navigate(routePath.OTP)
    })
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Card sx={{ flexGrow: 1 , padding:4}}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={6}>
              
                <img
                  src={LogoImage}
                  alt="Contact us"
                  style={{ width: "100%" }}
                />
               
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Mobile Number"
                    name="mobileNumber"
                    type="number"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ className: "mulish" }}
                    InputProps={{ classes: { input: "mulish" } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="User Name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ className: "mulish" }}
                    InputProps={{ classes: { input: "mulish" } }}
                  />
                </FormControl>
              </Grid>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox {...label} />
                <Typography>I have read and accept the</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                <Link to={routePath.TERMSOFSERVICE} style={{ textDecoration: "none", color: "#00b0dc",cursor:'pointer', fontWeight:"500" }}>
                  Terms of Service
                </Link>
                <Typography px={1}>and</Typography>
                <Link to={routePath.PRIVACYPOLICY} style={{ textDecoration: "none", color: "#00b0dc",cursor:'pointer', fontWeight:"500" }}>
                  Privacy Policy
                </Link>
              </Box>
              <Box sx={{ mt: 2}}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary" 
                  sx={{ backgroundColor: "#e80089ed" }}
                >
                  Submit
                </Button>
              </Box>
            
              <Box >
                <Typography>
                  Already Registered? 
                  
                  <Link
                to={routePath.LOGIN} 
                  style={{ 
                    textDecoration:"none",
                    color: "#2096A4",
                    cursor: "pointer",
                    fontWeight: "600", 
                  }}
                >
                 click here to Login!
                </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
};

export default UserRegistration;