import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EventIcon from "@mui/icons-material/Event";

import {
  Box,
  Divider,
  Fab,
  Grid,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAccountReachageDetails,
  getAllAppoint,
} from "./appoinmentDashboardApi";
import SmbAccountReachargeCard from "./AppoinmentDashboardCard";
import AddIcon from "@mui/icons-material/Add";
import DisplayAnnualRecharge from "./displayAnnualRecharge";
import { getData } from "./smbAccountRechargeCustomApiHandler";

import {
  convertDateToCustomFormat,
  getFirstDateOfMonth,
  getStartOfMonth,
} from "../../utils/commonUtils";
import AppoinmentDashboardCard from "./AppoinmentDashboardCard";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerComponent from "../../common/curstomDatePicker";
import Appoinmentadle from "../appoinmentModal/appoinmentModal";
import { UpdatedCustomerAppointment } from "../appoinmentModal/appoinmentModalApi";
import EmployeePayRollModal from "../employeePayRoll/employeeModal";
import EmployeeModal from "../../common/employeeModel";
import { PaginationCm } from "../../common/pagination";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

export default function AppoinmentDashboard() {
  // const [data, setData] = useState();
  const [pagination, setPagination] = useState({ page: 1,pageSize:1000, total: 1 });


  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [openT,aetOpenT]=useState(false)
  const datePickerRef = useRef();
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [search, setSearch] = useState("");

  const [date, setDate] = useState(getStartOfMonth());
  const [open, setOpen] = useState(false)

  const [SelectedStartDate, setSelectedStartDate] = useState(
    getFirstDateOfMonth()
  );


 
  const [modalData, setModalData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [value, setValue] = React.useState("Unassigned");
  const [data, setData] = useState();
  const [valuet, setValuet] = React.useState("Assigned");

  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
  const handleChanget = (event, newValue) => {
    // setPageNo(1);

    setValuet(newValue);
  };

  useEffect(() => {
    getAllAppoint({ page: pagination.page, pageSize: pagination.pageSize }, (res) => {
      console.log("getAllAppoint", res);
      setData(res?.list);
      setPagination({...pagination,total:res?.totalRecords})
    });
    // getData(getAccountReachageDetails, setData);
  }, []);
  useEffect(() => {


    if (open == false) {
      setSelectedCustomer(null)
    }

  }, [open])
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };
  useEffect(() => {
    console.log("selectedCustomer", selectedCustomer)
  }, [selectedCustomer])

  useEffect(() => {
    const inputDate = selectedDate;
    const date = new Date(inputDate);
    const formattedDate = date.toISOString().split("T")[0];
    if (formattedDate == "1970-01-01") {
      return;
    }
    getAllAppoint(
      { page: 1, pageSize: 1000, search: formattedDate, filter: "date" },
      (res) => {
        console.log("getAllAppoint", res);
        setData(res?.list);
        
      }
    );
  }, [selectedDate]);
  return (
 <>
  <DynamicBreadcrumbs />
 <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
 <Grid container>


<Grid xs={12}>
  <Tabs
    value={value}
    onChange={handleChange}
    textColor="primary"
    indicatorColor="primary"
    aria-label="primary tabs example"
  >
    <Tab
      value="Unassigned"
      label="Unassigned"
      onClick={() => {
        // setPaidBills("");
        // paginationApiCall(date, pageNo, "null");
      }}
      sx={{
        fontWeight: value === "Unassigned" ? "700" : "",
        width: "50%",
      }}
    />

    <Tab
      value="Assigned"
      label="Assigned"
      onClick={() => {
        // paginationApiCall(date, pageNo, "null", "paid");
      }}
      sx={{ fontWeight: value === "Assigned" ? "700" : "", width: "50%" }}
    />
  </Tabs>
  {value === "Assigned" && (
    <Tabs
      value={valuet}
      onChange={handleChanget}
      textColor="primary"
      indicatorColor="primary"
      aria-label="primary tabs example"
    >
      <Tab
        value="Assigned"
        label="Assigned"
        onClick={() => {
          // paginationApiCall(date, pageNo, "null", "paid");
        }}
        sx={{
          fontWeight: value === "assigned" ? "700" : "",
          width: "50%",
        }}
      />

      <Tab
        value="Completed"
        label="Completed"
        onClick={() => {
          // setPaidBills("");
          // paginationApiCall(date, pageNo, "null");
        }}
        sx={{
          fontWeight: value === "Completed" ? "700" : "",
          width: "50%",
        }}
      />

      <Tab
        value="Cancelled"
        label="Cancelled"
        onClick={() => {
          // paginationApiCall(date, pageNo, "null", "paid");
        }}
        sx={{
          fontWeight: value === "Cancelled" ? "700" : "",
          width: "50%",
        }}
      />
    </Tabs>
  )}
</Grid>





{value === "Unassigned" && (
  <Grid
    item
    xs={12}
    sx={{
      mt: "10px",
      fontWeight: "500",
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
    }}
  >
    <Fab
      onClick={() => {

        // setOpen(true);

        setOpen(true)

      }}
      sx={{
        textTransform: "capitalize",
        width: {
          xs: "100%",
          sm: "auto",
        },
        // p: "10px",
        whiteSpace: "nowrap",
             zIndex:'0',
      }}
      size="small"
      color="primary"
      variant="extended"
      aria-label="add"
    >
      Add Appointment
      <AddIcon />
    </Fab>
  </Grid>
)} 
<Grid xs={4}></Grid>

<Grid xs={4} 
sx={{display:'flex'}}
>
<DatePickerComponent
        callback={(data) => {
          console.log("date ", data);
          setSelectedDate(data);
        }}
      /> 
</Grid>

<Grid xs={4}

sx={{display:'flex'}}
>
   
    
      <Box
          readonly
          fullWidth
          onClick={() => {
            aetOpenT(true)

          }}

          sx={{
            display: "flex",
            height: "23px", 
            justifyContent: "space-between",
            // width: "70%",
            border: "1px solid #c9c9c9",
            p: 1,
            borderRadius: "4px ",
            color: "grey",
            '&:hover': {
              cursor: 'pointer !important',
            },
          }}
          onChange={(e) => {

            // setSearch(e.target.value);
          }}

        >
          <Typography variant="p">
            {search !== "" ? search : "Search by Name/Mobile Number"}
          </Typography>
          <SearchIcon
            onClick={() => {
              setSearchOutStandingsModal(true)
            }}
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            }}
          />
        </Box>

</Grid>

<Grid container sx={{ p: "10px" }}>
  {data?.map((item, index) => {

    // if (  value==="Assigned"  && item?.status !== valuet    ) {
    //   return;
    // }
    // if (valuet !== "Unassigned" && item?.status !== valuet) {
    //   return;
    // }
    //   if (item?.status === value      ) { 

    //   return (
    //     <AppoinmentDashboardCard item={item} key={"anualCard" + index} onClick={()=>{

    //       setOpen(true)
    //     }} />
    //   );
    // } 
    if (item?.status === valuet && value !== "Unassigned") {

      return (
        <AppoinmentDashboardCard
          value={value} valuet={valuet} item={item} key={"anualCard" + index} onClick={(data) => {
            console.log("item ", data)
            console.log("clicked data", data)

            setOpen(true)
            setSelectedCustomer(data)
          }}
          callback={(data) => {
            let payload = { ...item }
            console.log("Completed = ", { ...payload, status: "Completed" })
            if (data.api == 'complete') {
              UpdatedCustomerAppointment({ ...payload, status: "Completed" }, async (re) => {



                getAllAppoint({ page: 1, pageSize: 1000 }, (res) => {
                  console.log("getAllAppoint", res);
                  setData(res?.list);
                });

              })
            }
            if (data.api == 'cancel') {
              // UpdatedCustomerAppointment( {...item,status:"Cancelled"} ,   async(re)=>{


              //   getAllAppoint({ page: 1, pageSize: 1000 }, (res) => {
              //     console.log("getAllAppoint", res);
              //     setData(res?.list);
              //   });

              // })
            }

          }}

        />
      );
    }
    if (value === "Unassigned") {

      return (
        <AppoinmentDashboardCard value={value} valuet={valuet} item={item} key={"anualCard" + index} onClick={(data) => {
          console.log("item ", data)
          console.log("clicked data", data)

          setOpen(true)
          setSelectedCustomer(data)
        }}
          callback={(data) => {
            console.log("Completed = ", { ...item, status: "Completed" })
            let payload = { ...item }
            if (data.api == 'complete') {
              UpdatedCustomerAppointment({ ...payload, status: "Completed" }, async (re) => {



                getAllAppoint({ page: 1, pageSize: 1000 }, (res) => {
                  console.log("getAllAppoint", res);
                  setData(res?.list);
                  setOpen(false)
                });

              })
            }
            if (data.api == 'cancel') {
              UpdatedCustomerAppointment({ ...payload, status: "Cancelled" }, async (re) => {


                getAllAppoint({ page: 1, pageSize: 1000 }, (res) => {
                  console.log("getAllAppoint", res);
                  setData(res?.list);
                  setOpen(false)

                });

              })
            }

          }}

        />
      );
    }
    // else{
    //   return (
    //     <AppoinmentDashboardCard item={item} key={"anualCard" + index} onClick={(data)=>{
    //       console.log("clicked data",data)
    //       setSelectedCustomer(data)
    //       setOpen(true)
    //     }} />
    //   );
    // }
  })}
</Grid>
<Appoinmentadle open={open} setOpen={setOpen} selectedCustomer={selectedCustomer}




  apiCallback={() => {
    getAllAppoint({ page: 1, pageSize: 1000 }, (res) => {
      console.log("getAllAppoint", res);
      setData(res?.list);
    });
  }} />
{/* <DisplayAnnualRecharge open={open} setOpen={setOpen} /> */}
{/* <Pagination
    count={pagination.total}
    page={pagination.page}
    onChange={(event,value)=>{
      setPagination({
        ...pagination,
        page: value,
      });
    }}
  /> */}
<EmployeeModal
  // modalData={modalData}
  callback={(data)=>{
    setSearch(data?.name)
    aetOpenT(false)
  }}
  open={openT}
  setOpen={aetOpenT}

/>
</Grid>
 </Box>
 </>
  );
}
