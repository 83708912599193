

  
  
import { GETMONTLYGSTDETAILS } from "../../../utils/api";
import axios from "../../../utils/axios";

// }
export const getMonthlyDetails=async(param)=>{
    const res=await axios.post(GETMONTLYGSTDETAILS,param);
    return res;
}