import axios from "axios";

export const cityNameCll=async(callback)=>{ 
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const userIp = ipResponse.data.ip;
    console.log('User IP:', userIp);
   
    const locationResponse = await axios.get(`http://ip-api.com/json/${userIp}`);
    console.log('Location Data:', locationResponse.data);
    callback(locationResponse.data)
    }