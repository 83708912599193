import { USERMASTER, USERMPIN } from "../../utils/api";
import axios from "../../utils/axios"

export const setMpinApi=async(payload,callback)=>{ 
    await axios
    .post (USERMPIN,payload)
    .then((res) => {
      console.log("res search ", res);
    //   callback(res?.data?.data) 
      if(res?.data?.succeeded){
        callback(res?.data?.data) 
      }
      else{
        alert(res?.data?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  
}