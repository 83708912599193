import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Modal,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { setUserDetail } from "../app/store/reducer";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { routePath } from "../constants/routePath";

export default function LogOutPop({ open, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "20%",
          position: "absolute",
          borderRadius: "10px",
          left: "40%",
          top: "30%",
        }}
      >
        <CloseIcon
          sx={{ fontSize: "30px", float: "right", m: "10px" }}
          onClick={() => {
            setOpen(false);
          }}
        />

        <Box
          sx={{
            p: "10px",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  //   border: "1px solid grey",
                  padding: "16.5px 14px",
                  borderRadius: "4px",
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  marginTop: "4px",
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "550",
                  }}
                >
                  Are you sure to logout ?
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              sx={{
                //   width: "100%",
                p: "10px",
                mt: "10px",
                background: "red",
              }}
              onClick={async () => {
                setOpen(false);
                // await sessionStorage.removeItem("smbToken");
                dispatch(setUserDetail({}));
                navigate(routePath.LANDINGPAGE);
              }}
            >
              {" "}
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                //   width: "100%",
                p: "10px",
                mt: "10px",
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              {" "}
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
