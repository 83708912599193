import { GETALLCUSTOMERDETAILFORSALON, LASTSERVICEDETAILS } from "../../utils/api";
import axios from "../../utils/axios";


export const getAllCustomerDetail=async(payoad,callback)=>{
    await axios
    .post(GETALLCUSTOMERDETAILFORSALON,payoad)
    .then((res) => {
      console.log("res search ", res);
      callback(res.data.data) 
    })
    .catch((err) => {
      console.log(err);
    });
}

export const getLastDetail=async(payoad,callback)=>{
  await axios
  .post(LASTSERVICEDETAILS,payoad)
  .then((res) => {
    console.log("res search ", res);
    callback(res.data.data) 
  })
  .catch((err) => {
    console.log(err);
  });
}