export const validationAddPurchase = (purchaseItem) => {
  if (purchaseItem?.description === "") {
    return {
      status: false,
      msg: "Please Select Product",
    };
  } else if (purchaseItem?.amount === 0) {
    return { status: false, msg: "Please Enter Amount" };
  } else if (purchaseItem?.quantity === 0) {
    return { status: false, msg: "Please Enter Quantity" };
  }
  return { status: true, msg: "" };
};
