import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function PurchaseAccordian({ item }) {
  const calculateTotalPrice = (items) => {
    return items.reduce((total, item) => total + item.price, 0);
  };
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography
                color="primary"
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",

                  textOverflow: "ellipsis",

                  fontWeight: 600,
                }}
              >
                {convertDateToCustomFormat(item?.date?.split("T")[0])}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CurrencyRupeeIcon color="primary" fontSize="10px" />

              {/* {calculateTotalPrice(item?.purchaseDetails)} */}
              {item?.amount?.toFixed(2)}
            </Grid>
          </Grid>
        </AccordionSummary>
        {item?.purchaseDetails?.map((item2, index) => {
          return (
            <AccordionDetails sx={{
              // background:'red',
              p:"0px 3px",
              m:"0px"
            }}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      overflow: "Hidden",

                      textOverflow: "ellipsis",

                      fontWeight: 600,
                    }}
                  >
                    {item2?.description}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <CurrencyRupeeIcon color="primary" fontSize="10px" /> */}
                  {item2?.quantity}
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CurrencyRupeeIcon color="primary" fontSize="10px" />
                  {item2?.price?.toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CurrencyRupeeIcon color="primary" fontSize="10px" />
                  {item2?.amount?.toFixed(2)}
                </Grid>
              </Grid>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </div>
  );
}
