import * as React from "react";
import Box from "@mui/material/Box";
import "../App.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import logo from "../assets/headers/logoSMB.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Divider, Fab, Tooltip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { routePath } from "../constants/routePath";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  setBussinessSideBar,
  setCustomerManagmentDisplay,
  setDrawerShow,
  setTableName,
} from "../app/store/reducer";
import MenuIcon from "@mui/icons-material/Menu";
import Timer from "./timer";
import { setActiveHeader } from "./commonUtils/commonUtilFunction";
import { useState } from "react";
// smbfavicon
import smbfavicon from "../assets/img/logo.svg";
import LogOutPop from "./logoutPop";
// import LogOutPop from "./logoutPop";
import { DrawerTableBills } from "./drawerTableBills";
export default function Header({ menuLoginHeader, setDrawerShowSideBar }) {
  const location = useLocation();

  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const urlHeaderMenu = useSelector((state) => state.reducer.urlHeaderMenu);
  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  console.log(urlHeaderMenu, "urlHeaderMenu");

  const [currentPage, setCurrentPage] = React.useState(-1);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const actvieBussinessDetails = useSelector((state) => state.reducer.actvieBussinessDetails);
  console.log(actvieBussinessDetails,'actvieBussinessDetails')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reportActive, setReportActive] = useState(false);
  const checkHeaderUrl = (loc) => {
    const validPaths = [
      "/item-services",
      "/stocks",
      "/purchase",
      "/payament-receipt",
      "/outstanding-report",
      "/countersell",
      "/expence",
    ];

    return validPaths.includes(loc);
  };
  React.useEffect(() => {
    console.log("lcation", location.pathname);
    if (checkHeaderUrl(location.pathname)) {
      setReportActive(false);
      setCurrentPage(setActiveHeader(headerMenu, urlHeaderMenu, location));
    } else if (location.pathname === "/reports") {
      setReportActive(true);
      setCurrentPage(-1);
    } else {
      setCurrentPage(-1);
    }
  }, [location.pathname]);
  // React.useEffect(() => {
  //   console.log("lcation", location.pathname);
  //   if (checkHeaderUrl(location.pathname)) {
  //     setReportActive(false);
  //     setCurrentPage(setActiveHeader(headerMenu, urlHeaderMenu, location));
  //   } else if (location.pathname === "/reports") {
  //     setReportActive(true);
  //     setCurrentPage(-1);
  //   } else {
  //     setCurrentPage(-1);
  //   }
  // }, []);
  React.useEffect(() => {
    console.log("headerMenu ", headerMenu);
  }, [headerMenu]);

  const NameData = {
    Home: 1,
    "Business Mangment": 1,
    "Customer Managment": 1,
  };
  const [headerOption, setHeaderOption] = useState({
    arr1: [],
    arr2: [],
  });
  const [header1, setHeader1] = useState([]);
  const [header2, setHeader2] = useState([]);

  const arrN = (urlHeaderMenu) => {
    let arr1 = [...header1];

    let arr2 = [...header2];

    if (arr1.length == 0) {
      urlHeaderMenu.forEach((element, index) => {
        if (index % 2 == 0) {
          arr1.push(element);
        } else {
          arr2.push(element);
        }
        setHeader1(arr1);
        setHeader2(arr2);
      });
    }
  };
  const eventCallHeader = {
    BusinessMangment: () => {
      console.log("called function")
      dispatch(setBussinessSideBar(true));
    },
    CustomerManagment:()=>{
      dispatch(setCustomerManagmentDisplay(true));

    }
  };
  React.useEffect(() => {
    arrN(urlHeaderMenu);
  }, [urlHeaderMenu]);
  React.useEffect(() => {
    console.log("header2 header1 ", header1);

    console.log("header2 ", header2);
  }, [header2]);
  return (
    <Box sx={{ flexGrow: 1, borderBottom: "1px solid #edebed" }}>
      <Grid
        container
        spacing={0}
        sx={{ p: "0px 0px", height: "90px", backgroundColor: "#ffffff" }}
      >
        <Grid
          item
          xs={12}
          sm={1.1}
          md={1.1}
          lg={1.1}
          sx={{
            borderRight: "0.2px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            cursor: "pointer",
            height: "80px",
          }}
          onClick={() => {
            if (location.pathname !== routePath.LOGIN) navigate("/");
          }}
        >
          {/* logo */}
          <img
            src={smbfavicon}
            alt="logo"
            style={{ height: "80px", m: "10px" }}
          />
          {/* <Typography
            color="primary"
            sx={{
              fontSize: "10px",
              fontWeight: "700",

            }}>
            SHOW MY
            <span style={{
              color: "#FF6600"
            }}> BIILS</span>
          </Typography>
          <Typography color="secondary" sx={{
            fontSize: "10px",
            fontWeight: "700",

          }}>
            {" "}
          </Typography> */}
        </Grid>
        {menuLoginHeader && (
          <Grid
            item
            // xs={10}

            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {menuLoginHeader?.map((item, index) => (
              <Typography
                key={"menuLogin" + index}
                variant="h6"
                gutterBottom
                align="center"
                sx={{
                  margin: "0px 10px",
                }}
              >
                {item.title}
              </Typography>
            ))}
          </Grid>
        )}
        {!menuLoginHeader && (
          <Grid
            className="navHeader"
            item
            sx={{
              display: "flex",
              overflowY: "hidden",
              justifyContent: "space-between",
              overflowX: "auto", // Enable horizontal scrolling
              whiteSpace: "nowrap",
            }}
            // xs={location.pathname === routePath.COUNTERSELL ? 9.5 : 10}
            // sm={location.pathname === routePath.COUNTERSELL ? 8.5 : 10}
            // md={location.pathname === routePath.COUNTERSELL ? 8.5 : 10}
            // lg={location.pathname === routePath.COUNTERSELL ? 9.5 : 10}
            xs={7.9}
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   // justifyContent: "left",
            //   height: "60px",
            //   background: "red",
            //   // background: "red",
            // }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "left",
                height: "80px",
                width: "90%",
                // background: "red",
                // background: "red",
              }}
            >
              {header1?.map((item, index) => {
                return (
                  <Box>
                    <Box
                      bgcolor={
                        index === header1?.length - 1
                          ? "secondary.main"
                          : "primary.main"
                      }
                      textAlign="center"
                      sx={{
                        borderRadius: "8px",
                        // minWidth: "12rem",
                        // alignContent: "center",

                        padding: "0px 20px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "10px 5px",
                        justifyContent: "center",
                        minHeight: "2.0rem",
                        // width
                        // margin: "0px 1px",
                      }} 
                      onClick={() => {
                        console.log("item==== ",item)
                      
                          navigate(item?.route);
                       
                        
                      }}
                      boxShadow={4} // Adds shadow effect to the box
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        key={"headerMenu" + index}
                        align="center"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          p: 0,
                          m: 0,

                          cursor: "pointer",
                          color: currentPage === index ? "yellow" : "white",
                        }}
                      >
                        {(function () {
                          const indexRoute = headerMenu.findIndex(
                            (it) =>
                              it.systemFuctionName === item.systemFuctionName
                          );
                          if (NameData[item?.systemFuctionName]) {
                            return item?.systemFuctionName;
                          }
                          if(headerMenu[indexRoute]?.regionalFunctionName.includes("Counter Sale")){
                            return "Billing"
                          }
                          return headerMenu[indexRoute]?.regionalFunctionName;
                        })()}
                      </Typography>
                    </Box>

                    {true && (
                      <Box
                        bgcolor={"primary.main"}
                        textAlign="center"
                        sx={{
                          borderRadius: "8px",
                          // minWidth: "12rem",
                          // alignContent: "center",

                          padding: "0px 20px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          margin: "0px 5px",
                          justifyContent: "center",
                          minHeight: "2.0rem",
                          // width
                          // margin: "0px 1px",
                        }}
                        onClick={() => {
                          const indexRoute = header2.findIndex(
                            (it) =>
                              it.systemFuctionName === item.systemFuctionName
                          );
                          // console.log();
                          //  console.log()

                          const key = header2[index]?.systemFuctionName.replace(
                            /\s+/g,
                            ""
                          );
                          console.log("Key after removing spaces:", key); // Check if it logs "BusinessMangment"
                          if(item.systemFuctionName==="Cash Sale Bill"){
                            dispatch(setDrawerShow(!drawerShow))
                            return
                          } 
                          if (eventCallHeader[key]) {
                            eventCallHeader[key](); // Call the function
                            return;
                          } else {
                            console.error(`No function found for key: ${key}`);
                          }
                          navigate(header2[index]?.route);
                        }}
                        boxShadow={4} // Adds shadow effect to the box
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          key={"headerMenu" + index}
                          align="center"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            p: 0,
                            m: 0,

                            cursor: "pointer",
                            color: currentPage === index ? "yellow" : "white",
                          }}
                        >
                          {(function () {
                            const indexRoute = headerMenu.findIndex(
                              (it) =>
                                it.systemFuctionName ===
                                header2[index]?.systemFuctionName
                            );

                            if (!headerMenu[indexRoute]?.regionalFunctionName) {
                              return header2[index]?.systemFuctionName;
                            }

                            console.log(
                              "indexRoute ",
                              headerMenu[indexRoute],
                              " ",
                              indexRoute
                            );
                            return headerMenu[indexRoute]?.regionalFunctionName;
                          })()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          
            <Box sx={{display:'flex', alignItems:'center', gap:3}}>
              <Box component={'img'} src={actvieBussinessDetails?.logoPath} sx={{width:'70px', height:'70px', borderRadius:'50%'}}/>
            <Box>
            <Typography sx={{fontSize:'14px', color:'primary.main', fontWeight:'600'}}> {actvieBussinessDetails.enterpriseName}</Typography>
            <Typography sx={{fontSize:'14px', color:'primary.main', fontWeight:'600'}}> {actvieBussinessDetails.branchName}</Typography>
            </Box> 
          </Box>
          </Grid>
        )}
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "10px",
          }}
        >         
          <Box
            sx={{ display: { lg: "flex", alignItems: "center", gap: "10px" } }}
          >
            <Box
              sx={{
                display: "flex",
                alignItem: "center",
              }}
            >
              <LocalPhoneIcon
                sx={{
                  mr: "4px",
                  fontSize: "21px",
                }}
                color="primary"
              />
              <Typography
                color="primary.main"
                variant="subtitle2"
                margin={0}
                fontWeight={"bold"}
              >
                {userDetail?.mobileNumber}
              </Typography>
            </Box>
            <Timer />
          </Box>

          {true && (
            <Fab
              color="primary"
              aria-label="Add to Cart"
              size="small"
              // sx={{ float: "right" , }}
              onClick={() =>  setDrawerShowSideBar(true)}
            >
              <Tooltip title="Settings">
                <SettingsIcon />
              </Tooltip>
            </Fab>
          )}

          <Fab
            // color="red"
            sx={{
              ml:{md:1.5},
              background: "red",
            }}
            aria-label="Add to Cart"
            size="small"
            // sx={{ float: "right" , }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Tooltip title="Log Out">
              {/* <LogoutIcon /> */}
              <PowerSettingsNewIcon />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      <LogOutPop open={open} setOpen={setOpen} />

      {/* {userDetail?.businessLineCode === "CB"  && <DrawerTableBills />} */}

      {location.pathname !== routePath.COUNTERSELL && <DrawerTableBills />}
    </Box>
  );
}
