
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { getAlldetailSerice } from "../features/services/servicesListApi";

const CustomTabPanel = (props) => {
  const { children, value, index, width, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: width || (isMobile ? "100%" : isTab ? "60%" : "40%"),
        transition: "width 0.3s ease",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  width: PropTypes.string,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Detail = () => {
  const detailInfoArr = [
    {
      enterpriseName: "Urban Salon",
      address: "Vishnu Dev Nagar, Wakad, Pimpri-Chinchwad, Maharashtra 411057",
      area: "Pune",
      openingTime: "11AM",
      closingTime: "9PM",
      serviceAvailability: "Home/Site visit",
      businessCategory: "Unisex Parlour",
      branchName: "6Th",
      city: "Pune",
      district: "Pune",
      state: "Maharashtra",
      pinCode: 411057,
      mobNumberOne: 5656565656,
      mobNumberTwo: 8989898989,
      mailOne: "dummyone@gmail.com",
      mailTwo: "dummytwo@gmail.com",
    },
  ];
  // const info= {
  //     enterpriseName: "Urban Salon",
  //     address: "Vishnu Dev Nagar, Wakad, Pimpri-Chinchwad, Maharashtra 411057",
  //     area: "Pune",
  //     openingTime: "11AM",
  //     closingTime: "9PM",
  //     serviceAvailability: "Home/Site visit",
  //     businessCategory: "Unisex Parlour",
  //     branchName: "6Th",
  //     city: "Pune",
  //     district: "Pune",
  //     state: "Maharashtra",
  //     pinCode: 411057,
  //     mobNumberOne: 5656565656,
  //     mobNumberTwo: 8989898989,
  //     mailOne: "dummyone@gmail.com",
  //     mailTwo: "dummytwo@gmail.com"
  // }
  const info = useSelector((state) => state.reducer.detail);

  return (
    <Grid container direction="column" spacing={2}>
      {/* {detailInfoArr.map((info, index) => ( */}
      <React.Fragment>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Enterprise Name :{" "}
              <span style={{ color: "#00A69C" }}>{info.enterpriseName}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Address : <span style={{ color: "#00A69C" }}>{info.address}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Area : <span style={{ color: "#00A69C" }}>{info.area}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{borderColor: "divider"}}>
       <Box sx={{display:'flex', direction:'row', justifyContent:'space-between', borderBottom: 1, borderColor: "divider"}}>
       <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Opening Time :{" "}
              <span style={{ color: "#00A69C" }}>{info.openingTime}</span>
            </Typography>
          </Box>
             <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Closing Time :{" "}
              <span style={{ color: "#00A69C" }}>{info.closingTime}</span>
            </Typography>
          </Box>
       </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Service Available At :{" "}
              <span style={{ color: "#00A69C" }}>
                {(() => {
                  if (info.serviceFrom == "Both") {
                    return "Shop, Home/site Visit";
                  }
                  if (info.serviceFrom == "In shop") {
                    return "Shop";
                  }
                  if (info.serviceFrom == "Home service") {
                    return "Shop, Home/site Visit";
                  }
                  return "Shop";
                })()}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Business Category :{" "}
              <span style={{ color: "#00A69C" }}>{info.businessCategory}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Branch Name :{" "}
              <span style={{ color: "#00A69C" }}>{info.branchName}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              City : <span style={{ color: "#00A69C" }}>{info.city}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
         <Box sx={{display:'flex', direction:'row', justifyContent:'space-between', borderBottom: 1, borderColor: "divider"}}>
         <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              District :{" "}
              <span style={{ color: "#00A69C" }}>{info.district}</span>
            </Typography>
          </Box>
          <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              State : <span style={{ color: "#00A69C" }}>{info.state}</span>
            </Typography>
          </Box>
         </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Pin Code :{" "}
              <span style={{ color: "#00A69C" }}>{info.pinCode}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
       <Box sx={{display:'flex', direction:'row', justifyContent:'space-between', borderBottom: 1, borderColor: "divider"}}>
       <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Mobile Number 1 :{" "}
              <span style={{ color: "#00A69C" }}>{info.mobileNumber}</span>
            </Typography>
          </Box>
          <Box sx={{pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Mobile Number 2 :{" "}
              <span style={{ color: "#00A69C" }}>{info.mobileNumber2}</span>
            </Typography>
          </Box>
       </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Mail ID 1 :{" "}
              <span style={{ color: "#00A69C" }}>{info.mailID1}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
            <Typography style={{ color: "#e80089ed" }} className="mulish">
              Mail ID 2 :{" "}
              <span style={{ color: "#00A69C" }}>{info.mailID2}</span>
            </Typography>
          </Box>
        </Grid>
      </React.Fragment>
      {/* ))} */}
    </Grid>
  );
};

const Services = () => {
  const [pagination, setPagination] = useState({ page: 1, total: 1,pageSize:5 });

  const [servicesArr, setservicesArr] = useState([
    {
      serviceName: "Shaving",
      rate: "100.00/Per Head",
    },
    {
      serviceName: "Hair cutting",
      rate: "150.00/Per Head",
    },
  ]);
  const infoD = useSelector((state) => state.reducer.detail);
  useEffect(() => {
    getAlldetailSerice(
      {
        billerId: infoD?.billerId,
        page:pagination.page,
        pageSize:pagination.pageSize
      },
      (res) => {
        console.log("Data res", res);
        setPagination((prev)=>{
          return {...prev,total:res.totalPages}
        })
        setservicesArr(res.data);
      }
    );
  }, []);
  const handleChange = (event, value) => {
    getAlldetailSerice(
      {
        billerId: infoD?.billerId,
        page:pagination.page,
        pageSize:pagination.pageSize
      },
      (res) => {
        console.log("Data res", res);
        // setPagination((prev)=>{
        //   return {...prev,total:res.totalPages}
        // })
        setservicesArr(res.data);
      }
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };

  return (
    <Box sx={{}}>
      <Box
        container
        spacing={2}
        sx={{ mb: 2 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "12px",
        }}
      >
        <Box item xs={6}>
          <Typography
            sx={{ fontWeight: "bold", width: "191px", wordBreak: "break-all" }}
            className="mulish"
          >
            SERVICE NAME
          </Typography>
        </Box>
        <Box item xs={6}>
          <Typography
            sx={{ fontWeight: "bold", wordBreak: "break-all" }}
            className="mulish"
          >
            RATE(₹)
          </Typography>
        </Box>
      </Box>
      {servicesArr?.map((info, index) => (
        <Box
          container
          spacing={2}
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "space-between",
            paddingInline: "20px",
            marginBottom: "20px",
            boxShadow: "0 0px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "6px",
          }}
        >
          <Box>
            <Typography
              key={index}
              sx={{ py: 2, fontWeight: "bold", color: "#e80089ed" }}
              className="mulish"
            >
              {info?.itemDescription}
            </Typography>
          </Box>
          <Box>
            <Typography
              key={index}
              sx={{ py: 2, color: "#00A69C" }}
              className="mulish"
            >
              {info?.price}
            </Typography>
          </Box>
        </Box>
      ))}
      <Pagination
          count={pagination.total}
          page={pagination.page}
          onChange={handleChange}
        />
    </Box>
  );
};

function DetailPage() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fdf8fe",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#5A396D",
            },
          }}
        >
          <Tab
            label="Overview"
            {...a11yProps(0)}
            className="mulish"
            sx={{
              "&.Mui-selected": {
                color: "#e80089ed",
              },
            }}
          />
          <Tab
            label="Services"
            {...a11yProps(1)}
            className="mulish"
            sx={{
              "&.Mui-selected": {
                color: "#e80089ed",
              },
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Detail />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={1}
        width={isMobile ? "100%" : isTab ? "60%" : "35%"}
      >
        <Services />
        
      </CustomTabPanel>
    </Box>
  );
}

export default DetailPage;
