
// const 

import { ADDBILLERSERICE, GELLALLCATEGORIESHELPSUPPORT, GETALLUSERFAQ } from "../../utils/api";
import axios from "../../utils/axios"

export const getCategory=async()=>{
    const res=await axios.get(GELLALLCATEGORIESHELPSUPPORT )
    return res;
}
export const getAllUserFaq=async(param)=>{
    
    const res=await axios.post(GETALLUSERFAQ,param);
    return res;
}

export const addUserFaq=async(param)=>{
    const res=await axios.post(ADDBILLERSERICE,param);
    return res;
}