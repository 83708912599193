import {
    Box,
    Button,
    Typography,
    Modal,
    Grid,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    Divider,
  } from "@mui/material";
  import GoogleIcon from "@mui/icons-material/Google";
  
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router";
  import CloseIcon from "@mui/icons-material/Close";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { useDispatch, useSelector } from "react-redux";
  import CancelIcon from "@mui/icons-material/Cancel";
  import LinkCo from "./myComponent";
  import MediaCard from "./mediaCard";
  
  import PublicIcon from "@mui/icons-material/Public"; // Earth icon
  import InstagramIcon from "@mui/icons-material/Instagram";
  import YouTubeIcon from "@mui/icons-material/YouTube";
  import FacebookIcon from "@mui/icons-material/Facebook";
  
  import Facebook from '../assets/img/icon/facebook.png'
  import Google from '../assets/img/icon/google.png'
  import Instagram from '../assets/img/icon/instagram.png'
  import Youtube from '../assets/img/icon/youtube.png'
  import Web from '../assets/img/icon/web.png'
  import Pinterest from '../assets/img/icon/pinterest.png'
  import Twitter from '../assets/img/icon/twitter.png'
  import LinkedIn from '../assets/img/icon/linkedin.png'
import MediaCardInput from "./mediaCardInput";
  const MediaModelApp = ({ open, setOpen, modelData,setModalData }) => {
    const serviceViceSearch = useSelector(
      (state) => state.reducer.serviceViceSearch
    );
    const navBarInputFields = useSelector(
      (state) => state.reducer.navBarInputFields
    );
    const [searchValue, setSeacrchValue] = useState("");
    const [indexTableItem, setIndexTableItem] = useState();
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            background: "white",
            padding:'10px',
            width: {
              xs: "90vw",
              sm: "50vw",
              lg:'30vw'
            },
            borderRadius: "10px",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
         
          }}
        >
          <Box>
          <Typography sx={{textAlign:'center'}} variant='h6'>Media Links</Typography>
          <Divider/>
            <Grid
              container
              sx={{
                overflowY: "scroll",
                maxHeight: "70vh",
                borderTopLeftRadius: "20px",
                pt:1,
              }}
            >
              <Grid item xs={12} sx={{}}>
                 
                  <MediaCardInput url={modelData.google} Icon={Google}   
                  title={"GOOGLE"} value={modelData?.google} setValue={(data)=>{
                    setModalData({...modelData,google:data})
                  }} 
                  />
               
                
                  <MediaCardInput url={modelData.website} Icon={Web} 
                  title={"WEBSITE"} value={modelData?.website} setValue={(data)=>{
                    setModalData({...modelData,website:data})
                  }} 
                  />
                
                
                  <MediaCardInput   
                  title={"INSTAGRAM"}
                  url={modelData.instagram} Icon={Instagram}  
                  
                  value={modelData?.instagram} setValue={(data)=>{
                    setModalData({...modelData,instagram:data})
                  }} />
               
 
                  <MediaCardInput  
                  title={"FACEBOOK"}
                  url={modelData.facebook} Icon={Facebook}  value={modelData?.facebook} setValue={(data)=>{
                    setModalData({...modelData,facebook:data})
                  }}/>
                  
                  <MediaCardInput 
                  title={"YOU TUBE"}
                  url={modelData.youTube} Icon={Youtube}  value={modelData?.youTube} setValue={(data)=>{
                    setModalData({...modelData,youTube:data})
                  }}/>
                  
                 
 
                  <MediaCardInput 
                  title={"Pinterest"}
                  url={modelData.pinterest} Icon={Pinterest}  value={modelData?.pinterest} setValue={(data)=>{
                    setModalData({...modelData,pinterest:data})
                  }}/>
                  
                  <MediaCardInput 
                  title={"TWITTER"}
                  url={modelData.twitter} Icon={Twitter}  
                  
                  value={modelData?.twitter} setValue={(data)=>{
                    setModalData({...modelData,twitter:data})
                  }}/>
 
                  <MediaCardInput 
                  title={"LINKEDIN"}
                  url={modelData.linkedIn} Icon={LinkedIn}  value={modelData?.linkedIn} setValue={(data)=>{
                    setModalData({...modelData,linkedIn:data})
                  }}/>
                 
  
                {/* de  */}
              
              
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  };
  export default MediaModelApp;
  