import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect } from "react";
import { useState } from "react";
import AutofocusTextFields from "./autoFocusTextField";
import { encrypt } from "../../utils/encryption";
import Swal from "sweetalert2";
import { updatePin } from "./resetPinApi";
import { getUpdateData } from "./resetPinCustomApiHander";
import { swalAlertPopProps } from "../../utils/commonUtils";
import { ENCRYPTIONKEY } from "../../utils/api";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

const ResetMpin = () => {
  const [itemData, setItemData] = useState({ passcode: "" });
  const [itemData2, setItemData2] = useState({ passcode: "" });

  function generateRandomString(length) {
    const chars = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }
  return (
    <>
       <DynamicBreadcrumbs />
    <Box>
      <Container maxWidth="sm">
        <Grid
          container
          sx={{
            p: "20px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              fontWeight={"600"}
              color="primary"
              sx={{ m: "20px 0px" }}
            >
              Update M pin
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{ m: "20px 0px" }}
            >
              Enter M pin
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <AutofocusTextFields
              itemData={itemData}
              setItemData={setItemData}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{ m: "20px 0px" }}
            >
              Confirm Enter M pin
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <AutofocusTextFields
              itemData={itemData2}
              setItemData={setItemData2}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{
                mt: "20vh",
                width: "100%",
              }}
              onClick={() => {
                console.log("mpin reset ", itemData2.passcode);
                const randomString = generateRandomString(4);
                let encoded = encrypt(
                  itemData2.passcode + randomString,
                  ENCRYPTIONKEY
                );
                if (
                  itemData2.passcode !== itemData.passcode ||
                  itemData2.passcode.length != 4 ||
                  itemData.passcode.length != 4
                ) {
                  Swal.fire({
                    text: "Please Enter Same value",
                    icon: "warning",

                    ...swalAlertPopProps,
                  });
                } else {
                  getUpdateData(
                    {
                      mPin: encoded,
                      isBiometricAuthentication: false,
                    },
                    updatePin,
                    Swal
                  );
                }

                console.log("call ", {
                  mPin: encoded,
                  isBiometricAuthentication: true,
                });
              }}
              variant="contained"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  );
};

export default ResetMpin;
