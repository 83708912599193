import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";

import { payloadDateFormat } from "../../utils/commonUtils";
import { getAllUserFaq, getCategory } from "./helpAndSupportApi";
import HelpSupportAccordian from "./helpAndSupportAccordian";
import { getCate, getFaq } from "./helpAndSupportCustomApiHandlers";
import { filter } from "lodash";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

// import PuchaseModal from "./purchaseModal";

// import { payloadDateFormat } from " ./../utils/commonUtils";
const HelpAndSupport = () => {
  const [open, setOpen] = useState(false);

  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 4,
  });

  const datePickerRef = useRef();

  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const handleChange = (event, value) => {
    getFaq(
      {
        page: value,
        pageSize: pagination.pageSize,
        filterByColumn: "string",
        filterValue: filterBy?.category,
        searchByColumn: "string",
        searchValue: "string",
        orderByColumn: "string",
        orderBy: "string",
      },
      getAllUserFaq,
      setNumberOfRecords,
      setfaq
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };

  const [filters, setFilters] = useState([]);
  const [selectedFilterObj, setSelectedFilterObj] = useState();
  const [faq, setfaq] = useState([]);

  const [filterBy, setFilterBy] = useState();
  useEffect(() => {
    console.log("filters ", filterBy);
  }, [filters]);
  useEffect(() => {
    getCate(getCategory, setFilterBy, setFilters);
    getFaq(
      {
        page: pagination.page,
        pageSize: pagination.pageSize,
        filterByColumn: "string",
        filterValue: filterBy?.category,
        searchByColumn: "string",
        searchValue: "string",
        orderByColumn: "string",
        orderBy: "string",
      },
      getAllUserFaq,
      setNumberOfRecords,
      setfaq
    );

    // setFilterBy(filters[0].category);
    console.log("datE ", payloadDateFormat());
  }, []);
  const [SelectedEndDate, setSelectedEndDate] = useState(null);
  return (
    <>
       <DynamicBreadcrumbs />
     <Box  sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
     <Grid
        container
        sx={{
          overflowY: "auto",
          height: "calc(100vh-66px)",

          // sx={{displa}
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            fontWeight: "bold",
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFilterObj?.category}
              label="Filter By"
              onChange={(e) => {
                const ind = filters.findIndex(
                  (data) => data.category == e.target.value
                );
                console.log("ind help", filters[ind]);
                getFaq(
                  {
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    filterByColumn: "categoryid",
                    filterValue: String(filters[ind].categoryId),
                    searchByColumn: "",
                    searchValue: "",
                    orderByColumn: "",
                    orderBy: "",
                  },
                  getAllUserFaq,
                  setNumberOfRecords,
                  setfaq
                );
                setSelectedFilterObj(filters[ind]);
              }}
            >
              {filters.map((item, index) => {
                return (
                  <MenuItem value={item.category} key={"option" + index}>
                    {item.locCategory}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          {faq?.map((item, index) => {
            return (
              <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: "10px" }}>
                <HelpSupportAccordian
                  item={item}
                  selectedFilterObj={selectedFilterObj}
                />
              </Grid>
            );
          })}
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography color="primary">
            <strong>Total Items: &nbsp;</strong>
            {/* {totalItems} */}
            {/* {pagination.records} */}
          </Typography>
          <Pagination
            count={
              Number.isInteger(numberOfRecords / pagination.pageSize)
                ? numberOfRecords / pagination.pageSize
                : parseInt(numberOfRecords / pagination.pageSize) + 1
            }
            page={pagination.page}
            onChange={handleChange}
          />
        </Stack>
      </Grid>
     </Box>
    </>
  );
};

export default HelpAndSupport;
