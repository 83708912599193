export const TotalAmount = (billData) => {
  let sum = 0;
  let priceWithoutGst = 0;
  billData?.counterSalesChild?.forEach((item) => {
    sum += item.amount;
    priceWithoutGst+=(item.rate * item.quantity)
  });
console.log({amount:sum.toFixed(2),
priceWithoutGst:priceWithoutGst},'tstttt')
  return{ 
    amount:sum.toFixed(2),
    priceWithoutGst:priceWithoutGst
  }
};

export const validationUpdate = (billData) => {
  if (billData?.paymentMode === "Credit" && billData?.customer.trim() === "") {
    return {
      status: false,
      msg: "please enter username",
    };
  }
  if (
    billData?.paymentMode === "Credit" &&
    billData?.customerMobile.length !== 10
  ) {
    return {
      status: false,
      msg: "please enter Mobile",
    };
  }
  if (billData?.counterSalesChild?.length === 0) {
    return {
      status: false,
      msg: "Please Select Product",
    };
  }
  return { status: true, msg: "" };
};
