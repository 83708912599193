import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
// import { getdashBoardApi } from "../../utils/utils";
import DescriptionIcon from '@mui/icons-material/Description';
import { routePath } from "../../constants/routePath";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { getdashBoardApi } from "../../utils/utils";
import { getData2 } from "./dashboardCustomApiHandlers";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export default function Dashboard() {
  const userDetail = useSelector((state) => state.reducer.userDetail);

  const [dashboard, setDashboard] = useState(null);

  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    if (
      location.pathname === routePath.DASHBOARD &&
      Object.keys(userDetail).length !== 0
    ) {
      getData2(getdashBoardApi, setDashboard);
      if (userDetail.appMenu == "Customer") {
        // navigate("")
      }
    }
  }, [location.pathname, userDetail]);
  // useEffect()
  
  return (
    <Box>
      <DynamicBreadcrumbs/>
      {dashboard && ( <Box sx={{
        border:'2px solid #bb4a9b',
        borderRadius:'10px',
        padding:4,
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
        <Typography sx={{color:'#bb4a9b', mb:2, fontSize:'20px',  borderBottom:'2px solid #bb4a9b', fontWeight:'600'}}>Appointments</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} md={3}>
            <Stack sx={{ boxShadow:'8px 6px 8px 0px #27272787', backgroundColor: '#2fa3cb', py: 1, justifyContent: 'center', alignItems: 'center', borderRadius: '10px', color: '#fff' }}>
              <Typography sx={{ fontSize: '20px' }}>Unassigned</Typography>
              <Typography sx={{ fontSize: '35px',fontWeight:'600' }}>  {dashboard?.todaysUnassignedAppointments}</Typography>
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12} md={3}>
            <Stack sx={{ boxShadow:'8px 6px 8px 0px #27272787', backgroundColor: '#cdb51c', py: 1, justifyContent: 'center', alignItems: 'center', borderRadius: '10px', color: '#fff' }}>
              <Typography sx={{ fontSize: '20px' }}>Assigned</Typography>
              <Typography sx={{ fontSize: '35px',fontWeight:'600' }}> {dashboard?.todaysAssignedAppointments}</Typography>
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12} md={3}>
            <Stack sx={{ boxShadow:'8px 6px 8px 0px #27272787', backgroundColor: '#427d42', py: 1, justifyContent: 'center', alignItems: 'center', borderRadius: '10px', color: '#fff' }}>
              <Typography sx={{ fontSize: '20px' }}>Completed</Typography>
              <Typography sx={{ fontSize: '35px',fontWeight:'600' }}> {dashboard?.todaysCompletedAppointments}</Typography>
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12} md={3}>
            <Stack sx={{ boxShadow:'8px 6px 8px 0px #27272787', backgroundColor: '#c34949', py: 1, justifyContent: 'center', alignItems: 'center', borderRadius: '10px', color: '#fff' }}>
              <Typography sx={{ fontSize: '20px' }}>Cancelled</Typography>
              <Typography sx={{ fontSize: '35px',fontWeight:'600' }}> {dashboard?.todaysCancelledAppointments}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9} xl={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                marginTop:'20px'
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Sales
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  p: "10px",
                  lineHeight: "2.3rem",
                }}
              >
                  <Grid md={5}> </Grid>
                <Grid md={3.5}>   <Box component="span">Today's</Box></Grid>
                <Grid md={3.5}>   <Box component="span">Monthly</Box></Grid>
                <Grid item xs={5}>
                  <Box component="span">Total Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                   alignItems:'center'
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCounterSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                   alignItems:'center'
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthSale?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography component="span">Counter Sales:</Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCashSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthSaleCashOnline?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box component="span">Credit Sale:</Box>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCreditSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthSaleCredit?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box component="span">Expenses:</Box>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.todayExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.monthlyExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box component="span">Number of Bills:</Box>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalNumberOfBillsForDay}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalNumberOfBillsForMonth}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box component="span">Product Sold:</Box>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentDayProductSold}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthProductSold}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box component="span">Services Sold:</Box>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center' }}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentDayServicesSold}
                  </Typography>
                </Grid>
                <Grid item xs={3.5} sx={{ color: "#0da3ca", display: "flex", alignItems:'center'}}>
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthServicesSold}
                  </Typography>
                </Grid>

              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>)}
      {/* {dashboard && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 40px)",

            // p: "50px",
            padding: {
              xs: "10px",
              sm: "50px 10px 10px 10px ",
              md: "50px",
              // Adjust the desired padding value
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Sales
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  p: "10px",
                  lineHeight: "2.3rem",
                }}
              >
                <Grid item xs={8}>
                  <Box component="span">Today's Total Counter Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCounterSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component="span">Total Cash Sales:</Typography>
                </Grid>
                <Grid item xs={4} sx={{ color: "green", display: "flex" }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCashSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Total Credit Sale:</Box>
                </Grid>
                <Grid item xs={4} sx={{ color: "red", display: "flex" }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCreditSales?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",
                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Average Sales:
                </Typography>
              </Box>

              <Grid
                container
                sx={{
                  lineHeight: "2.3rem",
                  p: "10px",
                }}
              >
                <Grid item xs={8}>
                  <Box component="span">Average Daily Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.averageDailySales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{}}>
                  <Box component="span">Today's Total Difference:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.todaysTotalDifference?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Weekly Sales:
                </Typography>
              </Box>

              <Grid container sx={{ lineHeight: "2.3rem", p: "10px" }}>
                <Grid item xs={8}>
                  <Box component="span">Current Week Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentWeekSale?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Last Week Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.lastWeekSale?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
                overflow: "hidden", // Add this line
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",
                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Monthly Sales:
                </Typography>
              </Box>

              <Grid
                container
                sx={{ lineHeight: "2.3rem", p: "10px", overflowX: "auto" }}
              >
                <Grid item xs={8}>
                  <Box component="span">Current Month Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthSale?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Last Month Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.lastMonthSale?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#3695B6de",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Expense Information

                </Typography>
              </Box>

              <Grid
                container
                sx={{ lineHeight: "2.3rem", p: "10px", overflowX: "auto" }}
              >
                <Grid item xs={8}>
                  <Box component="span">
                    Todays Expenses:
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.todayExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Current Week Expense:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.weeklyExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Current Month Expanse:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.monthlyExpense?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )} */}
    </Box>
  );
}
