import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Container,
  Grid,
  CardMedia,
  IconButton,
  Box,
  Checkbox,
} from "@mui/material";
import male from "../../assets/salon_male.png";
import female from "../../assets/salon_female.png";
import {
  FavoriteBorder,
  AppRegistration as AppRegistrationIcon,
  Share as ShareIcon,
  WhatsApp as WhatsAppIcon,
  LocalOffer as LocalOfferIcon,
  Info as InfoIcon,
  PlayArrow as PlayArrowIcon,
  Call as CallIcon,
  Directions as DirectionsIcon,
  Favorite,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { information } from "../../constants/locationData";

import { api } from "../../utils/api";
import axios from "../../utils/axios";
import { routePath } from "../../constants/routePath";
import { useNavigate } from "react-router";
import { setAppoinmentType, setDetail } from "../../app/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../../utils/commonUtils";
import ApModal from "../AppoinmetModalppBtn";

const CardLanding=({item,callback,openModel,addFav,remove})=>{
  const userDetail = useSelector((state) => state.reducer.userDetail);
    const navigate=useNavigate()
    const dispatch=useDispatch()

    
    const favProduct = useSelector((state) => state.reducer.favProduct);



  const label = { inputProps: { "aria-label": "Checkbox demo" } };

    
      const handleRedirect = ({latitude,longitude}) => {
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(googleMapsUrl, '_blank');
      };
      const handleWhatsAppClick = (phone) => {
        if (phone) {
          const whatsappUrl = `https://wa.me/${phone}`;
          window.open(whatsappUrl, "_blank");
        }
      };
      const handleContactClick = (phone) => {
        if (phone) {
          window.location.href = `tel:${phone}`;
        }
      };
    return (
      <>
     
        <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 10%)",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
       
      >
        <CardActionArea
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            bgcolor: "#fff",
            "&:hover": {
              backgroundColor: "transparent",
              cursor: "default",
            },
          }}
          disableRipple
        >
          <Grid container spacing={1} sx={{ padding: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h6" component="div">
                {item.enterpriseName}
              </Typography>
              <Typography variant="body2" component="div">
                {item.businessCategory}
              </Typography>
              <Typography variant="body2" component="div" sx={{fontWeight:'bold'}}>
                {item.area}
              </Typography>
            
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent={"end"}
              >
                {(item.serviceTo=="Male" || item.serviceTo=="Unisex") && (
                  <Grid item>
                    <CardMedia
                      component="img"
                      sx={{ width: 30, height: 30, objectFit: "contain" }}
                      image={male}
                      alt={"men"}
                    />
                  </Grid>
                )}
                {(item.serviceTo=="Female" || item.serviceTo=="Unisex") && (
                  <Grid item>
                    <CardMedia
                      component="img"
                      sx={{ width: 30, height: 30, objectFit: "contain" }}
                      image={female}
                      alt={"women"}
                    />
                  </Grid>
                )}
                {item.isHome && (
                  <Grid item>
                    <Box>
                      <HomeIcon
                        sx={{
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#00a69c",
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item>
                  <div>
                    <Checkbox
                    checked={favProduct[item.id]}
                    onChange={(e)=>{



                      if(e.target.checked){
                        addFav()

                      }
                      else{
                      remove()

                      }
                    }}
                      {...label}
                      icon={<FavoriteBorder  />}
                      checkedIcon={
                        <Favorite
                        
                          sx={{ color: "red", height: "30", width: "30" }}
                        
                        />
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: 1,
              backgroundColor: "transparent",
              justifyContent: "end",
            }}
          >
            <Grid container spacing={1}>
              {[
                {
                  icon: <AppRegistrationIcon sx={{ color: "#00a69c" }} />,
                  label: "Appointment",
                  onClick:async()=>{
                    if(Object.keys(userDetail).length==0 || !userDetail){
                      dispatch(setDetail(item))
                      openModel(true)
                    }
                    else{
                      dispatch(setDetail(item))
                      dispatch(setAppoinmentType("profile"))
                      navigate(routePath.APPOINMENT)
                    }
                    
                  }
                }
                ,
                {
                  icon: <ShareIcon sx={{ color: "#00a69c" }} />,
                  label: "Profile",
                },
                {
                  icon: <WhatsAppIcon sx={{ color: "#00a69c" }} />,
                  label: "Enquiry",
                  onClick: () => handleWhatsAppClick(item.mobileNumber),
                },
                {
                  icon: <LocalOfferIcon sx={{ color: "#00a69c" }} />,
                  label: "Offer",
                  onClick:()=>{
                    dispatch(setDetail(item))
                    navigate(routePath.GETOFFERBYBILLER)
                    
                  }
                },
                {
                  icon: <InfoIcon sx={{ color: "#00a69c" }} />,
                  label: "Details",
                  onClick:()=>{
                    dispatch(setDetail(item))
                    navigate(routePath.DETAIL)
                    
                  }
                },
                {
                  icon: <PlayArrowIcon sx={{ color: "#00a69c" }} />,
                  label: "Media",
                  onClick:()=>{
                    callback()
                  }
                },
                {
                  icon: <CallIcon sx={{ color: "#00a69c" }} />,
                  label: "Call",
                  onClick: () => handleContactClick(item.mobileNumber),
                },
                {
                  icon: <DirectionsIcon sx={{ color: "#00a69c" }} />,
                  label: "Directions",
                  onClick: () =>
                    handleRedirect(item),
                },
              ].map((action, i) => (
                <Grid item xs={3} key={i}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        border: "1px solid grey",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label={action.label}
                        onClick={action.onClick}
                        sx={{ backgroundColor: "transparent" }}
                      >
                        {action.icon}
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ color: "#e80089ed", fontSize: "13px" }}
                    >
                      {action.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      </>
    )
}

export default CardLanding