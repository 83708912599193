import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {  BrowserRouter, HashRouter } from "react-router-dom";
import appTheme from "./theme/appTheme";
import store from "./app/store/store";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import Test from "./features/test/Test";
import BasicTabs from "./common/detail";
import DetailPage from "./common/detail";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter >
    <ThemeProvider theme={appTheme}>
      <Provider store={store}>
        <App />
        {/* <Test/> */}
      </Provider>
    </ThemeProvider>
  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
