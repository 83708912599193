import React, { useRef, useState } from "react";
import Expense from "../../expense/expense";


const ExpenceReport = () => {
  
  const [SelectedEndDate, setSelectedEndDate] = useState(null);
  return (
    <>
      <Expense gridSize={{
    sm:6,
    xs:12
  }} />
    </>
  );
};

export default ExpenceReport;
