
import {    ADVANCEREPORT, GETASISTANTPAYROLLREPORT, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS } from "../../../utils/api";
import axios from "../../../utils/axios";
 
// export const getCategorySalesCustomers=async(param)=>{
//     const res=await axios.post(GETCATEGORYWISECOUNTERSALE,param);
//     return res;
// }
export const getMonthlyDetails=async(param)=>{
    const res=await axios.post(GETMONTLYGSTDETAILS,param);
    return res;
}

export const getOverallCounterDetail=async(param)=>{
    const res=await axios.post(ADVANCEREPORT,param)
    return res;
}