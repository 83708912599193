import { Box, Modal, TextField } from "@mui/material"
import UserCard from "./userCard";
import SearchIcon from "@mui/icons-material/Search";



const PurchaseSearchModal = ({ openSearch, setOpenSearch, searchItem, purchaseItem, setPurchaseItem, search, setSearch }) => {
    return (
        <Modal
            open={openSearch} sx={{ zIndex: 999999 }} // Yo

            onClose={() => {
                setOpenSearch(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {/* <h1>ssdfsdf</h1> */}
            <Box
                sx={{
                    background: "white",
                    width: "50%",
                    overflowY: "auto",
                    // height: "50vh",
                    position: "absolute",
                    left: "25%",
                    top: "10%",
                    padding: "20px"
                }}
            >
                <Box sx={{ height: "50vh", overflowY: "auto", pb: '10px' }}>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Search "
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <SearchIcon


                                        onClick={() => {
                                            setOpenSearch(true)
                                        }}
                                        sx={{
                                            color: (theme) => theme.palette.primary.main,
                                        }}
                                    />
                                ),
                            }}
                        />
                    </Box>
                    {searchItem?.map((item, index) => {
                        if (
                            !item?.description
                                .toLowerCase()
                                .includes(search.toLowerCase()) &&
                            // !item?.name?.toLowerCase().includes(search.toLowerCase()) &&
                            search.length > 0
                        ) {
                            return null;
                        }
                        return (
                            <UserCard
                                data={item}
                                callBack={() => {
                                    console.log("click ", item);
                                    setPurchaseItem({
                                        ...purchaseItem,
                                        rawMaterialId: item?.rawMaterialId,
                                        description: item?.description,
                                        // quantity: 0,
                                    });
                                    setOpenSearch(false)
                                    //   getUnpaid({
                                    //     customerMobile: item?.mobile,
                                    //   });
                                    //   setBillData({
                                    //     ...item,
                                    //     customerMobile: item?.mobile,
                                    //     customer: item?.name,
                                    //   });
                                }}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Modal>
    )
}

export default PurchaseSearchModal