import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";

import "react-datepicker/dist/react-datepicker.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getCounterSalesStatement } from "./outstandingApi";
import { useEffect } from "react";

import DisplayOutStandingReport from "./displayOutStandingReport";
import OutStandingReportCart from "./outStandingReportCart";

import { downloadXLSXFile } from "../../utils/commonUtils";
import { api } from "../../utils/api";
import { getCounterSales } from "./outStandingReportCustomApiHandler";
import { displayOutstading } from "./outstandingReportFunction";
import PaymentReceiptSearchModal from "../paymentReceipt/paymentReceiptSearchModal";
import { getUnpaidUser } from "../paymentReceipt/paymentReceiptApi";
import PaymentReceipt from "../paymentReceipt/paymentReceipt";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
const OutstandingReport = () => {
   
  const [modalData, setModalData] = useState();
  const [outStandingBills, setOutStandingBills] = useState({
    records: [],
    totalOustandingAmount: "",
    totalRecords: "",
  });
  const [value, setValue] = React.useState("outstanding");

  const tabfn = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  //-----
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)
  const [upPaidBillData, setUnPaidBillData] = useState();
  const [unPaidBill, setUnPaidBill] = useState([]);
  const data = {
    customerMobile: "",
    amount: 0,
    name: ""
  };
  const [billData, setBillData] = useState(data);
  const [billFilter, setBillFilter] = useState(null);


  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };
  //----
  const filters = [
    { name: "Amount Greater than ", value: "amountgreatethan" },
    { name: "Amount Less than ", value: "amountlessthan" },
    { name: "Amount elapsed than ", value: "dayselapsed" },
    { name: "Partially Paid ", value: "partlypaid" },
  ];

  ///---pagination functions
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 24,
  });
  const [payloadGetCounterSales, setPayloadGetCounterSales] = useState({
    page: 1,
    pageSize: pagination.pageSize,
    sortBy: "",
    order: 0,
    buyerGroupId: 0,
    filter: "",
    search: "",
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const handleChange = (event, value) => {
    getCounterSales(
      {
        ...payloadGetCounterSales,
        page: value,
        pageSize: pagination.pageSize,
      },
      outStandingBills,
      getCounterSalesStatement,
      setNumberOfRecords,
      setOutStandingBills
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };

  const [search, setSearch] = useState("");

  // const [value, setValue] = useState("");
  useEffect(() => {
    getCounterSales(
      { ...payloadGetCounterSales },
      outStandingBills,
      getCounterSalesStatement,
      setNumberOfRecords,
      setOutStandingBills
    );
  }, []);

  return (
    <>
       <DynamicBreadcrumbs />
     <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
     <Grid
        container
        sx={{
          p: "20px",
          overflowY: "auto",
          // height: "calc(100vh - 70px)",
          maxHeight: { sm: "calc(100vh - 70px)", xs: "calc(100vh - 130px)" },
        }}
        spacing={1}
      >

        <Grid xs={12}>

          <Tabs
            value={value}
            onChange={tabfn}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab
              value="outstanding"
              label="Outstanding Report"
              onClick={() => {
                // setPaidBills("");
                // paginationApiCall(date, pageNo, "null");
              }}
              sx={{ fontWeight: value === 'outstanding' ? '700' : '', width: '50%' }}
            />

            <Tab
              value="payment"
              label="Payment Report"
              onClick={() => {
                // paginationApiCall(date, pageNo, "null", "paid");
              }}
              sx={{ fontWeight: value === 'payment' ? '700' : '', width: '50%' }}
            />
          </Tabs>
        </Grid>
        {value == "outstanding" && (
          <React.Fragment>
         
     
            <Grid item xs={12} sm={4}>
              {/* <TextField
           fullWidth
           id="name"
           readonly
           label="Search Name/Mobile Number"
           value={search}
           size="small"
           onClick={() => {
             setSearchOutStandingsModal(true)
           }}
           onChange={(e) => {
             // setSearch(e.target.value);
           }}
           InputProps={{
             style: {
               margin: "0px",
               // height: "45px"
             },
             endAdornment: (
               <SearchIcon
                 onClick={() => {
                   setSearchOutStandingsModal(true)
                 }}
                 sx={{
                   color: (theme) => theme.palette.primary.main,
                   cursor: "pointer",
                 }}
               />
             ),
           }}
         /> */}
              <Box
                // disabled
                // size="small"
                readonly
                fullWidth
                onClick={() => {
                  setSearchOutStandingsModal(true)

                }}

                // id="name"
                // label="Search by Name/Mobile Number"
                // value={search}
                sx={{
                  display: "flex",
                  height: "23px",
                  justifyContent: "space-between",
                  // width: "70%",
                  border: "1px solid #c9c9c9",
                  p: 1,
                  borderRadius: "4px ",
                  color: "grey",
                  '&:hover': {
                    cursor: 'pointer !important',
                  },
                }}
                onChange={(e) => {

                  // setSearch(e.target.value);
                }}
              // InputProps={{
              //   style: {
              //     // background:"yellow"
              //     cursor: 'pointer !important',
              //     '&:hover': {
              //       cursor: 'pointer !important', // Set the cursor to pointer on hover
              //       // Add other hover styles here if needed
              //     },
              //     height: "40px"
              //   },

              // }}
              >
                <Typography variant="p">
                  {search !== "" ? search : "Search by Name/Mobile Number"}
                </Typography>
                <SearchIcon
                  onClick={() => {
                    // setSearchModal(true)
                    setSearchOutStandingsModal(true)

                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                // padding: { sm: "0px 16px" },
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                mt: { xs: "10px", sm: "0px" },
              }}
            >
              <FormControl fullWidth sx={{ position: "relative" }}
              >
                <InputLabel id="demo-simple-select-label"
                  sx={{ position: "absolute", top: "-6px" }}

                >Filter </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  fullWidth
                  value={payloadGetCounterSales.filter}
                  label="Filter By"
                  sx={{

                    // height: "40px !important",

                  }}
                  InputProps={{
                    style: {
                      height: "15px !important"
                    },
                  }}
                  onChange={(e) => {
                    setSearch("")
                    getCounterSales(
                      {
                        ...payloadGetCounterSales,
                        filter: e.target.value,
                      },
                      outStandingBills,
                      getCounterSalesStatement,
                      setNumberOfRecords,
                      setOutStandingBills
                    );
                    setPayloadGetCounterSales(
                      {
                        ...payloadGetCounterSales,
                        filter: e.target.value,
                      },
                      outStandingBills,
                      getCounterSalesStatement,
                      setNumberOfRecords,
                      setOutStandingBills
                    );
                  }}
                >
                  {filters.map((item, index) => {
                    return (
                      <MenuItem value={item.value} key={"option" + index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={4}  >
              <Grid item xs={12}
                sx={{
                  // padding: { sm: "0px 16px" },
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  mt: { xs: "10px", sm: "0px" },
                }}>
                <TextField
                  fullWidth
                  id="Value"
                  label="Value"
                  size="small"

                  value={payloadGetCounterSales.search}
                  onChange={(e) => {
                    setPayloadGetCounterSales(
                      {
                        ...payloadGetCounterSales,
                        search: e.target.value,
                      },
                      outStandingBills,
                      getCounterSalesStatement,
                      setNumberOfRecords,
                      setOutStandingBills
                    );
                  }}
                  InputProps={{

                    style: {
                      // height: "45px"
                    },

                    endAdornment: (
                      <SearchIcon
                        onClick={() => {
                          getCounterSales(
                            {
                              ...payloadGetCounterSales,
                              filter: payloadGetCounterSales?.filter
                            },
                            outStandingBills,
                            getCounterSalesStatement,
                            setNumberOfRecords,
                            setOutStandingBills
                          );
                        }}
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
                <FileDownloadIcon
                  sx={{ ml: "10px", mr: "0px" }}
                  onClick={() => {
                    downloadXLSXFile(
                      api + "Reports/ReportOutstandingCouterSalesStatementXlsx",
                      {
                        ...payloadGetCounterSales,
                        page: pagination.page,
                        pageSize: pagination.pageSize,
                      }
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              container
              sx={{
                padding: "8px 16px",
                fontWeight: "500",
              }}
            >
              <Grid
                item
                sm={6}
                sx={{
                  padding: "8px 0px",
                }}
              >
                <Grid
                  item
                  sm={6}
                  component="span"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Total Outstanding :
                </Grid>
                <Grid
                  item
                  sm={6}
                  component="span"
                  sx={{
                    marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  <CurrencyRupeeIcon color="secondary" fontSize="12px" />
                  {/* {outStandingBills?.totalOustandingAmount} */}
                  {(search?.trim()?.length > 0 ? displayOutstading(outStandingBills, search) : Number(outStandingBills?.totalOustandingAmount)?.toFixed(2))}
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  padding: "8px 0px",
                  fontWeight: "500",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: (theme) => theme.palette.primary.main,

                    fontWeight: "500",
                  }}
                >
                  Total Bills:{" "}
                </Box>
                <Box
                  component="span"
                  sx={{
                    marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  {outStandingBills?.totalRecords}
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider
                sx={{
                  // width: "100%",
                  background: (theme) => theme.palette.primary.main,
                }}
              />
            </Grid>
            <Divider
              sx={{

                // width: "100%",
                background: (theme) => theme.palette.primary.main,
              }}
            />

            <Grid container
              sx={{
                marginX: "20px",
              }}
            >
              {outStandingBills?.records?.map((item, i) => {
                console.log("Outstanding item ", item);
                // if (
                //   !item?.buyerName?.toLowerCase().includes(search.toLowerCase()) &&
                //   !item?.buyerMobile
                //     ?.toLowerCase()
                //     .includes(search.toLowerCase()) &&
                //   search.length > 0
                // ) {
                //   return null;
                // }
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={"payamentRecpt" + i}
                    sx={{ p: "5px", borderRight: "3px solid #dfdfdf" }}
                  >
                    <OutStandingReportCart
                      item={item}
                      setOpen={setOpen}
                      setModalData={setModalData}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <DisplayOutStandingReport
              modalData={modalData}
              open={open}
              setOpen={setOpen}
              getRecept={() => {
                // getPaymentRec({ ...getPaymentObj });
              }}
            />
            {/* -------- */}
            <Grid container sx={{ pt: "10px" }}>
              <Grid item xs={4}>

              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  // bgcolor: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
                <Pagination
                  count={
                    Number.isInteger(numberOfRecords / pagination.pageSize)
                      ? numberOfRecords / pagination.pageSize
                      : parseInt(numberOfRecords / pagination.pageSize) + 1
                  }
                  page={pagination.page}
                  onChange={handleChange}
                />
                {/* </Stack> */}
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </React.Fragment>
        )}
        {
          value != "outstanding"
          && (<PaymentReceipt />)
        }
        {/* //------------------------ */}

      </Grid>
     </Box>
      <PaymentReceiptSearchModal
        searchModal={searchOutStandingsModal}
        setSearchModal={setSearchOutStandingsModal}
        unPaidBill={unPaidBill}
        getUnpaid={getUnpaid}
        setBillData={setBillData}
        search={search}
        setSearch={setSearch}

        setUnPaidBill={setUnPaidBill}
        setUnPaidBillData={setUnPaidBillData}
        setPagination={setPagination}
        billData={billData}
        callSearchApi={(searchName) => {
          setBillFilter("")
          // payloadGetCounterSales.search
          setPayloadGetCounterSales({ ...payloadGetCounterSales, search: "" })
          getCounterSales(
            {
              page: 1,
              pageSize: 10000,
              sortBy: "",
              order: 0,
              buyerGroupId: 0,
              filter: "buyername",
              search: searchName,
            },
            outStandingBills,
            getCounterSalesStatement,
            setNumberOfRecords,
            setOutStandingBills
          );
          // getPaymentRec(
          //   {
          //     // ...getPaymentObj,
          //     // page: pagination.page,
          //     // pageSize: pagination.pageSize,

          //       "page": 1,
          //       "pageSize": 10000,
          //       "filter": "buyername",//"filter": null,
          //       "search": searchName,
          //       "date": getPaymentObj?.date

          //   },
          //   getPaymentReceipt,
          //   setNumberOfRecords,
          //   setTotals,
          //   totals,
          //   setReceipts
          // );
        }}
      //  userDetail={userDetail}
      />
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default OutstandingReport;
