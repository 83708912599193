import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import "react-datepicker/dist/react-datepicker.css";
import {
  displayDateFormat,
  downloadXLSXFile,
  getEndOfMonth,
  getFirstDateOfCurrentMonthPayloadFormate,
  getFirstDateOfMonth,
  getLastDateOfCurrentMonthPayloadFormat,
  payloadDateFormat,
  Toast,
} from "../../utils/commonUtils";
import { Divider, FormControlLabel, Switch, TextField } from "@mui/material";
import FileUploader from "./fileUploader";
import { UpdateOffersApi } from "./UpdateOffersApi";
export default function UpdateOfferModal({ open, handleClose, offerData  }) {
  console.log(offerData,'offerData ')
  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  const [displayEndDate, setDisplayEndDate] = useState(displayDateFormat());

  const [SelectedStartDate, setSelectedStartDate] = useState(
    getFirstDateOfMonth()
  );
  const [endDate, setEndDate] = useState(
    getLastDateOfCurrentMonthPayloadFormat()
  );
  const [startDateFormat, setstartDateFormat] = useState(
      getFirstDateOfCurrentMonthPayloadFormate()
    );
    console.log(startDateFormat,'endDateendDate')

  const datePickerRef2 = React.useRef();

  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };

  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setStartDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };

  const [startDate, setStartDate] = useState(displayDateFormat());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [status, setStatus] = useState("active");
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setStatus(isChecked ? "active" : "inactive");
  };

  useEffect(() => {
    
    setMessage(offerData?.offerInformation)
    setImagePreviewUrl(offerData?.imagePath);
    if(offerData?.status=="active"){
      setChecked(true)
      setStatus("active")
    }else{
      setChecked(false)
      setStatus("inactive")
    }
  }, [offerData])
  

  const handleFileChange = (file) => {
    setSelectedFile(file);
    if (file) {
      // Generate a preview of the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreviewUrl(offerData?.imageUrl); // Revert to old image if no new file is selected
    }
  };
  

  const handleSubmit= async()=>{
    const formData = new FormData();
  formData.append("Id", offerData?.id);
  formData.append("OfferInformation", message);
  formData.append("FromDate", startDate);
  formData.append("ToDate", displayEndDate);
  formData.append("Status", status);
  // formData.append("File", selectedFile);
  if (selectedFile) {
    // Only append the file if the user has selected a new one
    formData.append("File", selectedFile);
  }

    await UpdateOffersApi(
      formData
      )
        .then((res) => {
          if (res?.data?.succeeded) {
            Toast.fire({
              icon: "success",
              title: "Offer Updated succesfully.",
            });
        handleClose()
          } else {
            Toast.fire({
              icon: "error",
              title: "Something Went Wrong",
            });
          }
        })
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "52%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
            <Typography variant="h6" sx={{color:'primary.main'}}>Update Offers</Typography>
            <Divider/>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              mb: 2,
            }}
          >
            <DatePicker
              hidden={true}
              selected={SelectedStartDate}
              maxDate={new Date()}
              onChange={datePickerOnChange}
              className="custom-datepicker"
              ref={datePickerRef}
              onClickOutside={() => {
                datePickerRef.current.setOpen(false);
              }}
            />

            <DatePicker
              hidden={true}
              selected={SelectedEndDate}
              maxDate={new Date()}
              onChange={datePickerOnChangeEnd}
              className="custom-datepicker"
              ref={datePickerRef2}
              onClickOutside={() => {
                datePickerRef2.current.setOpen(false);
              }}
            />
            <TextField
              fullWidth
              readonly
              size="small"
              onClick={openDatePicker}
              id="date"
              sx={{
                mt: 2,
              }}
              value={startDate + " To " + displayEndDate}
              label={
                <Box
                  sx={{
                    flex: 4,
                    color: "grey",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                >
                  Date :
                </Box>
              }
              onChange={(e) => {}}
              InputProps={{
                endAdornment: (
                  <EventIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                ),
              }}
            />
          </Box>
          <TextField
               onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
            fullWidth
            label="message"
            value={message} 
            multiline
            rows={3}
            maxRows={5} // Grows up to 5 rows, and then it will scroll
            variant="outlined"
          />
          <Box
            display={"flex"}
            sx={{ mb: 2 }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
           <FileUploader onFileSelect={handleFileChange} />
            {imagePreviewUrl && (
              <Box sx={{ mt: 2 }}>
                <img src={imagePreviewUrl} alt="Current Offer" style={{ width: "100px" }} />
              </Box>
            )}

            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Offer Status"
              labelPlacement="start"
            />
          </Box>

          <Button onClick={handleSubmit} variant="contained" sx={{ float: "right" }}>
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
