import {
  Box,
  Fab,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  addAssociatedUser,
  getAssociatedUser,
  updateAssociatedUser,
} from "./associatedUsersApi";
import AddCategories from "./addAssociatedUser";
import CategoriesCard from "./associatedCard";
import Swal from "sweetalert2";
import AssociatedCard from "./associatedCard";
import AddAssociatedUser from "./addAssociatedUser";
import ScopeModal from "./scopeModal";
import {
  addUser,
  getUsers,
  updateUser,
} from "./associatedUsersCustomApiHandler";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export default function AssociatedUsers() {
  const [open2, setOpen2] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, total: 1 });
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const [open, setOpen] = useState(false);

  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  // const [itemData,set]

  const getUserObj = {
    page: 1,
    pageSize: 10,
    filterByColumn: "",
    filterValue: "",
    searchByColumn: "",
    searchValue: "",
    orderByColumn: "",
    orderBy: "",
  };

  useEffect(() => {
    getUsers(getUserObj, getAssociatedUser, setAssociatedUsers);
  }, []);

  useEffect(() => {
    if (open === false) {
      setSelectedUser();
    }
  }, []);

  return (
    <>
       <DynamicBreadcrumbs />
    <Box sx={{ overflowY: "auto", height: "calc(100vh - 70px)" ,  margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },}}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="end"
        sx={{      
          position: {
            xs: "fixed",
            sm: "relative",
          },

          background: "white",
          width: {
            xs: "40%",
            sm: "100%",
          },
          top: {
            xs: "61px",
            sm: "0px",
          },
          right: {
            xs: "10px",
            sm: "0px",
          },
        }}
      >
        <Fab
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            textTransform: "capitalize",
                 zIndex:'0'
          }}
          size="small"
          color="primary"
          variant="extended"
          aria-label="add"
        >
          Add User
          <AddIcon />
        </Fab>
      </Stack>
      <Grid
        container
        spacing={2}
        sx={{
          mt: {
            xs: 4,
            sm: 2,
          },
          p: "10px",
        }}
      >
        {associatedUsers?.map((item) => {
          return (
            <Grid item lg={3} sm={4} xs={12} md={3}>
              <AssociatedCard
                setOpen2={setOpen2}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setEditedItem={() => {
                  setSelectedUser(item);

                  setOpen(true);
                }}
                item={item}
              />
            </Grid>
          );
        })}
      </Grid>

      <AddAssociatedUser
        selectedUser={selectedUser}
        open={open}
        setSelectedUser={setSelectedUser}
        setOpen={setOpen}
        addUser={(param) =>
          addUser(
            param,
            addAssociatedUser,
            Swal,
            getUserObj,
            getAssociatedUser,
            setAssociatedUsers
          )
        }
        updateUser={(guid, param) =>
          updateUser(
            guid,
            param,
            updateAssociatedUser,
            Swal,
            getUserObj,
            getAssociatedUser,
            setAssociatedUsers
          )
        }
      />
      <ScopeModal
        open={open2}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        getUser={() =>
          getUsers(getUserObj, getAssociatedUser, setAssociatedUsers)
        }
        setOpen={setOpen2}
      />
    </Box>
    </>
  );
}
