import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControlLabel,
  Grid,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setDrawerShow,
  setMobileTableDrawer,
  setUpdateBillData,
} from "../app/store/reducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./table.css";
import { useNavigate } from "react-router";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { routePath } from "../constants/routePath";
import { displayDateFormat, payloadDateFormat } from "../utils/commonUtils";
import DateFormatter from "./timeFormatter";
const TableWithBox = ({ data, billCount, billApiCall, paginationApiCall }) => {
  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("all");
  const [paidBills, setPaidBills] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [startDate, setStartDate] = useState(new Date());

  const [date, setDate] = useState(
    (() => {
      function addLeadingZero(value) {
        return value < 10 ? "0" + value : value;
      }
      var currentDate = new Date();

      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      var day = currentDate.getDate();
      var formattedDate =
        year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

      return formattedDate;
    })()
  );

  const handleChange = (event, newValue) => {
    setPageNo(1);
    console.log("date== ",date)

    if (newValue === "all") {
      paginationApiCall(date, 1, "null");
    } else if (newValue == "paid") {
      paginationApiCall(date, 1, paidBills, "paid");
    } else {
      paginationApiCall(date, 1, newValue);
    }
    setValue(newValue);
  };

  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const navigate = useNavigate();
  const mobileTableDrawer = useSelector(
    (state) => state.reducer.mobileTableDrawer
  );

  const AmountColorSet = (item) => {
    if (
      item?.paymentMode === "Credit" &&
      item?.billStatus !== "Unpaid"
      // item?.billDate?.split("T")[0] == payloadDateFormat()
    ) {
      return (theme) => theme.palette.secondary.main;
    }
    if (
      item?.paymentMode === "Credit" &&
      item?.billStatus === "Unpaid"
      // item?.billDate?.split("T")[0] == payloadDateFormat()
    ) {
      return "red";
    }
    if (item?.paymentMode === "Credit") {
      return "red";
    } else {
      return "green";
    }
  };
useEffect(()=>{
if(drawerShow){
  const dateStr = startDate.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const [month, day, year] = dateStr.split("/");

  const formattedDate = `${year}-${day.padStart(
    2,
    "0"
  )}-${month.padStart(2, "0")}`;

  billApiCall(formattedDate)
}
},[drawerShow])
  return (
    <>
      <Box
        component={Paper}
        sx={{
          width: "100%",
          display: drawerShow || mobileTableDrawer ? "block" : "none",
          m: "0px",
          height: "calc(100vh - 64px)",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "8px 16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <CloseIcon
              sx={{ fontSize: "40px", float: "right" }}
              onClick={() => {
                dispatch(setDrawerShow(false));
                dispatch(setMobileTableDrawer(false));
              }}
            />
            <Box
              component={"h3"}
              sx={{ flex: 4, color: (theme) => theme.palette.primary.main }}
            >
              Bills
            </Box>
          </Grid>

         

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab
                    value="all"
                    label="All bills"
                    onClick={() => {
                      setPaidBills("");
                      paginationApiCall(date, pageNo, "null");
                    }}
                    sx={{ fontWeight: value === 'all' ? '700' : '' }}
                  />
                  <Tab
                    value="credit"
                    label="Credit Bills"
                    onClick={(e) => {
                      setPaidBills("");

                      paginationApiCall(date, pageNo, "credit", "null");
                    }}
                    sx={{ fontWeight: value === 'credit' ? '700' : '' }}
                  />
                  <Tab
                    value="paid"
                    label="Paid Bills"
                    onClick={() => {
                      paginationApiCall(date, pageNo, "null", "paid");
                    }}
                    sx={{ fontWeight: value === 'paid' ? '700' : '' }}
                  />
                </Tabs>
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "8px 16px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              mt:3
            }}
          >
            <DatePicker
              hidden={true}
              selected={startDate}
              maxDate={new Date()}
              onChange={(date) => {
                const dateStr = date.toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                });
                const [month, day, year] = dateStr.split("/");

                const formattedDate = `${year}-${day.padStart(
                  2,
                  "0"
                )}-${month.padStart(2, "0")}`;

                setDate(formattedDate);
                billApiCall(formattedDate);
                setStartDate(date);
                setPageNo(1);
                setValue("all");
                setPaidBills("cash");
              }}
              className="custom-datepicker"
              ref={datePickerRef}
              onClickOutside={() => datePickerRef.current.setOpen(false)}
            />
            <Box
              sx={{
                border:'1px solid #dfdfdf',
                p:1,
                borderRadius:'5px',
                display:'flex',
                alignItems:'center',
                gap:1,
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
              onClick={openDatePicker}
            >
              Date : {date}
              <CalendarMonthIcon/>
            </Box>
          </Grid>
          {value === "paid" && (
            <Grid item xs={12}>
              <RadioGroup
                row
                sx={{
                  justifyContent: "center",
                  fontWeight: "500",
                  width: "100%",
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={paidBills}
                onChange={(e) => {
                  paginationApiCall(date, pageNo, e.target.value, "paid");
                  // paginationApiCall(date, pageNo, "paid", e.target.value);
                  setPaidBills(e.target.value);
                  // setBill({

                  //   ...bill,
                  //   paymentMode: e.target.value,
                  // });
                }}
              >
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label="Cash Payment"
                />
                <FormControlLabel
                  value="digital"
                  control={<Radio />}
                  label="Online Payment"
                />
              </RadioGroup>
            </Grid>
          )}

          <Grid
            item
            xs={6}
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "8px 16px",
              fontWeight: "500",
            }}
          >
            <Box>
              <Box
                component="span"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Total Sale:
              </Box>
              <Box
                component="span"
                sx={{
                  fontWeight: "500",
                  // display: "flex",
                  // justifyContent: "right",
                  // alignItems: "center",
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                <CurrencyRupeeIcon
                  sx={{ height: "17px", marginBottom: "-2px" }}
                />
                {billCount?.totalSales}{" "}
              </Box>
            </Box>

            {value !== "paid" && value !== "credit" && (
              <Box>
                <Box
                  component="span"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Outstanding:
                  {/* OutStand:{" "} */}
                </Box>
                <Box
                  component="span"
                  sx={{
                    marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{ height: "17px", marginBottom: "-2px" }}
                  />
                  {billCount?.totalOutstanding}
                </Box>
              </Box>
            )}

            {/* </Box> */}
            {/* </Box> */}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "8px 16px",
              fontWeight: "500",
            }}
          >
            <Box sx={{ flex: 4 }}>
              <Box
                component={"span"}
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Total Bills:{" "}
              </Box>
              <Box
                component={"span"}
                sx={{
                  marginLeft: "8px",
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                {billCount?.totalBills}{" "}
              </Box>
            </Box>
            {value !== "paid" && value !== "credit" && (
              <Box
                sx={{
                  flex: 4,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Box component="span">Unpaid Bills: </Box>
                <Box
                  component="span"
                  sx={{
                    marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  {billCount?.unpaidBills}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "8px 16px",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flex: 1 }}>Bill ID</Box>
            <Box sx={{ flex: 2 }}>Table Name</Box>
            <Box sx={{ flex: 2 }}>Time</Box>
            <Box sx={{ flex: 2 }}>Amount </Box>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: "30vh",
            }}
          >
            {data.map((item) => {
              // if (item.paymentMode !== value && value !== "all") {
              //   return null;
              // }
              return (
                <Box
                  key={item.id}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    display: "flex",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setUpdateBillData(item));
                    navigate(routePath.UPDATEBILLS);
                    dispatch(setDrawerShow(false));
                    // callback(item);
                  }}
                >
                  <Box sx={{ flex: 1, fontWeight: "500" }}>
                    {item.billNumber}
                  </Box>
                  <Box
                    sx={{
                      flex: 2,
                      fontWeight: "500",
                    }}
                  >
                    {item.customer}
                  </Box>
                  <Box
                    sx={{
                      flex: 2,
                      fontWeight: "500",
                    }}
                  >
<DateFormatter dateString={item.billDate} />
                  </Box>
                  <Box
                    sx={{
                      flex: 2,
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      // 2023-07-31
                      // color: item?.paymentMode === "Credit" ? "red" : "green",
                      // color: (theme) => theme.palette.secondary.main,
                      color: AmountColorSet(item),
                    }}
                  >
                    <CurrencyRupeeIcon sx={{ height: "17px" }} />{" "}
                    {item.billAmount.toFixed(2)}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={
                Number.isInteger(billCount?.totalBills / 10)
                  ? billCount?.totalBills / 10
                  : parseInt(billCount?.totalBills / 10) + 1
              }
              page={pageNo}
              onChange={(e, v) => {
                console.log("value ", value);
                if (value === "all") {
                  paginationApiCall(date, v, "null");
                } else if (value == "paid") {
                  paginationApiCall(date, pageNo, paidBills, "paid");
                } else {
                  paginationApiCall(date, v, value);
                }
                setPageNo(v);
              }}
              shape="rounded"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableWithBox;
