import { Box, Fab, Grid, InputAdornment, Tab, Tabs, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search";
import { getAllCustomerDetail } from "../../common/searchModal/api";
import UserCard from "../../common/searchModal/userCard";
import { searchedElementDisplay } from "../../common/searchModal/function";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";

import { Form } from "./form";

export const CustomeDetail = ({gridSize}) => {
  const [collection, setCollection] = useState([])
  const [search, setSearch] = useState("");
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [open, setOpen] = useState(false)
  const [selectedData, setSeletecData] = useState(null)
  const [value, setValue] = useState("all");
  
  const [formDataModal,setFormDataModal]=useState(null)
  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
  const [searchCurrentModal, setSearchCurrentModal] = useState("")

  useEffect(() => {
    getAllCustomerDetail(
      {

        page: 1,
        pageSize: 1000,
        filter: "",
        search: "",
        filter2: "",
        search2: ""
      }, (data) => {
        setCollection(data)
        console.log("data detail ", data)
      }
    )
  }, [])
  return (
    <Grid
      container

      spacing={1}
      sx={{
        padding: '20px'
      }}
    >
      <Grid item xs={12} sx={{
        textAlign:'right'
      }}>
      {!gridSize && <Fab
            onClick={() => {

              // setOpen(true);

              setOpen(true)

            }}
            sx={{
              textTransform: "capitalize",
              width: {
                xs: "100%",
                sm: "auto",
              },
              // p: "10px",
              whiteSpace: "nowrap",
            }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Add 
            <AddIcon />
          </Fab>
}
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField


          value={selectedData?.name}


          onChange={(e) => {
            setSeletecData({ ...selectedData, name: e.target.value })
          }}
          sx={{ width: "400px" }}
          size="small"
          id="outlined-basic-search"
          placeholder="Search Items"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {search ? (<CloseIcon onClick={() => { setSearch("") }} sx={{ cursor: 'pointer' }} />) : (<SearchIcon />)}
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ height: "55vh", overflowY: "auto" }}>
          {collection.map((item, index) => {
            if (searchedElementDisplay(item, selectedData?.name)) {
              return null;
            }
            return (
              <UserCard
                key={"employee" + index}
                data={item}
                callBack={() => {
                  setOpen(true)
                  setFormDataModal(item)
                  // callback(item)
                  // getUnpaid({
                  //   customerMobile: item?.mobile,
                  // });
                  // setBillData({
                  //   ...item,
                  //   customerMobile: item?.mobile,
                  //   customer: item?.name,
                  // // });
                  // setSearch(item?.name)
                  // setUSerObject(item)
                  // callSearchApi(item?.name)
                  // setSearchModal(false)
                }}
              />
            );
          })}
        </Box>
      </Grid>

      <Form open={open} setOpen={setOpen} formDataModal={formDataModal} setFormDataModal={setFormDataModal} callback={()=>{
         getAllCustomerDetail(
          {
    
            page: 1,
            pageSize: 1000,
            filter: "",
            search: "",
            filter2: "",
            search2: ""
          }, (data) => {
            setCollection(data)
            console.log("data detail ", data)
          }
        )
      }} />

    </Grid>

  )
} 