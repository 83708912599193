import React, { useRef, useState } from 'react';
import { Button } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

export default function ImageUploadButton() {
  // Ref to the hidden file input
  const fileInputRef = useRef(null);

  // State to store the uploaded image URL
  const [imageSrc, setImageSrc] = useState(null);

  // Function to trigger the click on the hidden file input
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Handle the image file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Convert the file to a data URL to display it
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); // Save the image data URL to state
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {/* Button with an image icon */}
      <Button
        variant="contained"
        startIcon={<ImageIcon />}
        onClick={handleButtonClick}
      >
        Upload Image
      </Button>

      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Display the uploaded image */}
      {imageSrc && (
        <div style={{ marginTop: '20px' }}>
          <img src={imageSrc} alt="Uploaded" style={{ maxWidth: '300px', height: 'auto' }} />
        </div>
      )}
    </div>
  );
}
