import { Box, Grid, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getAllCustomerDetail } from "../../common/searchModal/api"
import OutStandingReportCart from "../employeePayRoll/outStandingReportCart"
import CardCounterInformation from "./card"
import SearchIcon from "@mui/icons-material/Search";
import SearchModalCustomer from "../../common/searchModal/customer"
import { getData } from "./counterInformationApi"
import { CustomeDetail } from "../customerDetail/customerDetail"
import LastCustomer from "../../common/searchModal/lastCustomer"
import CustomerInfo from "./CustomerInfo"
import DynamicBreadcrumbs from "../../common/Breadcrumb"

export const CustomerInformation = () => {
  const [collection, setCollection] = useState([])
  const [search, setSearch] = useState("");
  const [searchOutStandingsModal, setSearchOutStandingsModal] = useState(false)

  const [openT, setOpenT] = useState(false)
  const [selectedData, setSeletecData] = useState(null)
  const [value, setValue] = useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  useEffect(() => {

  }, [])
  return (
<>
<DynamicBreadcrumbs />
<Box sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
<Grid
      container

      spacing={1}
      sx={{
        padding: '20px'
      }}
    >
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab
            value="all"
            label="Customer history"
            onClick={() => {
              // setPaidBills("");
              // paginationApiCall(date, pageNo, "null");
            }}
            sx={{ fontWeight: value === 'all' ? '700' : '', width: '50%' }}
          />

          <Tab
            value="purchase"
            label="Customer Details"
            onClick={() => {
              // paginationApiCall(date, pageNo, "null", "paid");
            }}
            sx={{ fontWeight: value === 'paid' ? '700' : '', width: '50%' }}
          />
        </Tabs>
      </Grid>
      {
        value === "all" && (
         <CustomerInfo/>
        )
      }
      {value!=="all" && (
        <CustomeDetail />
      )}
    </Grid>
</Box>
</>

  )
}