import React, { useState } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Card,
} from "@mui/material";
import LogoImage from "../../assets/img/logo.svg";
import { setMpinApi } from "./setMpinApi";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { getSession } from "../../utils/commonUtils";
import { encrypt } from "../../utils/encryption";

function SetMpin() {
    
  const [mPin, setMPin] = useState(new Array(4).fill(""));
  const [confirmMPin, setConfirmMPin] = useState(new Array(4).fill(""));



  const navigate = useNavigate()
  // Handle the change in M-Pin or Confirm M-Pin fields
  const handlePinChange = (value, index, pinType) => {
    const newPin = pinType === 'mPin' ? [...mPin] : [...confirmMPin];
    if (/^[0-9]?$/.test(value)) {
      newPin[index] = value;
      pinType === 'mPin' ? setMPin(newPin) : setConfirmMPin(newPin);
    }
  };

  // Handle key up event to move focus between fields
  const handlePinKeyUp = (e, index, pinType) => {
    const nextInput = document.querySelector(`input[name=${pinType}-${index + 1}]`);
    const prevInput = document.querySelector(`input[name=${pinType}-${index - 1}]`);

    if (e.key === "Backspace" && prevInput) {
      prevInput.focus();
    } else if (nextInput && e.target.value !== "") {
      nextInput.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mPin.join("") !== confirmMPin.join("")) {
      alert("M-Pin and Confirm M-Pin do not match.");
      
    } else {
        getSession("eleGentUser",(data)=>{
            console.log("Data ddd",data)
            let obj=JSON.parse(data)
            
      const randomId = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    const pass = mPin.join("") + randomId;
    ;
            setMpinApi({guid:obj.guId,mPin:encrypt(pass,"9z8x7c1m2n3b5l4k"),isBiometricAuthentication:false},(data)=>{
                console.log("Data",data)

                // setSession("eleGentUser",data)
                navigate(routePath.LOGIN)
              })
        })
      
      alert(`M-Pin: ${mPin.join("")}`);
    }

  };

  return (
    <Container maxWidth="lg" sx={{ padding: { xs: "10px", sm: "20px" } }}>
      <Card
        sx={{
          padding: { xs: "10px", sm: "20px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ height: "100%", flex: 1 }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={LogoImage}
                alt="logo"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: { xs: "80%", sm: "100%" },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: "#3695B6" }}>
                Set M-Pin
              </Typography>

              {/* Enter M-Pin */}
              <Box sx={{ mb: 2, width: { xs: "100%", sm: "22.3rem" } }}>
                <Typography sx={{ color: "#3695B6" }}>Enter M-Pin:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 3,
                    mb: 2,
                  }}
                >
                  {mPin.map((value, index) => (
                    <TextField
                      key={index}
                      name={`mPin-${index}`}
                      type="text"
                      value={value}
                      onChange={(e) => handlePinChange(e.target.value, index, 'mPin')}
                      onKeyUp={(e) => handlePinKeyUp(e, index, 'mPin')}
                      inputProps={{
                        maxLength: 1,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {
                          textAlign: "center",
                          fontSize: "24px",
                          width: "15px",
                          height: "20px",
                        },
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  ))}
                </Box>
              </Box>

              {/* Confirm M-Pin */}
              <Box sx={{ mb: 2, width: { xs: "100%", sm: "22.3rem" } }}>
                <Typography sx={{ color: "#3695B6" }}>Confirm M-Pin:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 3,
                    mb: 2,
                  }}
                >
                  {confirmMPin.map((value, index) => (
                    <TextField
                      key={index}
                      name={`confirmMpin-${index}`}
                      type="text"
                      value={value}
                      onChange={(e) => handlePinChange(e.target.value, index, 'confirmMpin')}
                      onKeyUp={(e) => handlePinKeyUp(e, index, 'confirmMpin')}
                      inputProps={{
                        maxLength: 1,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {
                          textAlign: "center",
                          fontSize: "24px",
                          width: "15px",
                          height: "20px",
                        },
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  ))}
                </Box>
              </Box>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#3695B6",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "600",
                  marginTop: "10px",
                  width: { xs: "100%", sm: "22.3rem" },
                }}
              >
                Continue
              </Button>
            
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default SetMpin;