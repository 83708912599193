import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function AppoinmentDashboardCard({
  valuet,
  item,
  onClick,
  callback,
  value
}) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      sx={{ p: "20px" }}
      onClick={() => { }}
    >
      <Box
        sx={{
          p: "10px",

          fontWeight: "bold",
          // height: "100px",
          borderRadius: "10px",

          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{}}></Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px", 

                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              Customer Name
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px", // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={"14px"}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                m: "0px  2px",
                // fontWeight: "600",
              }}
            >
              {item?.customerName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px", 


                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              Mobile Number
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",


                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={"14px"}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                m: "0px 2px",
                // fontWeight: "600",
              }}
            >
              {item?.mobileNumber}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px", 
                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              Service
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px", 
                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            
            <Typography
              fontSize={"14px"}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                m: "0px 2px",
                // fontWeight: "600",
              }}
            >
              {item?.service}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
// color: (theme) => theme.palette.primary.main,
fontWeight: "600",
              }}
            >
              Date and time
            </Typography>
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 10px",
                // color: (theme) => theme.palette.primary.main,
                fontWeight: "600",
              }}
            >
              :
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            {/* <CurrencyRupeeIcon fontSize="10px" /> */}
            <Typography
              fontSize={"14px"}
              sx={{
                m: "0px 1px",
                // fontWeight: "600",
              }}
            >
              {/* {item?.date} */}
              {convertDateToCustomFormat(item?.date?.split("T")[0])}
            </Typography>
          </Grid>



          {value === "Unassigned" && (<Button variant="contained" fullWidth onClick={() => onClick(item)}>
            {" "}
            Assigned{" "}
          </Button>)}
          {valuet == "Assigned" && value !== "Unassigned" && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={() => onClick(item)}>
                {" "}
                Update{" "}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  callback({ api: "complete" });
                }}
              >
                {" "}
                Completed{" "}
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  callback({ api: "cancel" });
                }}
              >
                {" "}
                Cancelled{" "}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
