import { Box, Container, Typography } from "@mui/material"
import Logo from "../../assets/img/logo.svg";
import DynamicBreadcrumbs from "../../common/Breadcrumb";
export const Advertice=()=>{
    return (
     <Box>
        <DynamicBreadcrumbs/>
        <Container>
      <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', mt:5}}>
      {/* <img
          src={Logo}
          alt="logo"
          style={{ width: '320px', height: '320px'}}
        /> */}
            <Box component={"img"} src={Logo} width={"320px"} sx={{ boxShadow:'-10px -9px 6px #00000078',transition:'0.5s ease-in-out',borderRadius:'50%', "&:hover":{ boxShadow:'10px 8px 6px #00000078' }}}/>
        <Typography sx={{fontSize:'50px', fontWeight:'800', color:'secondary.main', textShadow:'5px 3px 6px #00000078',transition:'0.5s ease-in-out', "&:hover":{ textShadow:'10px 8px 6px #00000078' }}}>ELEGANT PRIME</Typography>
      </Box>
      </Container>
     </Box>
    )
}