import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import dayjs from "dayjs";
import { addItem } from "./itemApi";

import CloseIcon from "@mui/icons-material/Close";
import { swalAlertPopProps } from "../../utils/commonUtils";
import Swal from "sweetalert2";
import ControlledComponent from "./DatePicker";

const data = {
  cgst: "",
  sgst: "",
  igst: "",
  hsnCode: "",
  barcode: "",
  productorService: false,
  displayToCustomer: false,
  rawMaterialValue: "",
  requiredTime: "",
  itemCode: "",
  itemDescription: "",
  unit: "",
  price: "",
  fromDate: dayjs(),
  status: "",
  isCounterSalesItem: true,
  itemCategoryId: 0,
  myBusinessId: 0,
  serviceFrequency: null,
  daysOfService: null,
  gst: 0.0,
  businessLineId: 0,
  franchiseItemMasterId: 0,
  maintainInventory: false,
  itemServiceTypeAssn: null,
};

export default function AddItem({ open, item, onSave, setOpen,setEditedItem }) {
  const onClose = () => {
    setItemData(data);
    setOpen(false);
  };
  const [rawMaterial, setMaterial] = useState(false);
  const [itemData, setItemData] = useState(data);

  useEffect(() => {
    if (item) {
      if(item.rawMaterialValue){
        setMaterial(true)
      }
  console.log("formdata = ",item)

      setItemData({ ...item,fromDate:dayjs(item?.startDate) });
    }
  }, [item]);

  const onChange = (key, value) => {
    setItemData({ ...itemData, [key]: value });
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };



useEffect(()=>{
  console.log("formdata = ",item)

 if(!open){
  setMaterial(false)
  setItemData(data)
 }
 else{
  setEditedItem(null)

 }
             
},[open])

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
      <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: "3px",
        }}
      >
        Item Information{" "}
        <CloseIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: "3px" }}>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.itemCode}
            onChange={(e) => {
              onChange("itemCode", e.target.value);
            }}
            label={
              <span>
                item Code<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.itemDescription}
            onChange={(e) => {
              onChange("itemDescription", e.target.value);
            }}
            label={
              <span>
                Item Description<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.price}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  onChange("price", e.target.value);
              }}
              label={
                <span>
                  Rate (₹)<span style={{ color: "red" }}>*</span>
                </span>
              }
            />
            {/* <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.unit}
              onChange={(e) => {
                onChange("unit", e.target.value);
              }}
              label={
                <span>
                  Unit<span style={{ color: "red" }}>*</span>
                </span>
              }
            /> */}
            <FormControl fullWidth size="small" variant="outlined">
              <InputLabel id="unit-select-label">
                <span>
                  Unit<span style={{ color: "red" }}>*</span>
                </span>
              </InputLabel>
              <Select
                labelId="unit-select-label"
                id="unit-select"
                value={itemData.unit}
                label={
                  <span>
                    Unit<span style={{ color: "red" }}>*</span>
                  </span>
                }
                onChange={(e) => {
                  onChange("unit", e.target.value);
                }}
              >
                <MenuItem value={"Nos"}>Nos</MenuItem> 
                <MenuItem value={"Per Head"}>Per Head</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {/* <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.gst ? itemData.gst : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("gst", e.target.value);
            }}
            label="GST(%)"
            sx={{ mt: 1 }}
          /> */}

          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.cgst ? itemData.cgst : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("cgst", e.target.value);
            }}
            label="CGST(%)"
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.sgst ? itemData.sgst : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("sgst", e.target.value);
            }}
            label="SGST(%)"
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.igst ? itemData.igst : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("igst", e.target.value);
            }}
            label="IGST(%)"
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.hsnCode ? itemData.hsnCode : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("hsnCode", e.target.value);
            }}
            label="HSN Code"
            sx={{ mt: 1 }}
          />

          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.barcode ? itemData.barcode : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("barcode", e.target.value);
            }}
            label="Barcode"
            sx={{ mt: 1 }}
          />
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={itemData.productorService}
            onChange={(e) => {
              setItemData({
                ...itemData,
                productorService: e.target.value == "true" ? true : false,
              });
              // setBill({
              //   ...bill,
              //   discountType: e.target.value,
              //   discount: 0,
              // });
            }}
          >
            <FormControlLabel
              value={true}
              selected={true}
              control={<Radio />}
              label="Service"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Product"
            />
          </RadioGroup>
          {itemData.productorService && (
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.requiredTime ? itemData.requiredTime : ""}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  onChange("requiredTime", e.target.value);
              }}
              label="Enter Value"
              sx={{ mt: 1 }}
            />
          )}
          <FormGroup>
            <FormControlLabel
              sx={{
                justifyContent: "space-between",
                m: 0,
                flexDirection: "row-reverse",
              }}
              control={
                <Switch
                  checked={rawMaterial}
                  onChange={(e) => {
                    setMaterial(!rawMaterial);
                  }}
                />
              }
              label="Raw material required for this service?"
            />
          </FormGroup>

          {rawMaterial && (
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.rawMaterialValue ? itemData.rawMaterialValue : ""}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  onChange("rawMaterialValue", e.target.value);
              }}
              label="Raw materials "
              sx={{ mt: 1 }}
            />
          )}
          <FormGroup>
            <FormControlLabel
              sx={{
                justifyContent: "space-between",
                m: 0,
                flexDirection: "row-reverse",
              }}
              control={
                <Switch
                  checked={itemData.displayToCustomer}
                  onChange={(e) => {
                    onChange(
                      "displayToCustomer",
                      e.target.checked ? true : false
                    );
                  }}
                />
              }
              label="  Display To Customer"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              sx={{
                justifyContent: "space-between",
                m: 0,
                flexDirection: "row-reverse",
              }}
              control={
                <Switch
                  checked={itemData.maintainInventory}
                  onChange={(e) => {
                    onChange("maintainInventory", e.target.checked);
                  }}
                />
              }
              label="  Main Inventory"
            />
          </FormGroup>
          {/* {item && (
            <FormGroup>
              <FormControlLabel
                sx={{
                  justifyContent: "space-between",
                  m: 0,
                  flexDirection: "row-reverse",
                }}
                control={
                  <Switch
                    checked={itemData.status !== "active"}
                    onChange={(e) => {
                      onChange(
                        "status",
                        e.target.checked ? "inactive" : "active"
                      );
                    }}
                  />
                }
                label="Discontinue"
              />
            </FormGroup>
          )} */}
          <ControlledComponent
            date={itemData.fromDate}
            // size="small"

            sx={{ height: "30px" }}
            onChange={(date) => {
              onChange("fromDate", date);
            }}
            isEdit={!!item}
          />
          {/* <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={itemData.maintainInventory}
                    onChange={(e) => {
                      onChange("maintainInventory", e.target.checked);
                    }}
                  />
                }
                label="Maintain Inventory"
                labelPlacement="right"
              />
            </FormGroup>
          </FormControl> */}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            itemData.itemDescription.trim().length === 0 ||
            itemData.price.toString().trim().length === 0 ||
            itemData.unit.trim().length === 0
          }
          variant="contained"
          onClick={() => {
            console.log("payload ", {
              fromDate: dayjs(itemData.fromDate).format("YYYY-MM-DD"),
              // gst: Number(itemData.gst) || 0.0,
              price: Number(itemData.price),
              status: itemData.status || "active",
              cgst:parseFloat(itemData.cgst)
            });
            addItem(
              {
                ...itemData,
                fromDate: dayjs(itemData.fromDate).format("YYYY-MM-DD"),
                // gst: Number(itemData.gst) || 0.0,
                price: Number(itemData.price),
                status: itemData.status || "active",
                cgst:parseFloat(itemData.cgst),
                sgst:parseFloat(itemData.sgst),
                



                igst:parseFloat(itemData.igst),
                rawMaterialValue:itemData.rawMaterialValue?parseFloat(itemData.rawMaterialValue):parseFloat(0),



              },
              !!item
            ).then((res) => {
              onSave && onSave();




            });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
