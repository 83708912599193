import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";

import { getCategorySalesCustomers } from "./reportsApi";
import ReportCard from "./reportCard";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import GstReports from "./gstReport/gstReport";
import DiscountinuedItem from "./discountedItems/discountedItems";
import TotalCounterSellReport from "./totalCounterSaleReport.jsx/totalCounterSellReport";
import ItemCounterSellReport from "./itemWiseSaleSummary.jsx/itemCounterSellReport";
import SearchModal from "./searchModal";
import { payloadDateFormat } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { setServiceViceSearch } from "../../app/store/reducer";
import RateChangeItemHistory from "./rateChangeHistory/rateChangeHistory";
import CashCounterSale from "./cashCounterSale/cashCounterSale";
import EmployeePaymentRole from "./employeePaymentReport/employeePaymentRole";
import EmployeeAdvanceReport from "./employeeAdvanceReport/employeeAdvanceReport";
import RawMaterialReport from "./RawMaterialReport/RawMaterialReport";
import ListOfItemSerivces from "./ListOfItemSerivces/ListOfItemSerivces";
import PurchaseReportData from "./purchaseReport/purchaseReport";
import ExpenceReport from "./expensereport/expensereport";
import { InventoryReport } from "./InntryReport/InntryReport";
import EmployeeDetailReport from "./employeeDetailReport/employeeDetailReport";
import EmploDeReport from "./employeeDetailReport/employeeDetailReport";
import EmpPayRollReport from "./employeePayroll/employeePayroll";
import { CustomerDetailReport } from "./customerDetailReport";
import CustomerInfo from "../counterInformation/CustomerInfo";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

const Reports = () => {
  const [filters, setFilters] = useState([
    {
      name: "Discountinued items/Services list ",
      value: "DiscountinueditemsServiceslist",
    },
    { name: "Gst Report", value: "GstReport" },

    {
      name: "Item catogery wise daily counter sales",
      value: "ItemCatogery",
    },
    { name: "Total counter sale report", value: "Totalcountersalereport" },
    {
      name: "Item Wise Counter Sale Report",
      value: "ItemWiseCounterSaleReport",
    },
    {
      name: " Raw Material Report ",
      value: "RawMaterialReport",
    },
    {
      name: "Counter sale of selected item",
      value: "CounterSaleOfSelectedItem",
    },

    {
      name: "List of items/ Services",
      value: "ListOfItemSerivces",
    },
    {
      name: "Purchase Report",
      value: "PurchaseReportData",
    },
    {
      name: "Expense report",
      value: "ExpenceReport",
    },
    {
      name: "Inventory report",
      value: "InventoryReport",
    },
  ]);

  const [filters_t, setFilters_t] = useState([
    {
      name: "Employee Payroll Report",
      value: "EmployeePaymentRole",
    },
    {
      name: "Employee Advance Payment Report",
      value: "EmployeeAdvanceReport",
    },
    { name: "Employee Details Report", value: "EmploDeReport" },
  ]);
  const [filters_th, setFilters_th] = useState([
    {
      name: "Customer History Report ",
      value: "CustomerHistoryReport",
    },
    { name: "Customer Information Report", value: "CustomerDetailReport" },
  ]);

  const [filterBy, setFilterBy] = useState("DiscountinueditemsServiceslist");
  const [open2, setOpen2] = useState(false);

  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [CategorySale, setCategorySale] = useState({
    totalSales: "",
    total: [],
  });
  const [billFilter, setBillFilter] = useState("cash");

  const [date, setDate] = useState(displayDateFormat);

  const [startDate, setStartDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const serviceViceSearch = useSelector(
    (state) => state.reducer.serviceViceSearch
  );
  const getData = async (param) => {
    await getCategorySalesCustomers(param)
      .then((res) => {
        console.log("Category Customer ", res);
        setCategorySale({
          ...CategorySale,
          total: res?.data?.data?.categoryWiseCounterSale,
          totalSales: res?.data?.data?.totalSales,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("formattedDate2 ", formattedDate2);
    console.log("date", date);
    getData({
      date: formattedDate,
      // page:1,
      // pageSize:4
    });
    setDate(formattedDate2);

    setStartDate(date);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("datE ", date);
    getData({
      date: payloadDateFormat(),
    });
  }, []);
  const primaryColor = () => {
    return (theme) => theme.palette.primary.main;
  };
  return (
    <>
      <DynamicBreadcrumbs/>
   <Box  sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
   <Grid
        container
        sx={{
          pb: "20px",
          overflowY: "auto",
          minHeight: {
            xs: "100vh",
            sm: "calc(100vh - 60px)",
          },        
        }}
      >
        <Grid item xs={5} sx={{ borderRight: "1px solid #24869F " }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={"14px"}
                      sx={{
                        // m: "0px 5px",
                        color: (theme) => theme.palette.primary.main,
                        fontWeight: "600",
                      }}
                    >
                      Ready to use search option
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontWeight: "bold",
                      // mt: 2,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-around",
                      mt: 1.4,
                      mb: 5.4,
                      border: "1px solid #24869F",
                      p: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {filters.map((item, index) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          key={"reportcat" + index}
                          onClick={() => {
                            setFilterBy(item.value);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              overflow: "Hidden",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "1",
                              "-webkit-box-orient": "vertical",
                              fontWeight: 700,
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          >
                            {" "}
                            {item.name}
                          </Typography>
                          <PlayArrowIcon
                            sx={{
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          />
                        </Box>
                      );
                    })}

                    {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterBy}
                    label="Filter"
                    onChange={(e) => {
                      setFilterBy(e.target.value);
                    }}
                    sx={{ height: "45px" }}
                  >
                    {filters.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={"option" + index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontWeight: "bold",
                      // mt: 2,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-around",
                      mt: 1.4,
                      mb: 5.4,
                      border: "1px solid #24869F",
                      p: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {filters_t.map((item, index) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          key={"reportcat" + index}
                          onClick={() => {
                            setFilterBy(item.value);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              overflow: "Hidden",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "1",
                              "-webkit-box-orient": "vertical",
                              fontWeight: 700,
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          >
                            {" "}
                            {item.name}
                          </Typography>
                          <PlayArrowIcon
                            sx={{
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          />
                        </Box>
                      );
                    })}

                    {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterBy}
                    label="Filter"
                    onChange={(e) => {
                      setFilterBy(e.target.value);
                    }}
                    sx={{ height: "45px" }}
                  >
                    {filters.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={"option" + index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontWeight: "bold",
                      // mt: 2,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-around",
                      mt: 1.4,
                      mb: 5.4,
                      border: "1px solid #24869F",
                      p: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {filters_th.map((item, index) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          key={"reportcat" + index}
                          onClick={() => {
                            setFilterBy(item.value);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              overflow: "Hidden",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "1",
                              "-webkit-box-orient": "vertical",
                              fontWeight: 700,
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          >
                            {" "}
                            {item.name}
                          </Typography>
                          <PlayArrowIcon
                            sx={{
                              color:
                                item.value == filterBy
                                  ? (theme) => theme.palette.secondary.main
                                  : "",
                            }}
                          />
                        </Box>
                      );
                    })}

                    {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterBy}
                    label="Filter"
                    onChange={(e) => {
                      setFilterBy(e.target.value);
                    }}
                    sx={{ height: "45px" }}
                  >
                    {filters.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={"option" + index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    p: "10px",
                    mt: {
                      xs: "10px",
                      md: "0px",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      fontSize={"14px"}
                      sx={{
                        // m: "0px 15px",
                        color: (theme) => theme.palette.primary.main,
                        fontWeight: "600",
                      }}
                    >
                      Option for item / Service wise search
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      // padding: "0px 16px",
                      // fontWeight: "bold",
                      alignItems: "center",
                      justifyContent: "space-around",
                      // fontWeight: "bold",
                      // mt: 2,
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "space-around",
                      mt: 1.4,
                      mb: 5.4,
                      border: "1px solid #24869F",
                      p: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <RadioGroup
                      row
                      sx={{
                        // fontWeight: "500",
                        width: "100%",
                      }}
                      value={serviceViceSearch}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => {
                        dispatch(setServiceViceSearch(e.target.value));
                      }}
                    >
                      <FormControlLabel
                        value="rate"
                        control={<Radio />}
                        label="Rate of change history of item service"
                      />
                      <FormControlLabel
                        value="cash"
                        control={<Radio />}
                        label="Cash counter Sale of selected items"
                      />
                    </RadioGroup>
                    <Box
                      onClick={() => {
                        setOpen2(true);
                      }}
                    >
                      <Box
                        fullWidth
                        onClick={() => {
                          setOpen2(true);
                        }}
                        sx={{
                          display: "flex",
                          height: "23px",
                          justifyContent: "space-between",
                          width: "70%",
                          border: "1px solid #c9c9c9",
                          p: 1,
                          borderRadius: "4px ",
                          color: "grey",
                          "&:hover": {
                            cursor: "pointer !important",
                          },
                        }}
                        onChange={(e) => {
                          // setSearch(e.target.value);
                        }}
                        // InputProps={{
                        //   style: {
                        //     // background:"yellow"
                        //     cursor: 'pointer !important',
                        //     '&:hover': {
                        //       cursor: 'pointer !important', // Set the cursor to pointer on hover
                        //       // Add other hover styles here if needed
                        //     },
                        //     height: "40px"
                        //   },

                        // }}
                      >
                        <Typography variant="p">
                          {search !== "" ? search : "Search"}
                        </Typography>
                        <SearchIcon
                          onClick={() => {
                            setOpen2(true);
                          }}
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          {filterBy === "ItemWiseCounterSaleReport" && (
            <ItemCounterSellReport />
          )}
          {filterBy === "Totalcountersalereport" && <TotalCounterSellReport />}
          {filterBy === "DiscountinueditemsServiceslist" && (
            <DiscountinuedItem />
          )}
          {filterBy === "GstReport" && <GstReports />}
          {filterBy === "RateChangeItemHistory" && <RateChangeItemHistory />}
          {filterBy === "CashCounterSale" && <CashCounterSale />}
          {filterBy === "EmployeePaymentRole" && <EmployeePaymentRole />}
          {filterBy === "EmployeeAdvanceReport" && <EmployeeAdvanceReport />}
          {filterBy === "RawMaterialReport" && <RawMaterialReport />}

          {filterBy === "ItemCatogery" && (
            <Grid container px="10px">
              <Grid
                item
                xs={12}
                sx={{
                  mt: 1,

                  textAlign: "center",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (datePickerRef.current) {
                      datePickerRef.current.setOpen(true);
                    }
                  }}
                >
                  {date?.split("-")[1] + "-" + date?.split("-")[2]}
                </Button>
                <DatePicker
                  hidden={true}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    datePickerOnChange(date);
                  }}
                  className="custom-datepicker"
                  ref={datePickerRef}
                  onClickOutside={() => datePickerRef.current.setOpen(false)}
                  showMonthYearPicker
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  padding: "8px 16px",
                  fontWeight: "500",
                }}
              >
                <Box
                  component="span"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Total Sale:
                </Box>
                <Box
                  component="span"
                  sx={{
                    marginLeft: "8px",
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
                  {CategorySale?.totalSales}
                </Box>
              </Grid>

              <Divider
                sx={{
                  width: "100%",
                  background: (theme) => theme.palette.primary.main,
                }}
              />

              <Grid container>
                <Grid item xs={12} lg={12} sx={{ p: "10px" }}>
                  <Grid container sx={{ p: "10px" }}>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          overflow: "Hidden",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          overflow: "Hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          textAlign: "center",
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        Items
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        fontSize: "15px",
                        overflow: "Hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "1",
                        "-webkit-box-orient": "vertical",
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                    >
                      Amount
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          overflow: "Hidden",
                          display: "-webkit-box",
                          textAlign: "center",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          fontWeight: 700,
                        }}
                      >
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ maxHeight: "60vh", overflow: "auto" }}>
                  {CategorySale.total.map((item, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        key={"payamentRecpt" + i}
                        sx={{
                          p: "0px 0px",
                          cursor: "pointer ",
                          marginBottom:
                            i == CategorySale.total.length - 1 ? "20px" : "",
                        }}
                      >
                        <ReportCard item={item} index={i} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
          {filterBy === "PurchaseReportData" && <PurchaseReportData />}
          {filterBy === "ListOfItemSerivces" && <ListOfItemSerivces />}
          {filterBy === "ExpenceReport" && <ExpenceReport />}
          {filterBy === "InventoryReport" && <InventoryReport />}
          {filterBy === "EmploDeReport" && <EmploDeReport />}

          {filterBy === "CustomerDetailReport" && <CustomerDetailReport />}

          {filterBy === "CustomerHistoryReport" && (
            <Box
              sx={{
                padding: "0px 100px",
              }}
            >
              <CustomerInfo
                gridSize={{
                  sm: 6,
                }}
              />
            </Box>
          )}

          {filterBy === "EmployeePayroll" && <EmpPayRollReport />}
        </Grid>
        <SearchModal
          open={open2}
          setOpen={setOpen2}
          setFilterBy={setFilterBy}
        />
      </Grid>
   </Box>
    </>
  );
};

export default Reports;
