
  import Swal from "sweetalert2";
import { GETUSERPROFILE, UPDATEUSERPROFILE, USERMASTER, USERMPIN } from "../../utils/api";
import axios from "../../utils/axios"
import { validationUpdateProfile } from "./myProfileValidation";


export const getProfile=async(callback)=>{ 
  await axios
  .get (GETUSERPROFILE)
  .then((res) => {
    console.log("res search ", res);
  //   callback(res?.data?.data) 
    if(res?.data?.succeeded){
      callback(res?.data?.data) 
    }
    else{
      alert(res?.data?.message)
    }
  })
  .catch((err) => {
    console.log(err);
  });

}

export const updateProfile=async(payload,callback)=>{ 
  if (validationUpdateProfile(payload)) {
    await axios
    .post (UPDATEUSERPROFILE,payload)
    .then((res) => {
      console.log("res search ", res);
    //   callback(res?.data?.data) 
      if(res?.data?.succeeded){
        callback(res?.data?.data) 
        Swal.fire({
          text: "Update Successfully",
          icon: "sucess",
        });
      }
      else{
        alert(res?.data?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
}
  