import { ADDCUSTOMERAPPOINMENT, ADDCUSTOMERAPPOINMENTANAMONUS, ADDCUSTOMERAPPOINMENTI, UPDATEBILL, UPDATECUSTAPPOINMENT } from "../../utils/api";
import axios from "../../utils/axios"; 

import { Toast } from "../../utils/commonUtils";
export const addCustomerAppointment = async(payload,callback)=>{ 

  await axios
  .post (ADDCUSTOMERAPPOINMENTI,payload)
  .then((res) => {
    console.log("res search ", res);
     
     
    if(res?.data?.succeeded){
        if(res?.data?.succeeded){
            callback(res?.data) 
            console.log("res search ", res?.data);
            Toast.fire({
              icon: "success",
              title: "Appointment booked succesfully.",
            });
          }
          else{
            Toast.fire({
              icon: "error",
              title: res?.data?.message
            });    
          }
      callback(res?.data) 
      
    }else{
      Toast.fire({
        icon: "error",
        title: res?.data?.message
      });  
    }
    
  })
  .catch((err) => {
    console.log(err);
  });
}
export const UpdatedCustomerAppointment = async(payload,callback)=>{ 

  await axios
  .post (UPDATECUSTAPPOINMENT,payload)
  .then((res) => {
    console.log("res search ", res);
     
     
    if(res?.data?.succeeded){
        if(res?.data?.succeeded){
            callback(res?.data) 
            console.log("res search ", res?.data);
            Toast.fire({
              icon: "success",
              title: "Update booked succesfully.",
            });
          }
          else{
            Toast.fire({
              icon: "error",
              title: res?.data?.message
            });    
          }
      callback(res?.data) 
      
    }else{
      Toast.fire({
        icon: "error",
        title: res?.data?.message
      });  
    }
    
  })
  .catch((err) => {
    console.log(err);
  });
}