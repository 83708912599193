import { useEffect, useState } from "react"
import LocationModal from "../features/landingPage/locationModal"
import { NavBar } from "./landingPage/navbar"
import { cityNameCll } from "../features/landingPage/landingPageApi"
import { setNavBarInputFields } from "../app/store/reducer"
import { useDispatch, useSelector } from "react-redux"
import SignButtonPop from "./signButton"

export const NavBoarContainer=()=>{
    const dispatch=useDispatch()
    const [cityName,asignCityName]=useState("")
  const [locationModel,setLocationModal]=useState(false)
  const [currentLocation, setCurrentLocation] = useState("")
    const navBarInputFields = useSelector(
        (state) => state.reducer.navBarInputFields
      );
      useEffect(()=>{
        cityNameCll((data)=>{
          console.log("location" ,data)
          asignCityName(data?.city)
          setCurrentLocation(data?.city)
          dispatch(setNavBarInputFields({location:data?.city}))
        })
      },[])
    useEffect(()=>{
        setLocationModal(false)
      },[navBarInputFields.location])
     
   const [open,setOpen]=useState(true)
    return  (<>
    <LocationModal searchBarValue={cityName} currentLocation={currentLocation} open={locationModel} setOpen={setLocationModal}/>
    {/* <SignButtonPop  open={open} setOpen={setOpen}/> */}
        <NavBar navBarInputFields={navBarInputFields} setLocationModal={()=>{
          setLocationModal((prev)=>{
            return !prev
          })
        }}  />
        
        </>)
}