import { Box, Drawer, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillNo,
  setMobileTableDrawer,
  setTableName,
} from "../../app/store/reducer";
import fireDb from "../../utils/firebase";
import TableWithBox from "../../common/table";

import Swal from "sweetalert2";
import {
  TableSectionListApi,
  getBillCounterSell,
  tableListApi,
} from "./counterSellApi";
import { payloadDateFormat } from "../../utils/commonUtils";
import { getBill, getData } from "./counterSellApiHandlers";
import { callbackHandlerCounterSell_ } from "./countersellSetParameter";
import { getAllEmployeeList } from "../employeeInfo/appoinmentDashboardApi";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

export default function CounterSell() {
  const navigate = useNavigate();
  const [section, setSections] = useState([]);

  const [tables, setTables] = useState([]);

  const [sectionPresent, setSectionPresent] = useState(false);

  const dispatch = useDispatch();
  const [billCount, setBillCount] = useState({});
  const [bookedTables, setBookedTaBles] = useState();
  const billNumber = useSelector((state) => state.reducer.billNumber);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 4,
  });

  const [bills, setBills] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const callbackHandlerCounterSell = callbackHandlerCounterSell_(
    getBill,
    {
      date: payloadDateFormat(),
      status: "null",
      filter: "null",
      search: "null",
      page: 1,
      pageSize: 10,
    },
    getBillCounterSell,
    Swal,
    billNumber,
    dispatch,
    setBillNo,
    setBills,
    setBillCount,
    billCount,
    getData,
    TableSectionListApi,
    setSections,
    tableListApi,
    setSectionPresent,
    setTables
  );

  useEffect(() => {
    getAllEmployeeList((res) => {
      console.log("getAllEmployeeList", res);
      setTables(res?.list);
    });

    // getData(
    //   TableSectionListApi,
    //   setSections,
    //   tableListApi,
    //   setSectionPresent,
    //   setTables
    // );

    // callbackHandlerCounterSell.getData.callback(
    //   callbackHandlerCounterSell.getData.parameter
    // );
    // console.log("callbackHandlerCounterSell");
    // callbackHandlerCounterSell.getBill.callback(
    //   callbackHandlerCounterSell.getBill.parameter
    // );
  }, []);

  const userDetail = useSelector((state) => state.reducer.userDetail);
  useEffect(() => {
    console.log("tables ", tables);
  }, [tables]);

  useEffect(() => {
    console.log("bookedTables ", bookedTables);
  }, [bookedTables]);
  const handleDataChange = (snapshot, eventMode) => {
    const tableOrderData = [];
    if (eventMode === "on") {
      snapshot.forEach((childSnapshot) => {
        const tableData = childSnapshot.val();
        tableOrderData.push(tableData.name);
      });
      console.log("bookedTables firebase ", tableOrderData);

      setBookedTaBles(tableOrderData);
    }
  };

  useEffect(() => {
    let parentNodeRef = null;
    if (userDetail?.mobileNumber) {
      parentNodeRef = fireDb.child(`${userDetail?.mobileNumber}/bookedTables`);
      parentNodeRef?.on("value", (e) => {
        console.log("bookedTables on ");
        handleDataChange(e, "on");
      });
    }
    return () => {
      parentNodeRef?.off("value", (e) => {
        console.log("bookedTables off ");
        handleDataChange(e, "off");
      });
    };
  }, [userDetail]);

  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  const mobileTableDrawer = useSelector(
    (state) => state.reducer.mobileTableDrawer
  );
  return (
    <>
      <DynamicBreadcrumbs />
      <Box
        sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}
      >
        <Grid container>
          <Grid
            xs={drawerShow ? 8 : 12}
            sm={drawerShow ? 6 : 12}
            md={drawerShow ? 8 : 12}
            item
            sx={{}}
          >
            <Grid container>
              {sectionPresent !== true && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    overflowY: "auto",
                    maxHeight: {
                      xs: "calc(100vh - 60px)",
                      sm: "calc(100vh - 60px)",
                    },
                  }}
                >
                  {tables.map((item2, index) => {
                    return (
                      <Box
                        key={"tableListBill" + index}
                        sx={{
                          display: "inline-block",
                          m: "10px 0px",
                        }}
                      >
                        <Box
                          onClick={() => {
                            dispatch(setTableName(item2.name));
                            navigate(routePath.COUNTERSELLBILLING);
                          }}
                          sx={{
                            margin: "0px 10px",
                            cursor: "pointer",
                            boxShadow:
                              bookedTables?.indexOf(item2.name) !== -1
                                ? "0 4px 8px rgb(255 33 33 / 81%)"
                                : "0 4px 8px rgb(27 87 21)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100px",
                            p: "0px",
                            fontWeight: "bold",
                            textAlign: "center",
                            height: "60px",
                            borderRadius: "10px",
                          }}
                        >
                          {item2.name}
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>
              )}
              <Grid
                container
                sx={{
                  overflowY: "auto",
                  maxHeight: {
                    xs: "100vh",
                    sm: "calc(100vh - 60px)",
                  },
                }}
              >
                {sectionPresent !== false &&
                  // tables.length > 0 &&
                  section.map((item, index) => {
                    console.log("tables ", tables);
                    if (
                      tables.findIndex(
                        (table) => table["sectionId"] === item.id
                      ) == -1
                    ) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          p: "10px",
                        }}
                        key={"tablesection" + index}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          align="left"
                          sx={{}}
                        >
                          {item.name}
                        </Typography>
                        <Grid item xs={12} sx={{}}>
                          {tables.map((item2, index) => {
                            if (item2.sectionId !== item.id) {
                              return null;
                            }
                            return (
                              <Box
                                key={"tableListBill" + index}
                                sx={{
                                  display: "inline-block",
                                  m: "10px 0px",
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    dispatch(setTableName(item2.name));
                                    navigate(routePath.COUNTERSELLBILLING);
                                  }}
                                  sx={{
                                    margin: "0px 10px",
                                    cursor: "pointer",
                                    boxShadow:
                                      bookedTables?.indexOf(item2.name) !== -1
                                        ? "0 4px 8px rgb(255 33 33 / 81%)"
                                        : "0 4px 8px rgb(27 87 21)",

                                    background:
                                      bookedTables?.indexOf(item2.name) !== -1
                                        ? "radial-gradient(circle, rgba(238,0,0,1) 0%, rgba(171,0,0,1) 100%)"
                                        : " radial-gradient(circle, rgba(0,189,57,1) 0%, rgba(2,87,50,1) 100%)",
                                    color: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "60px",
                                    width: "100px",
                                    p: "0px",
                                    fontWeight: "bold",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {item2.name}
                                </Box>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={drawerShow ? 4 : 0}
            sm={drawerShow ? 6 : 0}
            md={drawerShow ? 4 : 0}
          >
            <TableWithBox
              data={bills}
              pagesCount={billCount?.totalBills}
              billApiCall={(da) => {
                // getBill(
                //   {
                //     date: da,
                //     status: "",
                //     filter: "",
                //     search: "",
                //     page: 1,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: da,
                  },
                });

                // callbackHandlerCounterSell.getBill.callback(...arr);
              }}
              paginationApiCall={(da, page, status, paymentMode) => {
                console.log("paymentMode ", paymentMode);
                if (paymentMode !== undefined) {
                  // getBill(
                  //   {
                  //     date: da,
                  //     status: status,
                  //     filter: paymentMode,
                  //     search: "null",
                  //     page: page,
                  //     pageSize: 10,
                  //   },
                  //   getBillCounterSell,
                  //   Swal,
                  //   billNumber,
                  //   dispatch,
                  //   setBillNo,
                  //   setBills,
                  //   setBillCount,
                  //   billCount
                  // );
                  callbackHandlerCounterSell.getBill.callback({
                    ...callbackHandlerCounterSell.getBill.parameter,
                    payload: {
                      ...callbackHandlerCounterSell.getBill.parameter.payload,
                      date: da,
                      status: status,
                      filter: paymentMode,
                      page: page,
                      pageSize: 10,
                    },
                  });
                } else {
                  // getBill(
                  //   {
                  //     date: da,
                  //     status: status,
                  //     filter: "",
                  //     search: "",
                  //     page: page,
                  //     pageSize: 10,
                  //   },
                  //   getBillCounterSell,
                  //   Swal,
                  //   billNumber,
                  //   dispatch,
                  //   setBillNo,
                  //   setBills,
                  //   setBillCount,
                  //   billCount
                  // );
                  callbackHandlerCounterSell.getBill.callback({
                    ...callbackHandlerCounterSell.getBill.parameter,
                    payload: {
                      ...callbackHandlerCounterSell.getBill.parameter.payload,
                      date: da,
                      status: status,
                      page: page,
                      pageSize: 10,
                    },
                  });
                }
              }}
              billCount={billCount}
              callback={(item) => {}}
              filterData={(date, status, page) => {
                // getBill(
                //   {
                //     date: date,
                //     status: status,
                //     filter: "",
                //     search: "",
                //     page: page,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: date,
                    status: status,
                    page: page,
                    pageSize: 10,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor={"right"}
          open={mobileTableDrawer}
          onClose={() => {
            dispatch(setMobileTableDrawer(false));
            // setDrawerShow(false);
          }}
          onClick={() => setMobileTableDrawer(false)}
        >
          <TableWithBox
            data={bills}
            pagesCount={billCount?.totalBills}
            billApiCall={(da) => {
              // getBill(
              //   {
              //     date: da,
              //     status: "",
              //     filter: "",
              //     search: "",
              //     page: 1,
              //     pageSize: 10,
              //   },
              //   getBillCounterSell,
              //   Swal,
              //   billNumber,
              //   dispatch,
              //   setBillNo,
              //   setBills,
              //   setBillCount,
              //   billCount
              // );
              callbackHandlerCounterSell.getBill.callback({
                ...callbackHandlerCounterSell.getBill.parameter,
                payload: {
                  ...callbackHandlerCounterSell.getBill.parameter.payload,
                  date: da,
                },
              });
            }}
            paginationApiCall={(da, page, status, paymentMode) => {
              if (paymentMode) {
                // getBill(
                //   {
                //     date: da,
                //     status: status,
                //     filter: paymentMode,
                //     search: "null",
                //     page: page,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: da,
                    status: status,
                    filter: paymentMode,
                    page: page,
                    pageSize: 10,
                  },
                });
              } else {
                // getBill(
                //   {
                //     date: da,
                //     status: status,
                //     filter: "null",
                //     search: "null",
                //     page: page,
                //     pageSize: 10,
                //   },
                //   getBillCounterSell,
                //   Swal,
                //   billNumber,
                //   dispatch,
                //   setBillNo,
                //   setBills,
                //   setBillCount,
                //   billCount
                // );
                callbackHandlerCounterSell.getBill.callback({
                  ...callbackHandlerCounterSell.getBill.parameter,
                  payload: {
                    ...callbackHandlerCounterSell.getBill.parameter.payload,
                    date: da,
                    status: status,
                    page: page,
                    pageSize: 10,
                  },
                });
              }
            }}
            billCount={billCount}
            callback={(item) => {}}
            filterData={(date, status, page) => {
              // getBill(
              //   {
              //     date: date,
              //     status: status,
              //     filter: "null",
              //     search: "null",
              //     page: page,
              //     pageSize: 10,
              //   },
              //   getBillCounterSell,
              //   Swal,
              //   billNumber,
              //   dispatch,
              //   setBillNo,
              //   setBills,
              //   setBillCount,
              //   billCount
              // );
              callbackHandlerCounterSell.getBill.callback({
                ...callbackHandlerCounterSell.getBill.parameter,
                payload: {
                  ...callbackHandlerCounterSell.getBill.parameter.payload,
                  date: date,
                  status: status,
                  page: page,
                  pageSize: 10,
                },
              });
            }}
          />
        </Drawer>
      </Box>
    </>
  );
}
