import { AD1DCASHPAYMENT, ADDCASHPAYMENT, GETACTIVEEMPLOYEELIST, GETASSISTANTPAYROLLDETAIL, GETCOUNTERSALESCUSTOMERS, GETCOUNTERSALESpPAYROLL, GETCOUNTERSELAESSTATEMENT, GETPAYMENTRECEIPT, GETPAYMENTRECEIPTS, GETPAYMENTXLSX, GETUNPAIDBILLS, TTLEASSISTNTPAYROLL } from "../../utils/api"
import axios from "../../utils/axios";
import { Toast } from "../../utils/commonUtils";

export const getCounterSalesStatement=async(param)=>{
    const res=await axios.post(GETCOUNTERSALESpPAYROLL,param)
    return res;
}







export const getCounterSalesCustomers=async(param)=>{
    const res=await axios.get(GETACTIVEEMPLOYEELIST)
    return res;
}


export const GetAssistancePayrollDetail=async(payload,callback)=>{
    await axios
    .post (GETASSISTANTPAYROLLDETAIL, payload)
    .then((res) => {
      console.log("res search ", res);
      
      if(res?.data){
        callback(res.data) 
        Toast.fire({
          icon: "success",
          title: "Appointment booked succesfully.",
        });
      }
      else{
        Toast.fire({
          icon: "error",
          title: res?.data?.message
        }); 
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export const titleAssistantPayRoll=async(payload,callback)=>{
  await axios
  .post (TTLEASSISTNTPAYROLL     , payload)
  .then((res) => {
    console.log("res search ", res);
    
    if(res?.data){
      callback(res.data) 
      Toast.fire({
        icon: "success",
        title: "Appointment booked succesfully.",
      });
    }
    else{
      Toast.fire({
        icon: "error",
        title: res?.data?.message
      }); 
    }
  })
  .catch((err) => {
    console.log(err);
  });
}
