import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";

import AddReceipt from "./addReceipt";
import { getPaymentReceipt, getPaymentXlsx, getUnpaidUser } from "./paymentReceiptApi";
import ReceiptCart from "./receiptCard";
import { useEffect } from "react";
import axios from "../../utils/axios";
import {
  displayDateFormat,
  downloadXLSXFile,
  getEndOfMonth,
  getFirstDateOfCurrentMonthPayloadFormate,
  getFirstDateOfMonth,
  getLastDateOfCurrentMonthPayloadFormat,
  payloadDateFormat,
} from "../../utils/commonUtils";
import { api } from "../../utils/api";
import { getPaymentRec } from "./paymentReceiptCustomApiHandler";
import { searchedElementDisplay } from "./receiptFunctions";
import UserCard from "./userCard";
import PaymentReceiptSearchModal from "./paymentReceiptSearchModal";
import { ToFromDate } from "../../common/toFromDate";
const PaymentReceipt = () => {

  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  const [billFilter, setBillFilter] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());

  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [employeeDetail, setEmployeeDetail] = useState(null)

  const datePickerRef2 = useRef();

  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };
  const [openT, setOpenT] = useState(true)
  const [open, setOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false)
  const [totals, setTotals] = useState();
  const [upPaidBillData, setUnPaidBillData] = useState();
  const [getPaymentObj, setGetPaymentObj] = useState({
    page: 1,
    pageSize: 12,
    // filter: "paymentmode",
    filter: "",

    search: billFilter,
    date: null,
  });
  const [unPaidBill, setUnPaidBill] = useState([]);
  const data = {
    customerMobile: "",
    amount: 0,
    name: ""
  };
  const [billData, setBillData] = useState(data);

  const [searchReceiptsModal, setSearchReceiptsModal] = useState(false)
  const getUnpaid = async (param) => {
    await getUnpaidUser(param)
      .then((res) => {
        console.log("unPaid user", res?.data?.data[0]);

        setUnPaidBillData(res?.data?.data[0]);
      })
      .catch((err) => { });
  };
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 12,
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);
  const handleChange = (event, value) => {
    getPaymentRec(
      {
        fromDate: startDateFormat,
        toDate: endDate,
        ...getPaymentObj,
        page: value,
        pageSize: pagination.pageSize,
        search: billFilter,
      },
      getPaymentReceipt,
      setNumberOfRecords,
      setTotals,
      totals,
      setReceipts
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };
  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };
  const [recepts, setReceipts] = useState([]);

  const [date, setDate] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  useEffect(() => {
    if (getPaymentObj?.date) {

      setSearch("")
      getPaymentRec(
        {
          ...getPaymentObj,
          page: pagination.page,
          fromDate: startDateFormat,
          toDate: endDate,
          //   pageSize: pagination.pageSize,
          filter: billFilter != "" && billFilter !== null ? "paymentmode" : "",
          pageSize: 12,
          search: billFilter,
        },
        getPaymentReceipt,
        setNumberOfRecords,
        setTotals,
        totals,
        setReceipts
      );
    }
  }, [getPaymentObj]);

  useEffect(() => {
    // downloadXLSXFile();
    console.log("datE ", date);
    getPaymentRec(
      {
        // ...getPaymentObj,
        // page: pagination.page,
        // pageSize: pagination.pageSize,
        fromDate: startDateFormat,
        toDate: endDate,
        "page": 1,
        "pageSize": 12,
        "filter": null,//"filter": null,
        "search": "",
        "date": null

      },
      getPaymentReceipt,
      setNumberOfRecords,
      setTotals,
      totals,
      setReceipts
    );
  }, [startDateFormat, endDate]);

  return (
    <>
      <Grid
        container
        sx={{
          p: "20px",
        }}
      >





        <Grid item xs={12} sm={6}
          sx={{
            display: "flex",
            justifyContent: "right"
          }}
        >
          <ToFromDate


            startDateFormat={startDateFormat}
            setstartDateFormat={setstartDateFormat}





            endDate={endDate}
            setEndDate={setEndDate}

          />

          {/* <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        /> */}

          {/* <DatePicker
            hidden={true}
            selected={startDate}
            maxDate={new Date()}
            onChange={(date) => {
              const dateStr = date.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              const dateStr2 = date.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              });
              const [month, day, year] = dateStr.split("/");

              const formattedDate = `${year}-${day.padStart(
                2,
                "0"
              )}-${month.padStart(2, "0")}`;

              setGetPaymentObj({ ...getPaymentObj, date: formattedDate });

              let dateContainer = String(dateStr2).split(" ");
              var year2 = dateContainer[2];
              var month2 = dateContainer[1];
              var day2 = dateContainer[0];
              var formattedDate2 = day2 + "-" + month2 + "-" + year2;
              setDate(formattedDate2);

              setStartDate(date);
            }}
            className="custom-datepicker"
            ref={datePickerRef}
            onClickOutside={() => datePickerRef.current.setOpen(false)}
          /> */}

        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            pt: {
              xs: "10px",
              sm: "0px",
            },
            padding: { sm: "0px 16px" },

            // fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}

        >
          <Box
            // disabled
            // size="small"
            readonly
            fullWidth
            onClick={() => {
              setSearchReceiptsModal(true)

            }}

            // id="name"
            // label="Search by Name/Mobile Number"
            // value={search}
            sx={{
              display: "flex",
              height: "23px",
              justifyContent: "space-between",
              width: "70%",
              border: "1px solid #c9c9c9",
              p: 1,
              borderRadius: "4px ",
              color: "grey",
              '&:hover': {
                cursor: 'pointer !important',
              },
            }}
            onChange={(e) => {

              // setSearch(e.target.value);
            }}
          // InputProps={{
          //   style: {
          //     // background:"yellow"
          //     cursor: 'pointer !important',
          //     '&:hover': {
          //       cursor: 'pointer !important', // Set the cursor to pointer on hover
          //       // Add other hover styles here if needed
          //     },
          //     height: "40px"
          //   },

          // }}
          >
            <Typography variant="p">
              {search !== "" ? search : "Search by Name/Mobile Number"}
            </Typography>
            <SearchIcon
              onClick={() => {
                // setSearchModal(true)
                setSearchReceiptsModal(true)

              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          </Box>

          <FileDownloadIcon
            sx={{ ml: "10px", mr: "0px", cursor: "pointer" }}
            onClick={() => {
              console.log("call ", {
                filter: getPaymentObj.filter,
                search: getPaymentObj.search,
                date: getPaymentObj.date,
              });

              downloadXLSXFile(api + "CounterSales/GetPaymentReceiptsXlsx", {
                filter: getPaymentObj.filter,
                search: getPaymentObj.search,
                date: getPaymentObj.date,
              });
            }}
          />

        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            pt: "10px",
          }}
        >
          <RadioGroup
            row
            sx={{
              fontWeight: "500",
              //   justifyContent: "space-around",
              width: "100%",
            }}
            value={billFilter}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            // value={bill.paymentMode}
            onChange={(e) => {
              setSearch("")
              getPaymentRec(
                {
                  ...getPaymentObj,
                  page: pagination.page,
                  pageSize: 12,
                  fromDate: startDateFormat,
                  toDate: endDate,
                  filter: "paymentmode",
                  search: e.target.value,
                },
                getPaymentReceipt,
                setNumberOfRecords,
                setTotals,
                totals,
                setReceipts
              );
              setBillFilter(e.target.value);
            }}
          >
            <FormControlLabel
              value="cash"
              control={<Radio />}
              label="Cash Payment"
            />
            <FormControlLabel
              value="online"
              control={<Radio />}
              label="Online Payment"
            />
          </RadioGroup>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Recovery:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {/* {billCount?.totalSales}{" "} */}
            {totals?.totalRecovery?.toFixed(2)}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            padding: "8px 16px",
            fontWeight: "500",
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center'
          }}
        >
          <Box>
            <Box
              component="span"
              sx={{
                color: (theme) => theme.palette.primary.main,

                fontWeight: "500",
              }}
            >
              Total Receipt:{" "}
            </Box>
            <Box
              component="span"
              sx={{
                marginLeft: "8px",
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {/* {billCount?.totalOutstanding} */} { }
              {totals?.totalReceipts}
            </Box>
          </Box>
          <Fab
            onClick={() => {
              // setOpen(true);
              setOpen(true);
            }}
            sx={{
              textTransform: "capitalize",
              width: {
                xs: "100%",
                sm: "auto",
              },
              // p: "10px",
              whiteSpace: "nowrap",
            }}
            size="small"
            color="primary"
            variant="extended"
            aria-label="add"
          >
            Add Receipt
            <AddIcon />
          </Fab>
        </Grid>
        {/* <Divider /> */}

        <Divider
          sx={{
            width: "100%",
            background: (theme) => theme.palette.primary.main,
          }}
        />
        {/* <ReceiptCart /> */}
        {/* <AddCircleIcon
          color="primary"
          onClick={async () => {
            setOpen(true);
          }}
          sx={{
            fontSize: "60px",
            cursor: "pointer",
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        /> */}
        <Grid container>
          {recepts?.map((item, i) => {
            // if (
            //   !item?.customer?.toLowerCase().includes(search) &&
            //   !item?.customerMobile
            //     ?.toLowerCase()
            //     .includes(search.toLowerCase()) &&
            //   search.length > 0
            // ) {
            //   return null;
            // }
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={"payamentRecpt" + i}
                sx={{ p: "3px", borderRight: "3px solid #dfdfdf" }}
              >
                <ReceiptCart item={item} />
              </Grid>
            );
          })}
        </Grid>
        <AddReceipt
          open={open}
          setOpen={setOpen}
          getRecept={() => {
            console.log("Payment saved ss");
            getPaymentRec(
              {
                ...getPaymentObj,
                page: pagination.page,
                pageSize: pagination.pageSize,
                fromDate: startDateFormat,
                toDate: endDate,
                search: billFilter,
              },
              getPaymentReceipt,
              setNumberOfRecords,
              setTotals,
              totals,
              setReceipts
            );
          }}
          unPaidBill={unPaidBill}
          setUnPaidBill={setUnPaidBill}
          upPaidBillData={upPaidBillData}
          setUnPaidBillData={setUnPaidBillData}
          billData={billData}
          setBillData={setBillData}
          searchModal={searchModal}
          setSearchModal={setSearchModal}
        />
        <Modal
          open={searchModal} sx={{ zIndex: 999999 }} // Yo
          onClose={() => {
            setSearchModal(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container sx={{
            // backgroundColor: "white",
            // width:"40%"
            background: "white",
            width: "40%",
            overflowY: "auto",
            height: "70vh",
            position: "absolute",
            left: "30%",
            top: "5%",
          }}>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  sx={{ width: "90%" }}
                  label="Search by Name/Mobile Number"
                  value={search}
                  onChange={(e) => {
                    // getPaymentRec(
                    //   { 
                    //       "page": 1,
                    //       "pageSize": 10,
                    //       "filter": e.target.value,//"filter": null,
                    //       "search": "",
                    //       "date": null

                    //   },
                    //   getPaymentReceipt,
                    //   setNumberOfRecords,
                    //   setTotals,
                    //   totals,
                    //   setReceipts
                    // );
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
                <CloseIcon sx={{ float: "right", cursor: "pointer", color: (theme) => theme.palette.primary.main, }} onClick={() => setSearchModal(false)} />
              </Box>
              <Box sx={{ height: "50vh", overflowY: "auto" }}>
                {unPaidBill.map((item, index) => {
                  if (searchedElementDisplay(item, search)) {
                    return null;
                  }
                  return (
                    <UserCard
                      data={item}
                      callBack={() => {
                        console.log("item ", item);
                        getUnpaid({
                          customerMobile: item?.mobile,
                        });
                        setBillData({
                          ...item,
                          customerMobile: item?.mobile,
                          customer: item?.name,
                        });

                        setSearchModal(false)
                      }}
                    />
                  );
                })}
              </Box>

            </Grid>
          </Grid>
        </Modal>
        <PaymentReceiptSearchModal
          searchModal={searchReceiptsModal}
          setSearchModal={setSearchReceiptsModal}
          unPaidBill={unPaidBill}
          getUnpaid={getUnpaid}
          setBillData={setBillData}
          search={search}
          setSearch={setSearch}
          setUSerObject={()=>{
            
          }}
          setUnPaidBill={setUnPaidBill}
          setUnPaidBillData={setUnPaidBillData}
          setPagination={setPagination}
          billData={billData}
          callSearchApi={(searchName) => {
            setBillFilter("")
            getPaymentRec(
              {
                // ...getPaymentObj,
                // page: pagination.page,
                // pageSize: pagination.pageSize,

                "page": 1,
                "pageSize": 10000,
                fromDate: startDateFormat,
                toDate: endDate,
                "filter": "buyername",//"filter": null,
                "search": searchName,
                "date": getPaymentObj?.date

              },
              getPaymentReceipt,
              setNumberOfRecords,
              setTotals,
              totals,
              setReceipts
            );
          }}
        //  userDetail={userDetail}
        />
        {/* ------------------------------------------------------ */}


        <Grid container sx={{ pt: "10px" }}>
          <Grid item xs={4}>

          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              // bgcolor: "red",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
            <Pagination
              count={
                Number.isInteger(numberOfRecords / pagination.pageSize)
                  ? numberOfRecords / pagination.pageSize
                  : parseInt(numberOfRecords / pagination.pageSize) + 1
              }
              page={pagination.page}
              onChange={handleChange}
            />
            {/* </Stack> */}
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {/* ------------------------------- */}

      </Grid >
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default PaymentReceipt;
