import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import Purchase from "../purchase/purchase";
import Stocks from "../stocks/stocks";
import ItemServices from "../items_services/ItemServices";
import ItemCategories from "../ItemsCategories/itemCategories";
import DynamicBreadcrumbs from "../../common/Breadcrumb";

export const ItemService=()=>{
  const [value, setValue] = React.useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);
    
    setValue(newValue);
  };

    return ( 
    
   <>
        <DynamicBreadcrumbs />
    <Box   sx={{
          margin: {
            xs: "10px",
            sm: "50px 10px 10px 10px ",
            md: "50px",
          },
        }}>
        <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab
          value="all"
          label="Item Details"
          onClick={() => {
            // setPaidBills("");
            // paginationApiCall(date, pageNo, "null");
          }}
          sx={{ fontWeight: value === 'all' ? '700' : '',width:'50%'  }}
        />
        
        <Tab
           value="purchase"
          label="Item Category"
          onClick={() => {
            // paginationApiCall(date, pageNo, "null", "paid");
          }}
          sx={{ fontWeight: value === 'paid' ? '700' : '',width:'50%' }}
        />
      </Tabs>
      <Grid container>
      <Grid item xs={12}>
          {value=="purchase"?<ItemCategories/>:<ItemServices />}
      </Grid>
      </Grid>
    </Box>
    </>
      )
}