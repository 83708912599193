import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Card,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoImage from "../../assets/img/logo.svg";
import { otpApi } from "./otpApi";
import { generateRandomString, getSession } from "../../utils/commonUtils";
import { encrypt } from "../../utils/encryption";
import { routePath } from "../../constants/routePath";
import { useSelector } from "react-redux";
import { VALIDCUSTOMERAPPOINMENTANAMONUS, VALIDOTP } from "../../utils/api";

const OTP = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const customerAppointmentId = useSelector((state) => state.reducer.customerAppointmentId);
  const apppoinmentUerType = useSelector((state) => state.reducer.apppoinmentUerType);
  const handleChange = (value, index) => {
    let newOtp = [...otp];
    if (/^[0-9]?$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };
  useEffect(() => {
  console.log(apppoinmentUerType,'apppoinmentUerType')

}, [apppoinmentUerType])

  const handleKeyUp = (e, index) => {
    const nextInput = document.querySelector(`input[name=otp${index + 1}]`);
    const prevInput = document.querySelector(`input[name=otp${index - 1}]`);

    if (e.key === "Backspace" && prevInput) {
      prevInput.focus();
    } else if (nextInput && e.target.value !== "") {
      nextInput.focus();
    }
  };
  const navigate = useNavigate();
  const payLoadhandle = (obj) => {
    // let obj=JSON.parse(data)

    const randomId = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const pass = otp.join("") ;
    console.log(pass,'passpass')
    if (apppoinmentUerType == "otp") {
     return {
      api:VALIDCUSTOMERAPPOINMENTANAMONUS,
       payload:{ 
        customerAppointmentId: customerAppointmentId,
        otp: pass}
      }
    } else {
      let passt=pass+randomId
      console.log("passt",passt)
      return { 
        api:VALIDOTP,
        payload:{guid: obj.guId, otp: encrypt(passt, "9z8x7c1m2n3b5l4k")}
       };
    }

    // const appoinmentPayload = {
    //   customerAppointmentId: 0,
    //   otp: "string",
    // };
    const OtpLogin = { guid: obj.guId, otp: encrypt(pass, "9z8x7c1m2n3b5l4k") };
    return {};
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("DATA ", otp.join(""));
    getSession("eleGentUser", (data) => {
      console.log("Data ddd", data);
      let obj = JSON.parse(data);
      const randomString = generateRandomString(4);
      const randomId = Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0"); 
      // const  randomId = Math.floor(Math.random() * 10000)
      // .toString()
      // .padStart(4, '0');
      const condition=apppoinmentUerType=="profile"
      payLoadhandle(obj);
      otpApi(
        payLoadhandle(obj),
        (data) => {
          console.log("Data otp===", data);
        //   if(!condition){
        //     navigate(routePath.SERVICESLIST)
        //   }
        //  else{
          navigate(routePath.SETMPIN);
        //  }
        }
      );
    });
    // alert(`OTP: ${otp.join("")}`);
    // console.log("getObj",getObj)
    // otpApi({guid:getObj.guid,otp:otp.join("")},()=>{

    // })
  };

  return (
    <Container>
      <Card sx={{ padding: "20px" }}>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={LogoImage}
                alt="logo"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: "#3695B6" }}>
                Enter OTP
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mb: 2,
                }}
              >
                {otp.map((value, index) => (
                  <TextField
                    key={index}
                    name={`otp${index}`}
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleKeyUp(e, index)}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: {
                        textAlign: "center",
                        fontSize: "24px",
                        width: "50px",
                        height: "50px",
                      },
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                ))}
              </Box>
              <Box marginTop={1}>
                <Typography
                  sx={{
                    color: "#2096A4",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  DO NOT RECEIVE OTP?{" "}
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#3695B6",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                  >
                    RESEND
                  </Link>
                </Typography>
              </Box>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#3695B6",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "600",
                  marginTop: "10px",
                  width: "20rem",
                }}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default OTP;
