import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import Purchase from "../purchase/purchase";
import Stocks from "../stocks/stocks";

export const Inventory=({})=>{
  const [value, setValue] = React.useState("all");

  const handleChange = (event, newValue) => {
    // setPageNo(1);
    
    setValue(newValue);
  };

    return ( 
    
    <Box>
        <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab
          value="all"
          label="Stock"
          onClick={() => {
            // setPaidBills("");
            // paginationApiCall(date, pageNo, "null");
          }}
          sx={{ fontWeight: value === 'all' ? '700' : '',width:'50%'  }}
        />
        
        <Tab
           value="purchase"
          label="purchase"
          onClick={() => {
            // paginationApiCall(date, pageNo, "null", "paid");
          }}
          sx={{ fontWeight: value === 'paid' ? '700' : '',width:'50%' }}
        />
      </Tabs>
      <Grid container>
      <Grid item xs={12}>
          {value=="purchase"?<Purchase />:<Stocks/>}
      </Grid>
      </Grid>
    </Box>
      )
}