import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";

import {
  Box,
  Divider,
  Fab,
  Grid,
  Modal,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DatePicker from "react-datepicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";



import { displayDateFormat, formatDateFromCustomToStandard, getEndOfMonth, getEndOfMonthISO, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getLastDateOfCurrentMonthPayloadFormat, getLastDateOfMonth, getStartOfMonth, payloadDateFormat } from "../../../utils/commonUtils";






import PurchaseSearchModal from "../../purchase/purchaseSearchModal";
import { callbackHandlerGetPurchase } from "../../purchase/purchaseFunctionParamter";
import { PageSizeCalculate } from "../../purchase/purFunction";
import { datePickerOnChange,
    datePickerOnChangeEnd } from "../../purchase/purchaseDateFunctions";
import { getPurchase } from "../../purchase/purchaseCustomApiHandler";
import PuchaseModal from "../../purchase/purchaseModal";
import PurchaseAccordian from "../../purchase/purchaseAccordian";
import { getPurchaseDetail } from "../../purchase/purchaseApi";
import Purchase from "../../purchase/purchase";



const PurchaseReportData = () => {
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [counterSales, setCounterSales] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
    pageSize: 15,
  });
  const [numberOfRecords, setNumberOfRecords] = useState(1);

  const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonthISO());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [SelectedEndDate, setSelectedEndDate] = useState(getLastDateOfMonth());
  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());

  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());
  let payload = {
    rawMaterialId: 0,
    quantity: 0,
    amount: 0,
    description: "",
    price: 0,
  };
  const [purchaseItem, setPurchaseItem] = useState(payload);

  const [searchItem, setSearchItem] = useState([]);
  const datePickerRef = useRef();
  const datePickerRef2 = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const [totalAmount, setTotalAmount] = useState(0);
  const [purchaseItems, setPurchaseItems] = useState([]);

  const callbackHandlerGetPurchaseob = callbackHandlerGetPurchase(
    getPurchase,
    {
      fromDate: startDateFormat,
      toDate: endDate,
      search: "string",
      page: 1,
      pageSize: pagination.pageSize,
    },
    setNumberOfRecords,
    setPurchaseItems,
    getPurchaseDetail,
    setTotalAmount
  )
  useEffect(() => {

    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: 1,
        pageSize: pagination.pageSize,
      }
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: 1,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail, setTotalAmount
    // );
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", getFirstDateOfCurrentMonthPayloadFormate());
    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: 1,
        pageSize: pagination.pageSize,
      }
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: 1,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail, setTotalAmount
    // );
  }, []);
  const handleChange = (event, value) => {
    callbackHandlerGetPurchaseob.getPurchase.callback({
      ...callbackHandlerGetPurchaseob.getPurchase.parameter,
      param: {
        fromDate: startDateFormat,
        toDate: endDate,
        search: "string",
        page: value,
        pageSize: pagination.pageSize,
      },
    }
    )
    // getPurchase(
    //   {
    //     fromDate: startDateFormat,
    //     toDate: endDate,
    //     search: "string",
    //     page: value,
    //     pageSize: 4,
    //   },
    //   setNumberOfRecords,
    //   setPurchaseItems,
    //   getPurchaseDetail,
    //   setTotalAmount
    // );
    setPagination({
      ...pagination,
      page: value,
    });
  };
 
  

  return (<Purchase gridSize={{
    sm:6,
    xs:12
  }}
   />)
   
};

export default PurchaseReportData;
