import { Box, Button, DialogActions, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, RadioGroup, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import EventIcon from "@mui/icons-material/Event";
import { DateCp } from "./dateCp";
import { addCounter, updateCounter } from "./api";
export const Form = ({ open, setOpen, formDataModal, setFormDataModal,callback }) => {
    let t={
        customer: "",






        customerMobile: "",
        birthdate: new Date(),
        anniversaryDate: new Date(),
        customerMailID: "",
        gender: ""
    }
    const [formData, setFormData] = useState({...t})
    const [startDate, setStartDate] = useState(new Date());
    const datePickerRef = useRef();
    const openDatePicker = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
        }
    };
    const addLeadingZero = (value) => {
        return value < 10 ? "0" + value : value;
    };
    const [date, setDate] = useState(
        (() => {
            var currentDate = new Date();
            let dateContainer = String(currentDate).split(" ");
            var year = dateContainer[2];
            var month = dateContainer[1];
            var day = dateContainer[3];
            var formattedDate =
                year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

            return formattedDate;
        })()
    );

    useEffect(() => {
        if (open == true && formDataModal) {
            console.log("formDataModal ", formDataModal)
            setFormData({
                ...formDataModal,
                customer: formDataModal?.name,
                customerMailID: formDataModal?.customerMailID
            })
        }
        else {
            setFormData({  customer: "",






                customerMobile: "",
                birthdate: new Date(),
                anniversaryDate: new Date(),
                customerMailID: "",
                gender: ""})
            setFormDataModal(null)
            
        }
    }, [formDataModal])
    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    background: "white",
                    width: "52%",
                    overflowY: "auto",
                    // height: "90vh",
                    position: "absolute",
                    left: "25%",
                    top: "10%",
                    padding: '10px'
                }}
            >
                <DialogTitle
                    color="primary"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 'auto',
                        p: "0px 10px !important"
                    }}
                >
                    Customer Information{" "}
                    <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <Grid container sx={{ position: "relative" }}>


                    <Grid item xs={12}>

                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                id="briefAddress"
                                size="small"
                                label="Customer"
                                InputProps={{
                                    style: {
                                        // height: '40px',
                                        // background:"yellow"
                                    },
                                }}
                                value={formData?.customer}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData, customer: e.target.value
                                    })
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>

                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                id="briefAddress"
                                size="small"
                                label="Mobile number"
                                InputProps={{
                                    style: {
                                        // height: '40px',
                                        // background:"yellow"
                                    },
                                }}
                                value={formData?.customerMobile}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData, customerMobile: e.target.value
                                    })
                                }}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12}>

                        <Box sx={{ mt: 2, cursor: "pointer" }}>
                            <DateCp
                                label={'date of birth '}

                                setFormatDate={(data) => {
                                    setFormData({
                                        ...formData, birthdate: data
                                    })
                                }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>

                        <Box sx={{ mt: 2, cursor: "pointer" }}>
                            <DateCp label={'date of aniversary '}

                                setFormatDate={(data) => {
                                    setFormData({
                                        ...formData, anniversaryDate: data
                                    })
                                }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                id="briefAddress"
                                size="small"
                                label="email"
                                InputProps={{
                                    style: {
                                        // height: '40px',
                                        // background:"yellow"
                                    },
                                }}
                                value={formData?.customerMailID}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData, customerMailID: e.target.value
                                    })
                                }}
                            />
                        </Box>

                    </Grid>






                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" className='mulish'>
                                Gender:
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    checked={formData.gender == "Female"}
                                    label="Female"
                                    className='mulish'
                                    onClick={(e) => {



                                        setFormData({ ...formData, gender: e.target.value })
                                    }}
                                />
                                <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    checked={formData.gender == "Male"}
                                    label="Male"
                                    className='mulish'
                                    onClick={(e) => {



                                        setFormData({ ...formData, gender: e.target.value })
                                    }}
                                />
                                <FormControlLabel
                                    value="Other"
                                    control={<Radio />}
                                    checked={formData.gender == "Other"}
                                    label="Other"
                                    className='mulish'
                                    onClick={(e) => {



                                        setFormData({ ...formData, gender: e.target.value })
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>



                <DialogActions>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setOpen(false);
                            if (formDataModal) {

                                updateCounter({ ...formData },()=>{
                                    callback()
                                })
                            }
                            else {
                                addCounter({ ...formData },()=>{
                                    callback()
                                })

                            }
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>














            </Box></Modal>
    )
}