export const setActiveHeader = (headerMenu, urlHeaderMenu, location) => {
  const indexRoute3 = urlHeaderMenu?.findIndex(
    (it) => it.route === location.pathname
  );
  let routeObject = urlHeaderMenu[indexRoute3];
  let ind = headerMenu?.findIndex(
    (ele) => ele?.systemFuctionName === routeObject?.systemFuctionName
  );

  return ind;
};
const padZero = (value) => {
  return value.toString().padStart(2, "0");
};

export const receiptDateFormate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0"); // Zero padding for day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Zero padding for month
  const year = String(date.getFullYear()).slice(-2); // Extract last 2 digits of the year
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
export const receptTime = () => {
  const date = new Date();
  const hour = date.getHours() % 12 || 12;
  const minute = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  return `${hour}:${padZero(minute)} ${ampm}`;
};

export const shareOnWhatsApp = (
  userDetail,
  getItemDetails,
  bill,
  billNumber,
  paybill,
  tableName,
  totalAmountBill,
  ApplyAccordingREAndCBUser,
  //param
  // ApplyAccordingREAndCBUser,
  // userDetail,
  currentDataArr,
  addtoCardProductCBUser,
  discountAmount,link
) => {
  console.log("shareOnWhatsApp ");
  const message = `${encodeURIComponent(
    `${userDetail?.myBusinessName}\n${userDetail?.billerAddress}\nPH-NO:${
      userDetail?.mobileNumber
    }\nBILL NO :${billNumber + 1} ${receiptDateFormate()} ${receptTime()}\n`
  )}
${encodeURIComponent(
  ApplyAccordingREAndCBUser(
    userDetail,
    () => tableName,
    () => ""
  )
)} 
${encodeURIComponent(
  getItemDetails(
    ApplyAccordingREAndCBUser,
    userDetail,
    currentDataArr,
    addtoCardProductCBUser
  )
)}
 
${(function () {
  if (discountAmount > 0) {
    return (
      encodeURIComponent(
        `\nSub Total : RS. ${paybill ? totalAmountBill : totalAmountBill}`
      ) +
      encodeURIComponent(
        `\nDiscount: RS. ${paybill ? discountAmount : discountAmount}`
      )
      // +
      // `\n----------------------------------------------------------------`
    );
  }
  return ``;
})()}
 

${encodeURIComponent(`\nTotal Amount: RS. ${totalAmountBill - discountAmount}`)}

${encodeURIComponent(`\n-----------------------------\nTHANKS VISIT AGAIN`)}`;

  const whatsappLink = `https://web.whatsapp.com/send?phone=${bill?.customerMobile}&text=${link}`;
  window.open(whatsappLink, "_blank");
};

export const getItemDetails = (
  ApplyAccordingREAndCBUser,
  userDetail,
  currentDataArr,
  addtoCardProductCBUser
) => {
  return `
---------------------------------------------- 
ITEM   
QTY     RATE   GST   TOTAL
----------------------------------------------
${ApplyAccordingREAndCBUser(
  userDetail,
  () => {
    return currentDataArr;
  },
  () => {
    return addtoCardProductCBUser;
  }
)?.map((ele, index) => {
  return `
${ApplyAccordingREAndCBUser(
  userDetail,
  () => {
    return ele[1] === undefined ? ele.itemDescription : ele[1].itemDescription;
  },
  () => {
    return ele.itemDescription;
  }
)}
  ${ApplyAccordingREAndCBUser(
    userDetail,
    () => {
      return ele[1] === undefined ? ele.quantity : ele[1].suppliedQuantity;
    },
    () => {
      return ele.suppliedQuantity;
    }
  )}        ${ApplyAccordingREAndCBUser(
    userDetail,
    () => {
      return ele[1] === undefined
        ? ele.rate?.toFixed(1)
        : ele[1].currentPrice?.toFixed(1);
    },
    () => {
      return ele.currentPrice?.toFixed(1);
    }
  )}     ${ApplyAccordingREAndCBUser(
    userDetail,
    () => {
      return ele[1] === undefined
        ? ele.gst?.toFixed(1)
        : ele[1].gst == 0
        ? "-"
        : ele[1].gst?.toFixed(1) + "%";
    },
    () => {
      return ele.gst?.toFixed(1) + "%";
    }
  )}     ${ApplyAccordingREAndCBUser(
    userDetail,
    () => {
      return ele[1] === undefined
        ? (ele.rate + ele.rate * (ele.gst / 100)).toFixed(1)
        : (ele[1].price * ele[1].suppliedQuantity)?.toFixed(1);
    },
    () => {
      return (ele.price * ele.suppliedQuantity)?.toFixed(1);
    }
  )}`;
})}
----------------------------------------------`;
};




export const formDataFn=(dataT)=>{
  let data = { ...dataT };

// Create a new FormData instance
let formData = new FormData();

// Loop through the object and append each key-value pair to formData
for (const key in data) {
  if (data.hasOwnProperty(key)) {
    formData.append(key, data[key]);
  }
}
return data
}