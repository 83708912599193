import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DiscountModal from "../../common/discountModel";
import SearchIcon from "@mui/icons-material/Search";

import ReactToPrint from "react-to-print";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Grid from "@mui/material/Grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { UpdateBillCounterSell } from "./updateBillApi";
import { useSelector } from "react-redux";
import { routePath } from "../../constants/routePath";
import MenuSideBar from "../../common/menuSideBar";
import Swal from "sweetalert2";
import PrintBilling from "../../common/printBilling";
import { updateBil } from "./updateBillApiHandler";
import { swalAlertPopProps } from "../../utils/commonUtils";
import { updateBillValidation } from "./updateBillValidationFunctions";
import { TotalAmount, validationUpdate } from "./updateBillingFunctions";

export default function UpdateBills() {
  const [indexTableItem, setIndexTableItem] = useState();
  const navigate = useNavigate();
  const [billData, setbillData] = useState({});
  const updateBillData = useSelector((state) => state.reducer.updateBillData);
  const tableName = useSelector((state) => state.reducer.tableName);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose2 = () => setOpen2(false);
  const componentRef = useRef();

  const removeItem = (index) => {
    let arr = [...billData.counterSalesChild];
    arr.splice(index, 1);
    setbillData({
      ...billData,
      counterSalesChild: arr,
    });
  };
  const navigateCallback = () => {
    if (Object.keys(updateBillData).length === 0) {
      navigate(routePath.COUNTERSELL);
    }
  };
  // useEffect(())
  useEffect(() => {
    navigateCallback();
    console.log("Update Bill Data ", updateBillData);
    setbillData(updateBillData);
  }, []);
  const [CollectionTableItems, setCollectionTableItems] = useState([]);

  const payableValueCalculate = () => {
    let sum = sumItemPrice();

    let discountPrice = (billData?.discount / 100) * sum;
    if (billData?.discountType === "Percent") {
      return sum - discountPrice;
    } else {
      return sum - billData?.discount;
    }
  };
  const addQuantity = (ele, index) => {
    let obj = { ...ele };
    obj.quantity++;
    let quan = obj.quantity;
    let rate = obj.rate;
    obj.amount = (rate + obj.rate * (obj.gst / 100)) * obj.quantity;
    console.log("obj = ", obj);
    // obj.amount =
    //   (item[1].price + (item[1].price * item[1].gst) / 100) *
    //   item[1].suppliedQuantity;
    let arr = [...billData.counterSalesChild];
    arr[index] = obj;
    setbillData({
      ...billData,
      counterSalesChild: arr,
    });
  };
  const subQuantity = (ele, index) => {
    let obj = { ...ele };
    if (obj.quantity > 1) {
      obj.quantity--;
      let quan = obj.quantity;
      let rate = obj.rate;
      obj.amount = (rate + obj.rate * (obj.gst / 100)) * obj.quantity;
      let arr = [...billData.counterSalesChild];
      arr[index] = obj;
      setbillData({
        ...billData,
        counterSalesChild: arr,
      });
    } else {
      removeItem(index);
    }
  };
  const sumItemPrice = () => {
    let sum = 0;
    billData?.counterSalesChild?.forEach((item) => {
      sum += item.amount;
    });
    return sum;
  };
  useEffect(() => { }, [billData]);
  const updateBillFunction = () => {
    const valid = validationUpdate(billData);
    if (valid.status) {
      updateBil(
        {
          ...billData,
          discount: billData?.discount ? parseFloat(billData?.discount) : 0,
        },
        UpdateBillCounterSell,
        Swal,
        navigate,
        routePath
      );
    } else {
      Swal.fire({
        title: valid.msg,
        text: "",
        icon: "error",

        ...swalAlertPopProps,
      });
    }
  };
  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 66px)",
      }}
    >
      <Grid container>
        <Grid item xs={12} container >
          <Grid item xs={8.5}>
            <Grid xs={12} sx={{ height: "3rem" }}>
              <Grid container sx={{ height: "100%" }}>
                <Grid
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    p: "10px",
                    display: "flex",
                    m: 0,
                    alignItems: "center",
                    height: "100%",
                  }}
                  item
                  // xs={6}
                  xs={8}
                >
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      ".MuiBreadcrumbs-separator": {
                        color: "white",
                      },
                    }}
                  >
                    <Typography
                      underline="none"
                      color="white"
                      fontWeight={"700"}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(routePath.COUNTERSELL)}
                    >
                      Counter Sell Billing
                    </Typography>

                    <Typography
                      underline="none"
                      color="white"
                      fontWeight={"700"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {billData?.customer}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    // p: "10px 0px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: "100%",
                  }}
                  item
                  xs={4}
                >
                  <TextField
                    sx={{
                      background: "white",
                      float: "left",
                      borderRadius: "5px",
                      width: "80%",
                      // height: "0rem",
                    }}
                    variant="outlined"
                    placeholder="Search in Menu"
                    InputProps={{
                      style: {
                        height: "2.5rem", // Set the desired height here
                      },
                      endAdornment: (
                        <IconButton type="submit" aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />

                  {/* <PersonIcon

              /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 107px)",
                  minHeight: "calc(100vh - 107px)",
                  paddingRight: "4px",
                }}
              >
                <MenuSideBar
                  CollectionTableItems={CollectionTableItems}
                  setCollectionTableItems={setCollectionTableItems}
                  indexTableItem={indexTableItem}
                  setIndexTableItem={setIndexTableItem}
                />
              </Grid>

              <Grid
                item
                xs={9}
                sx={{
                  // backgroundColor: (theme) => theme.palette.primary.main,
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 108px)",

                  minHeight: "calc(100vh - 108px)",
                  paddingRight: "4px",
                }}
              >
                <Grid container>
                  {CollectionTableItems[indexTableItem]?.tableItems.map(
                    (item, index) => {
                      return (
                        <Grid
                          item
                          key={"tableitems" + index}
                          xs={3}
                          //   xs={currentDataArr?.length > 0 && addToCardWindow ? 3 : 2}
                          sx={{ p: "10px", textAlign: "center" }}
                        >
                          <Box
                            onClick={() => {
                              console.log("update bi", item);
                              // const ind = billData?.counterSalesChild.filter(
                              //   (table) => table.itemId === item.id
                              // );
                              const ind = billData?.counterSalesChild.findIndex(
                                (table) => table.itemId === item.id
                              );

                              if (ind === -1) {
                                let arr = [...billData.counterSalesChild];
                                let obj = {};
                                obj["itemId"] = item.id;
                                obj["gst"] = item.gst;
                                obj["amount"] =
                                  (item.price + (item.price * item.gst) / 100) *
                                  1;
                                obj["gstAmount"] = item.gst;
                                obj["itemDescription"] = item.itemDescription;
                                obj["quantity"] = 1;
                                obj["rate"] = item.price;
                                obj["unit"] = item.unit;
                                arr.push(obj);

                                setbillData({
                                  ...billData,
                                  counterSalesChild: arr,
                                });
                              } else {
                                let arr = [...billData.counterSalesChild];

                                console.log("ind ", arr[ind]);
                                // arr[ind].quantity = arr[ind].quantity + 1;
                                let updatedItem = {
                                  ...arr[ind],
                                  // quantity: arr[ind].quantity + 1,
                                  // amount: arr[ind].amount * arr[ind].quantity,
                                };

                                // Update the array at the specified index with the updated object
                                arr[ind] = updatedItem;

                                setbillData({
                                  ...billData,
                                  counterSalesChild: arr,
                                });
                              }
                            }}
                            sx={{
                              p: "0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                              height: "4rem",
                              borderRadius: "10px",
                              color: "#5693a3",
                              cursor: "pointer",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                              ":hover": {
                                bgcolor: "#2892ba17",
                              },
                            }}
                          >
                            {item.itemDescription}
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3.5}
            sx={{
              overflowY: "auto",
              height: "calc(100vh - 60px)",
            }}
          >
            <Box
              sx={{
                p: "10px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                textAlign="center"
                sx={{
                  mb: "10px",
                }}
                color="primary"
              >
                Update Bills
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Box sx={{}}>
                  <Box
                    sx={{
                      p: "10px",
                      position: "relative",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={8} sx={{ p: "5px" }}>
                        <Box
                          sx={{
                            border: "1px solid #dfdfdf",
                            padding: "7px 14px",
                            // height: "1.43em",
                            borderRadius: "4px",
                            // color: "#00000059",


                            display: "flex",
                            // justifyContent: "space-between",
                            marginTop: "4px",
                          }}
                        >
                          <Typography sx={{ margin: "0px 10px" }}>
                            Table No:{" "}
                          </Typography>
                          <Typography>{billData?.customer}</Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sx={{ p: "5px" }}>
                        <Box
                          sx={{
                            border: "1px solid #dfdfdf",
                            padding: "7px 14px",
                            borderRadius: "4px",
                            display: "flex",
                            // color: "#00000059",
                            marginTop: "4px",
                          }}
                        >
                          <Typography sx={{ margin: "0px 10px" }}>
                            Bill No:{" "}
                          </Typography>
                          <Typography>{billData?.billNumber}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sx={{ p: "5px" }}>
                        <Box
                          sx={{
                            border: "1px solid #dfdfdf",
                            padding: "7px 14px",
                            borderRadius: "4px",
                            display: "flex",
                            // color: "#00000059",

                            marginTop: "4px",
                          }}
                        >
                          <Typography sx={{ margin: "0px 10px" }}>
                            Bill Date:{" "}
                          </Typography>
                          <Typography>
                            {billData?.billDate?.split("T")[0]}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sx={{ p: "5px" }}>
                        <TextField
                          fullWidth
                          size="small"
                          id="BrifeAddress"
                          // label="Table Name"
                          sx={{ width: "100%" }}
                          name="BrifeAddress"
                          placeholder="Mobile No"
                          value={billData?.customerMobile}
                          onChange={(e) => {
                            setbillData({
                              ...billData,
                              customerMobile: e.target.value.replace(/\D/g, ""),
                            });
                          }}

                        // value={modelTable}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{
                        p: "5px",
                        // color: "#00000059",
                      }}>
                        <TextField
                          fullWidth
                          size="small"
                          // id="BrifeAddress"
                          // label="Table Name"
                          sx={{
                            width: "100%",
                            color: "#00000059 !important",
                          }}
                          name="BrifeAddress"
                          // placeholder="Custmer Name"
                          value={billData?.customer}
                          onChange={(e) => {
                            setbillData({
                              ...billData,
                              customer: e.target.value,
                            });
                          }}
                        // value={modelTable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          {billData?.counterSalesChild?.length > 0 && (
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  borderBottom: "1px solid",

                                  borderColor: "divider",
                                  backgroundColor: "#f5f5f5",
                                  padding: "8px 16px",
                                  fontWeight: "600",
                                  color: "#000000"
                                }}
                              >
                                <Box
                                  sx={{
                                    flex: 1,

                                  }}
                                ></Box>

                                <Box
                                  sx={{
                                    flex: 4,
                                  }}
                                >
                                  Item
                                </Box>
                                <Box
                                  sx={{
                                    flex: 3,
                                  }}
                                >
                                  Price/Unit
                                </Box>

                                <Box
                                  sx={{
                                    flex: 2,
                                  }}
                                >
                                  Qunatity{" "}
                                </Box>
                                <Box
                                  sx={{
                                    flex: 1,
                                    // color: (theme) => theme.palette.primary.main,
                                  }}
                                >
                                  Amount{" "}
                                </Box>
                              </Box>
                            </Box>
                          )}
                          <Box
                            sx={{
                              overflowY: "auto",
                              height: "20vh",
                            }}
                          >
                            {billData?.counterSalesChild?.map((ele, index) => {
                              // return null
                              console.log("sss= ", ele, " ", index);

                              return (
                                <React.Fragment key={"billlinst" + index}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        borderBottom: "1px solid",
                                        borderColor: "divider",
                                        padding: "8px 16px",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => { }}
                                    >
                                      <Box sx={{ flex: 1 }}>
                                        <CancelIcon
                                          sx={{
                                            color: "#ff2e2e",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            removeItem(index);
                                          }}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          flex: 4,
                                        }}
                                        fontWeight="400"
                                        color={(theme) => theme.palette.primary.main}
                                      >
                                        {ele?.itemDescription}
                                      </Box>
                                      <Box sx={{ flex: 3 }} fontWeight="400">
                                        {ele?.rate}/{ele?.unit}
                                      </Box>
                                      <Box
                                        sx={{
                                          flex: 2,
                                          display: "flex",
                                          alignItems: "center",
                                          color: (theme) =>
                                            theme.palette.secondary.main,
                                        }}
                                      >
                                        <RemoveIcon
                                          style={{
                                            fontSize: "15px",
                                            color: "#333",
                                            border: "1px solid #333",
                                            margin: "5px",
                                            borderRadius: "4px",
                                            padding: "4px 8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            subQuantity(ele, index);
                                          }}
                                        />

                                        {ele?.quantity}

                                        <AddIcon
                                          style={{
                                            color: "#333",
                                            border: "1px solid #333",
                                            margin: "5px",
                                            fontSize: "15px",
                                            borderRadius: "4px",
                                            padding: "4px 8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            addQuantity(ele, index);
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          flex: 1,
                                          display: "flex",
                                          alignItems: "center",
                                          color: (theme) =>
                                            theme.palette.tertiary.main,
                                        }}
                                      >
                                        <CurrencyRupeeIcon
                                          sx={{ height: "17px" }}
                                        />{" "}
                                        {(
                                          ele.rate +
                                          (ele.rate * (ele.gst / 100))
                                        ).toFixed(2)}
                                      </Box>
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box
                            sx={{
                              display: "flex",
                              borderBottom: "1px solid",
                              borderColor: "divider",
                              padding: "8px 16px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            <Box
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Total Amount
                            </Box>
                            <Box
                              sx={{
                                flex: 3,
                                color: (theme) => theme.palette.secondary.main,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CurrencyRupeeIcon sx={{ fontSize: "17px" }} />
                              {TotalAmount(billData).amount}
                            </Box>

                            <Box
                              sx={{
                                flex: 2,
                                color: (theme) => theme.palette.primary.main,

                                textDecoration: "underline",

                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen2(true);
                              }}
                            >
                              {/* Payable Amount */}
                              Discount
                            </Box>
                            <Box
                              sx={{
                                flex: 2,
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              {/* Payable Amount */}
                              {billData?.discountType === "Percent" &&
                                billData?.discount === ""
                                ? ""
                                : ""}

                              {billData?.discountType === "Percent"
                                ? billData?.discount + "%"
                                : ""}
                            </Box>
                            <Box
                              sx={{
                                flex: 2,
                                // textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >   <CurrencyRupeeIcon sx={{ fontSize: "17px" }} />

                              {billData?.discountType === "Percent" &&
                                // (billData?.discount / 100) * billData?.netAmount}
                                (billData?.discount / 100) * sumItemPrice()}
                              {billData?.discountType !== "Percent" &&
                                // (billData?.discount / 100) * billData?.netAmount}
                                billData?.discount}

                              {billData?.discountType === "Amount" &&
                                billData?.discount === ""
                                ? 0
                                : ""}

                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box
                              sx={{
                                display: "flex",
                                borderBottom: "1px solid",
                                borderColor: "divider",
                                textAlign: "center",
                                padding: "8px 16px",
                                // fontWeight: "bold",
                                fontWeight: "500",
                              }}
                            >
                              <Box
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                }}
                              >
                                Payable Amount
                                {/* Discount */}
                              </Box>
                              <Box
                                sx={{
                                  flex: 3,
                                  color: (theme) => theme.palette.secondary.main,
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  flex: 3,
                                  display: "flex",
                                  color: (theme) => theme.palette.secondary.main,
                                  justifyContent: "center",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  flex: 3,
                                  color: (theme) => theme.palette.secondary.main,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* {billData?.billAmount} */}
                                <CurrencyRupeeIcon sx={{ fontSize: "17px" }} />
                                {payableValueCalculate().toFixed(2)}

                                {/* {billData?.discountAmount} */}
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box
                              sx={{
                                display: "flex",
                                borderBottom: "1px solid",
                                borderColor: "divider",
                                textAlign: "center",
                                padding: "8px 16px",
                                // fontWeight: "bold",
                                fontWeight: "500",
                              }}
                            >
                              {/* <Box
                              sx={{
                                flex: 3,
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Payment Mode
                            </Box> */}

                              {billData.paymentMode && (
                                <Box
                                  sx={{
                                    flex: 9,
                                    color: (theme) =>
                                      theme.palette.secondary.main,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={billData?.paymentMode}
                                    onChange={(e) => {
                                      setbillData({
                                        ...billData,
                                        paymentMode: e.target.value,
                                      });
                                      // setBill({
                                      //   ...bill,
                                      //   paymentMode: e.target.value,
                                      // });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Cash"
                                      control={<Radio />}
                                      label={
                                        <Box sx={{ display: "flex" }}>
                                          <CurrencyRupeeIcon
                                            sx={{ fontSize: "20px" }}
                                          />
                                          Cash{" "}
                                        </Box>
                                      }
                                    />
                                    <FormControlLabel
                                      value="Credit"
                                      selected={true}
                                      control={<Radio />}
                                      label={
                                        <Box sx={{ display: "flex" }}>
                                          <CurrencyRupeeIcon
                                            sx={{ fontSize: "20px" }}
                                          />
                                          Credit{" "}
                                        </Box>
                                      }
                                    />
                                    <FormControlLabel
                                      value="Digital"
                                      control={<Radio />}
                                      label={
                                        <Box sx={{ display: "flex" }}>
                                          <CurrencyRupeeIcon
                                            sx={{ fontSize: "20px" }}
                                          />
                                          Online{" "}
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                  {/* {billData?.discountAmount} */}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          m: "10px",
                        }}
                        onClick={() => {
                          updateBillFunction();
                        }}
                      >
                        {" "}
                        Update
                      </Button>
                      {/* <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        m: "10px",
                      }}
                      onClick={() => {
                        // let valid = updateBillValidation(billData);
                        // if (valid.status) {
                        //   setOpen3(true);
                        // } else {
                        //   Swal.fire({
                        //     text: valid.msg,
                        //     icon: "warning",
                        //     ...swalAlertPopProps,
                        //   });
                        // }
                      }}
                    >
                      {" "}
                      Print
                    </Button> */}
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              m: "10px",
                            }}
                            // sx={{
                            //   // width: "50%",
                            //   mt: "10px",
                            //   display: "inline-block",
                            //   mb: "10px",
                            // }}
                            onClick={() => {
                              // setKOT();
                              // addDataCallBack();
                              // setOpen2(false);
                            }}
                          >
                            {" "}
                            Update & Print
                          </Button>
                        )}
                        content={() => componentRef.current}
                        onBeforePrint={() => {
                          // setOpen2(false);
                          // addDataCallBack();
                          updateBillFunction();
                          // savePrintBill(
                          //   bill,
                          //   creditValidtionCheck,
                          //   Swal,
                          //   userDetail,
                          //   saveBill,
                          //   saveBillCbUser
                          // );
                        }}
                      ></ReactToPrint>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <DiscountModal
        open={open2}
        handleClose={handleClose2}
        bill={billData}
        setBill={(data) => {
          for (let prop in data) {
            // if (!billData.hasOwnProperty(prop)) {
            // }
          }
          let arr = { ...billData };
          setbillData({
            ...billData,
            ...arr,
            discount: data.discount,
            discountType: data.discountType,
          });
        }}
        setOpen={setOpen2}
      />
      <PrintBilling
        open2={open3}
        handleClose2={handleClose3}
        componentRef={componentRef}
        currentDataArr={billData?.counterSalesChild}
        tableName={tableName}
        priceWithoutGst={TotalAmount(billData).priceWithoutGst}
        // setKOT={setKOT}
        // setBill={setBill}
        setOpen2={setOpen3}
        addDataCallBack={() => {
          updateBil(
            {
              ...billData,
              discount: billData?.discount ? parseFloat(billData?.discount) : 0,
            },
            UpdateBillCounterSell,
            Swal,
            navigate,
            routePath
          );
        }}
        payableBilUpdateBill={payableValueCalculate}
        paybill={payableValueCalculate()}
        userDetail={userDetail}
        discountType={billData?.discountType}
        discount={1}
        totalAmountBill={TotalAmount(billData).amount}
        discountAmount={
          (function () {
            if (billData?.discountType === "Percent") {
              return (billData?.discount / 100) * sumItemPrice();
            }
            return billData?.discount;
          })()
          // billData?.discount
        }
        addtoCardProductCBUser={billData?.counterSalesChild}
      />
    </Box>
  );
}
